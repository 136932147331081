import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import ThemeMaker from "../../components/thememaker";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import { integer } from "aws-sdk/clients/frauddetector";
import NewCategorylist from "../../components/CategoryTemplate/NewCategorylist";
import styles from "../transaction/transaction.module.css"
import { useHistory } from "react-router-dom";
import style from "../SellerDetails/Sellerdetails.module.css"
const { apiEndpoint } = config
var $ = require('jquery');



let arr = []
let Ndata = arr;
const Addcategory = () => {
    const decoded = tokenDecode();
    const { data } = decoded;
    const { permission: { category } } = data;
    const [listing, setListing] = useState(true);
    let rawSubCatData = {
        parent_id: "",
        name: "",
        status: true,
        id: ""
    }
    const [Subcategorydata, setSubcategorydata] = useState(rawSubCatData);
    const [subcat, setSubcat] = useState(false)
    const [subcattoggle, setSubcattoggle] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [listingLoading, setListingLoading] = useState(false);
    const [taxData, setTaxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectsub, setSelectsub] = useState(0);
    // const [catlist, setCatlist] = useState('');
    const [Nadata, setNadata] = useState([]);
    // const [isChecked, setIsChecked] = useState(false);

    const [tax, setTax] = useState({
        tax: '',
        status: true,
        id: ''
    });

    const taxD = {
        tax: false,
        name: false,
        parent_id: false
    }
    const [taxErrorData, setTaxErrorData] = useState(taxD);
    const errorHandler = (e) => {
        if (e.target.value) {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: false });
        } else {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: true });
        }
    }
    const inputHandler = (e) => {
        setSelectsub(e.target.value);

    };

    const createTaxHandler = async () => {
        try {
            setLoading(true);
            if (tax?.tax) {
                const { data } = await axios({
                    url: `https://shikahrapp.hulsecure.in/public/index.php/api/addcategorie`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        name: tax.tax,
                        status: tax.status
                    }
                });

                if (data.status === 200) {
                    toast.success("New Category created !", {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTax({
                        tax: '',
                        status: true,
                        id: ''
                    })
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const createSubCatHandler = async () => {
        try {
            setLoading(true);

            if (Subcategorydata?.name) {
                const { data } = await axios({
                    url: `https://shikahrapp.hulsecure.in/public/index.php/api/addsubcategories`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: Subcategorydata
                });

                if (data.status === 200) {
                    toast.success("New Sub Category created !", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setSubcategorydata(rawSubCatData)


                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const taxListHanler = async () => {
        try {
            setListing(true);
            setSubcat(false)
            setListingLoading(true);

            const { data } = await axios({
                url: `https://shikahrapp.hulsecure.in/public/index.php/api/getcategories`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });

            setListingLoading(false);
            if (data.status === 200) {
                setTaxData(data.response);
            }

        } catch (error) {
            setListingLoading(false);


        }
    }

    const setCrete = () => {
        setListing(false);
        setIsUpdate(false);
        setSubcat(false);
        setTax({
            tax: '',
            status: true,
            id: ''
        })
    }
    const setsubCrete = async () => {
        setTax({
            tax: '',
            status: true,
            id: ''
        })
        setSubcategorydata(rawSubCatData)
        setListing(false);
        setIsUpdate(false);
        setSubcat(true);
        try {
            setListing(false);
            setListingLoading(false);
            const { data } = await axios({
                url: `https://shikahrapp.hulsecure.in/public/index.php/api/getcategories`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            // console.log("tax listing", data);
            setListingLoading(false);
            if (data.status === 200) {
                Ndata = data.response;
                const parsedData = data.response.map(item => ({
                    id: item.id,
                    parent_id: item.parent_id,
                    name: item.name,
                    status: item.status,
                    subcategory: item.subcategory || []
                }));
                // console.log("parsed data-->", parsedData)
                setNadata(parsedData);


            }

        } catch (error) {
            setListingLoading(false);


        }
    }


    const editRedirect = async (id, parentCatName) => {
        console.log("id---", id);
        setSubcattoggle(true)

        try {
            const { data } = await axios({
                url: `https://shikahrapp.hulsecure.in/public/index.php/api/getcatsub`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    "id": id
                }
            });
            console.log("editRedirect data", data);
            if (data.status === 200) {
                setTax({
                    tax: data?.parent_id[0]?.name,
                    status: data?.parent_id[0]?.status,
                    id: data?.parent_id[0]?.id
                });
                setSubcategorydata({
                    parent_id: data?.parent_id[0]?.parent_id,
                    name: data?.parent_id[0]?.name,
                    status: data?.parent_id[0]?.status,
                    id: data?.parent_id[0]?.id
                })
                setSelectsub(data?.parent_id[0]?.parent_id)
            }
        } catch (error) {

        }
        setIsUpdate(true);
        setListing(false);
        if (parentCatName !== "") {
            setSubcat(true)
        } else {
            setSubcat(false);
        }


    }

    const updateTax = async () => {
        try {
            if (tax?.tax) {
                setLoading(true);
                console.log("tax", tax);
                const { data } = await axios({
                    url: `${apiEndpoint}/updatecategories`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        "name": tax.tax,
                        "status": tax.status,
                        id: tax.id
                    }
                });
                // console.log("data----", data);
                if (data.status === 200) {
                    toast.success("Category Updated !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const updateSubTax = async () => {
        try {
            if (tax?.tax) {
                setLoading(true);
                const { data } = await axios({
                    url: `https://shikahrapp.hulsecure.in/public/index.php/api/updatesubcategories`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        "name": Subcategorydata.name,
                        "parent_id": Subcategorydata.parent_id,
                        id: Subcategorydata.id
                    }
                });

                if (data.status === 200) {
                    toast.success("Category Updated !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const deleteTax = async (id, parent_id) => {
        try {
            setLoading(true);

            const { data } = await axios({
                url: `https://shikahrapp.hulsecure.in/public/index.php/api/delcatshiftsubcat`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: { id, parent_id }
            });
            if (data.status === 200) {

                toast.success("Category Deleted !", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


                setTimeout(() => {
                    window.location.reload(false)
                }, 1000);

            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const handleSubcategoryChange = (id) => {
        setSubcategorydata({ ...Subcategorydata, parent_id: id });
    };


    const MakeCategory = ({ name, subcategory, id, onSubcategoryChange }) => {
        const subCategoryChecker = subcategory && subcategory.length >= 1;
        const handleCheckboxChange = (event) => {

            setSubcategorydata(prevState => {
                if (prevState.parent_id === id) {
                    return { ...prevState, parent_id: null };
                } else {
                    return { ...prevState, parent_id: id };
                }
            });
            onSubcategoryChange(id);
        };

        return (
            <>
                <ul style={{ marginLeft: "20px" }}  >
                    <li key={id}   > <input type="checkbox" value={id} onChange={handleCheckboxChange}
                        id={id} checked={Subcategorydata.parent_id === id} /> -{name}
                    </li>

                    {subCategoryChecker && subcategory.map((item) => (

                        <MakeCategory {...item} key={item.id} onSubcategoryChange={handleSubcategoryChange} Subcategorydata={Subcategorydata}
                            setSubcategorydata={setSubcategorydata} />
                    ))}
                </ul>
            </>
        )
    }

    // for multiselect:-
    //     const MakeCategory = ({ name, subcategory, id }) => {
    //         const subCategoryChecker = subcategory && subcategory.length >= 1;
    //         const [isChecked, setIsChecked] = useState(Subcategorydata.parent_id && Subcategorydata.parent_id.includes(id));
    //         const handleCategoryClick = () => {
    //             onCategoryClick(id)
    // ;
    //             setIsChecked(!isChecked);
    //         };

    //         function onCategoryClick(id)
    //  {
    //             setSubcategorydata({ ...Subcategorydata, parent_id: [...Subcategorydata.parent_id, id], })
    //         }

    //         const handleCheckboxChange = (event) => {
    //             setIsChecked(event.target.checked);
    //         };

    //         return (
    //             <>
    //                 <ul style={{ marginLeft: "20px" }}  >
    //                     <li key={id} onClick={handleCategoryClick}
    //                     >
    //                         <input type="checkbox" value={id} name="sub-category" checked={isChecked} onChange={handleCheckboxChange} /> -{name} </li>

    //                     {subCategoryChecker && subcategory.map((item) => (

    //                         <MakeCategory {...item} />
    //                     ))}
    //                 </ul>
    //             </>
    //         )
    //     }
    // console.log("listing state----->", Subcategorydata)

    useEffect(() => {
        taxListHanler()
    }, [])
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 px-0">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    <div className={style.tabs}>
                                        <button className={listing ? `${style.tab} ${style.active}` : `${style.tab}`}
                                            onClick={taxListHanler}
                                        >
                                            Category list
                                        </button>
                                        <button className={(!subcat && !listing) ? `${style.tab} ${style.active}` : `${style.tab}`}
                                            onClick={setCrete}
                                        >
                                            New Category
                                        </button>
                                        <button className={subcat ? `${style.tab} ${style.active}` : `${style.tab}`}
                                            onClick={setsubCrete}
                                        >
                                            Sub Category
                                        </button>

                                    </div>
                                </h6>
                            </div>
                            {
                                listing ? (<>
                                    <div className={`card-body ${styles.transactionListing_desktop}`} >
                                        <div className="table-responsive">
                                            <table className="table table-bordered" id="dataTable" width="100%" >
                                                <thead>
                                                    <tr>
                                                        <th>Sn</th>
                                                        <th>Category name</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        taxData.map((item, index) => (
                                                            <>
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        {
                                                                            item?.status ?
                                                                                <span className="badge badge-success">Active</span> :
                                                                                <span className="badge badge-danger">Deactive</span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div className="row">
                                                                            {
                                                                                category?.update ? (
                                                                                    <div className="col-md-4">
                                                                                        <button className="btn btn-success btn-circle"
                                                                                            onClick={() => editRedirect(item.id)}
                                                                                        >
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                category?.delete ? (
                                                                                    <div className="col-md-4">
                                                                                        <button className="btn btn-danger btn-circle"
                                                                                            onClick={() => deleteTax(item.id)}
                                                                                        >
                                                                                            <i className="fas fa-trash"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : null
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                    <div className={`${styles.transactionListing_mobile}`} style={{ padding: "10px" }}>  {

                                        <NewCategorylist item={taxData} editRedirect={editRedirect} deleteTax={deleteTax} />

                                    }</div></>
                                ) : (<>
                                    {subcat ?
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label >Sub Category name</label>
                                                        <span className="text-danger" > * </span>
                                                        {
                                                            taxErrorData?.name ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                        <input type="text" className="form-control" name="name" placeholder="Sub Category"
                                                            value={Subcategorydata.name}
                                                            onChange={(e) => setSubcategorydata({ ...Subcategorydata, [e.target.name]: e.target.value })}
                                                            onBlur={(e) => errorHandler(e)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        {/* <label >Parent Category</label> */}
                                                        {/* <span className="text-danger" > * </span>
                                                        {
                                                            taxErrorData?.parent_id ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        } */}

                                                        {/* <select className="form-control" value={selectsub} name="category_id" id="" onChange={(e) => inputHandler(e)} onBlur={(e) => errorHandler(e)}>
                                                            <option value=""> Select category</option>
                                                            {
                                                                Ndata.filter((Ndata) => {
                                                                    if (Ndata.status) {
                                                                        return Ndata;
                                                                    }
                                                                }).map((item, index) => {
                                                                    return (<option value={item.id} key={index}>{item.name}</option>)

                                                                })


                                                            }


                                                        </select> */}
                                                        <div className="subcategory">

                                                            <div className="selectcategory" onClick={() => setSubcattoggle(!subcattoggle)}>--Select Category--<span style={{ "float": "right" }}>▼</span>
                                                                {/* <input type="text" className="form-control" name="subcat" placeholder="Search" value={search} onChange={handleSearchChange} /> */}
                                                            </div>
                                                            {subcattoggle ? <>
                                                                {
                                                                    Nadata.map((item) => {
                                                                        return (
                                                                            <>
                                                                                <MakeCategory {...item} onSubcategoryChange={handleSubcategoryChange} />
                                                                            </>
                                                                        )
                                                                    })
                                                                }</>
                                                                : ""}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label >Status</label> <br />
                                                        <input type="checkbox" checked={tax.status} name="status" placeholder="Name"
                                                            value=""
                                                            onChange={(e) => setTax({ ...tax, [e.target.name]: !tax.status })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    {
                                                        !isUpdate && !listing ? (
                                                            <button type="submit" className="btn btn-primary"
                                                                onClick={createSubCatHandler}
                                                            >
                                                                Save
                                                            </button>
                                                        ) : (
                                                            <button type="submit" className="btn btn-success"
                                                                onClick={updateSubTax}
                                                            >
                                                                Update
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                            </div>
                                        </div> : <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label >Category name</label>
                                                        <span className="text-danger" > * </span>
                                                        {
                                                            taxErrorData?.tax ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                        <input type="text" className="form-control" name="tax" placeholder="Category"
                                                            value={tax.tax}
                                                            onChange={(e) => setTax({ ...tax, [e.target.name]: e.target.value })}
                                                            onBlur={(e) => errorHandler(e)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group d-flex align-items-center" style={{ gap: "3px" }}>
                                                        <label className="m-0">Status</label>
                                                        <input type="checkbox" checked={tax.status} name="status" placeholder="Name"
                                                            value=""
                                                            onChange={(e) => setTax({ ...tax, [e.target.name]: !tax.status })}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    {
                                                        !isUpdate && !listing ? (
                                                            <button type="submit" className="btn btn-primary"
                                                                onClick={createTaxHandler}
                                                            >
                                                                Save
                                                            </button>
                                                        ) : (
                                                            <button type="submit" className="btn btn-success"
                                                                onClick={updateTax}
                                                            >
                                                                Update
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                            </div>
                                        </div>
                                    }
                                </>

                                )
                            }
                        </div>
                    </div>
                </div>
            </ThemeMaker>

        </>
    )
}

export default Addcategory;