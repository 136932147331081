import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../../store/features/login/loginSlice";
import { useHistory } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch<any>();
    const loginData = useSelector((state: any) => state.loginReducer);
    // console.log("loginData--->", loginData);
    const { loading } = loginData;
    const [user, setUser] = useState({
        email: "",
        password: ""
    });
    const [errorMessage, setErrorMessage] = useState({
        email: true,
        password: true
    });

    const loginMannage = async () => {
        console.log("user---");
        dispatch(checkLogin(user));
    }

    const loginChecker = (e: any) => {
        e.preventDefault();
        if (errorMessage.email || errorMessage.password) {
            toast.error("Email and password is required !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            loginMannage();
        }
    }
    const onChangeHandler = (e: any) => {
        setUser({ ...user, [e.target.name]: e.target.value });
        setErrorMessage({ ...errorMessage, [e.target.name]: e.target.value ? false : true })
    }
    const redirectHandler = () => {
        try {
            const decode = tokenDecode();
            if (decode) {
                history.push('/dashboard');
            } else {
                history.push('/');
            }
        } catch (error) {
            history.push('/');
        }
    }
    useEffect(() => {
        redirectHandler();
    }, [loginData]);
    return (
        <>

            <div className="container-fluid bg-dark" style={{ height: "100vh" }} >

                <div className="row justify-content-center">
                    <div className="col-md-6 mt-4 mb-4">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-center mb-3">
                                                <img src="/img/logoimg.png" style={{ height: "65px" }} alt="logo here" />
                                            </div>
                                            <div className="text-center">

                                            </div>
                                            <form className="user mb-4" onSubmit={(e) => loginChecker(e)} >
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user"

                                                        placeholder="Enter Email Address..."
                                                        name="email"
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user"
                                                        placeholder="Password"
                                                        name="password"
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                                {
                                                    loading ? <button className="btn btn-dark btn-user btn-block mt-4 mb-4">
                                                        Loading...
                                                    </button> : <button className="btn btn-dark btn-user btn-block mt-4 mb-4">
                                                        Login
                                                    </button>
                                                }


                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <Link className="small" to="/forget-password">Forgot Password?</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <ToastContainer />
        </>
    )
}

export default Login;