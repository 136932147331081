import React from 'react'
import styles from "./Notification.module.css"

const rawData = [
    {
        type: "Success",
        message: "Order Placed Successfully. Thanku fro shopping with us"
    },
    {
        type: "Error",
        message: "Payment for the order could not be proceed. Please try again."
    },
    {
        type: "Warning",
        message: "Order will not be delivered in this pin code.Please try other pincode"
    },
    {
        type: "Info",
        message: "You will receive a tracking link as soon as your order ships."
    },
]
export default function Notification() {
    return (
        <>
            {rawData.map((item, index) => {


                let bgColorClass = '';
                if (item.type === 'Success') {
                    bgColorClass = styles.bgtype1;
                } else if (item.type === 'Error') {
                    bgColorClass = styles.bgtype2;
                } else if (item.type === 'Warning') {
                    bgColorClass = styles.bgtype3;
                } else if (item.type === 'Info') {
                    bgColorClass = styles.bgtype4;
                }

                return <div className={`d-flex ${styles.main} ${bgColorClass}`}>
                    <div className=''>
                        <span>{index}</span>
                    </div>
                    <div className=''>
                        <p className='m-1'>{item.type}</p>
                        <p>
                            {item.message}
                        </p>
                    </div>
                </div>
            })}


        </>

    )
}
