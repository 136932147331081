import React from "react"
import { useEffect, useState } from "react";
import ThemeMaker from "../../components/thememaker";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { tokenDecode } from "../../utils/helper";
import axios from "axios"
import { config } from "../../utils/config";
import { useParams } from "react-router-dom";
const { apiEndpoint } = config;
const s3Config = {
    bucketName: 'bucket-name',
    dirName: 'directory-name',      /* Optional */
    region: 'ap-south-1',
    accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
    secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
    s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
const aws = require('aws-sdk');

function OndcSellerProductAdd() {
    const decode = tokenDecode();
    let current_sellerid = decode.data.user.seller_id

    const [pageNamem, setpageName] = useState(true);
    const [uploading, setUpLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [taxData, setTaxData] = useState([]);
    const [readonly, setreadonly] = useState(false)
    const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

    // const submitdata = async () => {
    //   // console.log("data >>>>>>", name, email, password);
    //   let result = await fetch('https://shikahrapp.hulsecure.in/public/index.php/api/addmastercatalog', {
    //       method: 'post',
    //       // body: JSON.stringify({ name, email, password }),
    //       headers: {
    //           'Content-Type': 'application/json'
    //       },
    //   });

    //   result = await result.json();
    //   console.log(result);
    // }

    useEffect(() => {
        taxListHanler()
        categoryListing()
        if (id) {
            setpageName(false);
            getProduct();
            if (current_sellerid > 1) {
                setreadonly(true)
            }
            // setProductData(JSON.parse(user))
        } else {
            setpageName(true);
        }
    }, [])

    const getProduct = async () => {

        try {
            const { data } = await axios({
                url: "https://shikahrapp.hulsecure.in/public/index.php/api/ondcgetmasterprodcut",
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                },
                data: { product_id },

            });
            console.log({ product_id })
            // console.log("getProduct >---------> ", data);
            if (data.status === 200) {
                console.log(data.data)
                setProductData(data.data[0]);
            }
        } catch (error) { }

    };




    let params = useParams();
    const { id } = params;
    const product_id = id

    // console.log(rawData.seller_id)
    const rawData = {
        seller_id: decode?.data?.user?.seller_id,
        name: "",
        short_description: "",
        long_description: "",
        category_id: "",
        selling_price: 0,
        price: 0,
        price: 0,
        tax_included_price: 0,
        tax: 0,
        tags: "",
        image: "",
        sku: "",
        approve_status: "",
        retail_approve_status: "",
        hsn: "",
        upc: "",
        position: "",
        status: false,
        food_type_veg: "",
        returnable: false,
        replaceable: false,
        canceleable: false,
        return_window: "",
        seller_pickup_return: false,
        time_to_ship: "",
        cod: false,
        brand: "",
        customer_care_contact: "",
        product_type: "",
        pc_manufacturer_or_packer_name: "",
        pc_manufacturer_or_packer_address: "",
        pc_common_or_generic_name_of_commodity: "",
        pc_net_quantity_or_measure_of_commodity_in_pkg: "",
        pc_month_year_of_manufacture_packing_import: "",
        country_of_origin: "",
        pf_nutritional_info: "",
        pf_additives_info: "",
        pf_brand_owner_fassai_license_no: "",
        pf_other_fassai_license_no: "",
        pf_importer_fassai_license_no: "",

        vf_net_quantity: "",

        tax_included_check: false
    };
    const rawDataError = {
        name: false,
        short_description: false,
        long_description: false,
        customer_care_contact: false,
        category_id: false,
        country_of_origin: false,
        price: false,
        tax_included_price: false,
        tax: false,
        tags: false,
        image: false,
        sku: false,
        approve_status: false,
        hsn: false,
        upc: false,
        retail_approve_status: false,
        position: false,
        status: false,
        replaceable: false,
        returnable: false,
        canceleable: false,
        return_window: false,
        seller_pickup_return: false,
        time_to_ship: false,
        cod: false,
        product_type: false,
        pc_manufacturer_or_packer_name: false,
        pc_manufacturer_or_packer_address: false,
        pc_common_or_generic_name_of_commodity: false,
        pc_net_quantity_or_measure_of_commodity_in_pkg: false,
        pc_month_year_of_manufacture_packing_import: false,
        pf_nutritional_info: false,
        pf_additives_info: false,
        pf_brand_owner_fassai_license_no: false,
        pf_other_fassai_license_no: false,
        pf_importer_fassai_license_no: false,
        vf_net_quantity: false,

    };
    const [productData, setProductData] = useState(rawData);
    const [productDataError, setProductDataError] = useState(rawDataError);
    // console.log(productData)

    // console.log("price----", productData.price)

    const updateProductHanlder = async () => {
        // console.log("productData", productData);
        const errorEmptyAlert = () => {
            toast.error("Please fill the required fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        const {
            name,
            short_description,
            long_description,
            category_id,
            selling_price,
            price,
            tax_included_price,
            tax,
            tags,
            image,
            sku,
            hsn,
            position,
            status,
            food_type_veg,
            returnable,
            cancelable,
            return_window,
            seller_pickup_return,
            time_to_ship,
            cod,
            product_type,
            pc_manufacturer_or_packer_name,
            pc_manufacturer_or_packer_address,
            pc_common_or_generic_name_of_commodity,
            pc_net_quantity_or_measure_of_commodity_in_pkg,
            pc_month_year_of_manufacture_packing_import,
            pf_nutritional_info,
            pf_additives_info,
            pf_brand_owner_fassai_license_no,
            pf_other_fassai_license_no,
            pf_importer_fassai_license_no,
            vf_net_quantity,
            tax_included_check
        } = productData;
        let validChe = false;
        let validChe_2 = false;
        if (name && short_description && long_description && price && tax && position && return_window && time_to_ship && product_type && status !== "" && returnable !== "" && cancelable !== "" && seller_pickup_return !== "" && cod !== "") {
            if (Number(category_id) === 25) {
                if (food_type_veg !== "") {
                    validChe_2 = true;
                } else {
                    validChe_2 = false;
                    console.log("here");

                }
            } else {
                validChe_2 = true;
            }

            if (product_type == "0") {
                if (pc_manufacturer_or_packer_name && pc_manufacturer_or_packer_address && pc_common_or_generic_name_of_commodity && pc_net_quantity_or_measure_of_commodity_in_pkg && pc_month_year_of_manufacture_packing_import) {
                    validChe = true;
                } else {
                    validChe = false;
                }
            }
            if (product_type == "1") {
                if (pf_nutritional_info && pf_additives_info && pf_brand_owner_fassai_license_no) {
                    validChe = true;
                } else {
                    validChe = false;


                }
            }

            let updatedDataToSend;
            let dataForSeller2 = {
                product_id: productData.id,
                price: productData.price,
            }

            if (current_sellerid == 1) {
                updatedDataToSend = productData;
            } else {
                console.log(dataForSeller2)
                updatedDataToSend = dataForSeller2;
            }
            console.log("updatedDataToSend---", updatedDataToSend)
            // console.log("sghdsdsa---", validChe, validChe_2)
            if (validChe && validChe_2) {

                try {

                    setUpLoading(true);

                    const { data } = await axios({
                        url: "https://shikahrapp.hulsecure.in/public/index.php/api/ondcupdatesellerprodcut",
                        method: "post",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                        },
                        data: updatedDataToSend,
                    });
                    setUpLoading(false);
                    console.log(data)
                    if (data.status === 200) {
                        toast.success("Product updated successfully !", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error("404 error !", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } catch (error) {
                    setUpLoading(false);
                    toast.error("404 error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            } else {
                console.log("first")
                errorEmptyAlert();
            }
        } else {
            console.log("second")
            errorEmptyAlert();
        }

    };


    const inputHandler = (e) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });

    }
    const errorHandler = (e) => {
        if (e.target.value) {
            setProductDataError({ ...productDataError, [e.target.name]: false });
        } else {
            setProductDataError({ ...productDataError, [e.target.name]: true });
        }
    }
    const taxListHanler = async () => {
        try {
            const { data } = await axios({
                url: "https://shikahrapp.hulsecure.in/public/index.php/api/gettaxlist",
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            // console.log("tax listing", data);
            if (data.status === 200) {
                setTaxData(data.data);
            }
        } catch (error) {
        }
    }


    const categoryListing = async () => {
        try {
            const { data } = await axios({
                url: "https://shikahrapp.hulsecure.in/public/index.php/api/getcategorylist",
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                },
            });
            // console.log("category listing", data);
            if (data.status === 200) {
                setCategoryList(data.data);
            }
        } catch (error) { }
    };

    const priceHandler = (e) => {
        console.log("e.target.name", e.target.name);
        if (e.target.name === "selling_price") {
            const percentAmmount =
                (parseFloat(productData.tax) / 100) * parseFloat(e.target.value);
            setProductData((preState) => ({
                ...preState,
                [e.target.name]: parseFloat(e.target.value),
                tax_included_price: productData?.tax_included_check ? e.target.value : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4))

            }));
        } else if (e.target.name === "tax") {
            const percentAmmount =
                (parseFloat(e.target.value) / 100) * parseFloat(productData.selling_price);
            setProductData((preState) => ({
                ...preState,
                [e.target.name]: parseFloat(e.target.value),
                tax_included_price: productData?.tax_included_check ? productData?.tax_included_price :
                    parseFloat(productData.selling_price) +
                    parseFloat(percentAmmount.toFixed(4)),
            }));
        } else if (e.target.name === "tax_included_check") {
            const percentAmmount = (parseFloat(productData.tax) / 100) * parseFloat(productData.selling_price);
            setProductData({
                ...productData,
                [e.target.name]: !productData?.tax_included_check,
                tax_included_price: !productData?.tax_included_check ? productData.selling_price : percentAmmount + parseFloat(productData.selling_price)

            });

        }
    };

    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            console.log(Location);

            setProductData({ ...productData, image: Location })

        } catch (exception) {
            console.log(exception);
        }
    };

    const productTypeHandler = (e) => {
        if (e.target.value === "1") {
            setProductData({
                ...productData, [e.target.name]: e.target.value,
                pc_manufacturer_or_packer_name: "",
                pc_manufacturer_or_packer_address: "",
                pc_common_or_generic_name_of_commodity: "",
                pc_net_quantity_or_measure_of_commodity_in_pkg: "",
                pc_month_year_of_manufacture_packing_import: "",
            });
        } else if (e.target.value === "0") {
            setProductData({
                ...productData, [e.target.name]: e.target.value,
                pf_nutritional_info: "",
                pf_additives_info: "",
                pf_brand_owner_fassai_license_no: "",
                pf_other_fassai_license_no: "",
                pf_importer_fassai_license_no: "",
            });
        }
        // else if (e.target.value === "2") {
        //   setProductData({
        //     ...productData, [e.target.name]: e.target.value,
        //     vf_net_quantity: "",
        //   });
        // }
        // setProductData({ ...productData, [e.target.name]: e.target.value })
    }

    const createproductConsole = async () => {
        setLoading(true);
        const errorEmptyAlert = () => {
            toast.error("Please fill the required fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        const {
            name,
            short_description,
            long_description,
            category_id,
            selling_price,
            price,
            tax_included_price,
            tax,
            tags,
            image,
            approve_status,
            sku,
            hsn,
            upc,
            retail_approve_status,
            position,
            status,
            food_type_veg,
            returnable,
            replaceable,
            canceleable,
            return_window,
            seller_pickup_return,
            time_to_ship,
            cod,
            product_type,
            pc_manufacturer_or_packer_name,
            pc_manufacturer_or_packer_address,
            pc_common_or_generic_name_of_commodity,
            pc_net_quantity_or_measure_of_commodity_in_pkg,
            pc_month_year_of_manufacture_packing_import,
            pf_nutritional_info,
            pf_additives_info,
            pf_brand_owner_fassai_license_no,
            pf_other_fassai_license_no,
            pf_importer_fassai_license_no,
            vf_net_quantity,
            tax_included_check
        } = productData;
        let validChe = false;
        let validChe_2 = false;
        if (name && short_description && long_description && price && tax && position && return_window && time_to_ship && product_type && status !== "" && returnable !== "" && canceleable !== "" && seller_pickup_return !== "" && cod !== "") {

            //if not working delete this
            if (Number(category_id) === 25) {
                if (food_type_veg) {
                    validChe_2 = true;
                } else {
                    validChe_2 = false;

                }
            } else {
                validChe_2 = true;
            }
            //if not working delete this
            if (product_type === "1") {
                if (pc_manufacturer_or_packer_name && pc_manufacturer_or_packer_address && pc_common_or_generic_name_of_commodity && pc_net_quantity_or_measure_of_commodity_in_pkg && pc_month_year_of_manufacture_packing_import) {
                    validChe = true;
                } else {
                    validChe = false;
                }
            }
            if (product_type === "1") {
                if (pf_nutritional_info && pf_additives_info && pf_brand_owner_fassai_license_no) {
                    validChe = true;
                } else {
                    validChe = false;
                    console.log("product_type = 1 false");

                }
            }
            // if (product_type === "Statutory food") {
            //   if (vf_net_quantity) {
            //     validChe = true;
            //   } else {
            //     validChe = false;
            //   }
            // }
            if (validChe) {
                try {
                    // console.log("my sent data>>>>>>", productData)

                    const { data } = await axios({
                        url: "https://shikahrapp.hulsecure.in/public/index.php/api/addmastercatalog",
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,

                        },
                        data: productData,
                    });


                    if (data.status === 200) {
                        setProductData(rawData);
                        toast.success("New product created successfully !", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {

                        toast.error("404 error !", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    setLoading(false);

                } catch (error) {

                    setLoading(false);
                    toast.error("404 error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            } else {
                errorEmptyAlert();
            }
        } else {
            errorEmptyAlert();
        }
    }


    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    {pageNamem ? "Create product" : "Update product"}
                                </h6>



                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.name ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Enter title"
                                                value={productData.name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Position</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.position ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="position"
                                                placeholder="Position"
                                                value={productData.position}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product status</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                productDataError?.status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.status}
                                                name="status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}

                                            >
                                                <option value="">select product status</option>
                                                <option value="true">Active</option>
                                                <option value="false">Draft</option>
                                            </select>

                                            <label>Brand Name</label>
                                            <span className="text-danger" > * </span>
                                            <input
                                                type="text"
                                                value={productData.brand}
                                                className="form-control"
                                                name="brand"
                                                placeholder="Brand Name"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            />
                                            <label>Country Origin</label>
                                            <span className="text-danger" > * </span>
                                            <input
                                                type="text"
                                                value={productData.country_of_origin}
                                                className="form-control"
                                                name="country_of_origin"
                                                placeholder="Country Origin"
                                                onChange={(e) => inputHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Category</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.category_id ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.category_id}
                                                name="category_id"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value=""> Select category</option>
                                                {categoryList
                                                    .filter((categoryList) => {
                                                        if (categoryList.status) {
                                                            return categoryList;
                                                        }
                                                    })
                                                    .map((item) => (
                                                        <option value={item?.id}>
                                                            {item?.category_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        Number(productData?.category_id) === 25 ? (
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Food type</label>
                                                    <span className="text-danger" > * </span>

                                                    {
                                                        productDataError?.food_type_veg ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <select
                                                        className="form-control"
                                                        value={productData.food_type_veg}
                                                        name="food_type_veg"
                                                        id=""
                                                        onChange={(e) => inputHandler(e)}
                                                        onBlur={(e) => errorHandler(e)}

                                                    >
                                                        <option value="">Select food type</option>
                                                        <option value="true">Veg</option>
                                                        <option value="false">Non veg</option>
                                                    </select>
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Short description</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.short_description ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <textarea
                                                className="form-control"
                                                name="short_description"
                                                placeholder="Short description"
                                                onChange={(e) => inputHandler(e)}
                                                value={productData.short_description}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Long description</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.description ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <textarea
                                                className="form-control"
                                                name="long_description"
                                                placeholder="Long description"
                                                value={productData.long_description}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Selling price
                                                <span className="text-danger" > * </span>
                                                <input type="checkbox" name="tax_included_check" style={{ marginLeft: "20px" }}
                                                    onChange={(e) => priceHandler(e)}
                                                    checked={productData?.tax_included_check}
                                                    readOnly={readonly}
                                                /> Tax included
                                            </label>
                                            {
                                                productDataError?.selling_price ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="selling_price"
                                                placeholder="Min price"
                                                value={productData.selling_price}
                                                onChange={(e) => priceHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Max price</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.price ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="price"
                                                placeholder="Max price"
                                                value={productData.price}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tax included price</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                disabled
                                                name="tax_included_price"
                                                placeholder=""
                                                readOnly={readonly}
                                                value={productData.tax_included_price}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tax ( % ) </label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.tax ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }

                                            <select
                                                className="form-control"
                                                name="tax"
                                                placeholder="Tax"
                                                value={productData.tax}
                                                onChange={(e) => priceHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select tax</option>
                                                {
                                                    taxData.map((item) => (
                                                        <option value={item?.tax_rate}>{item?.tax_rate}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tags</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="tags"
                                                placeholder="Enter tags"
                                                value={productData.tags}
                                                onChange={(e) => inputHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="sku"
                                                placeholder="Enter sku"
                                                value={productData.sku}
                                                onChange={(e) => inputHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>HSN</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="hsn"
                                                placeholder="Enter hsn"
                                                value={productData.hsn}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>UPC</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="upc"
                                                placeholder="Enter upc"
                                                value={productData.upc}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Customer Care Contact</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly={readonly}
                                                name="customer_care_contact"
                                                placeholder="Enter Customer Care Number"
                                                value={productData.customer_care_contact}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Images</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.image ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => productImageHandler(e)}
                                                accept="image/*"
                                                name="image"
                                                multiple
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Product Type</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.product_type ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select name="product_type" id="" className="form-control"
                                                value={productData.product_type}
                                                onChange={(e) => productTypeHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select your product type</option>
                                                <option value="0">Statutory packaged commodities</option>
                                                <option value="1">Statutory food</option>
                                                {/* <option value="2">Veggies & fruits </option> */}

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {
                                            productData.product_type === "Statutory packaged commodities" ? (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className='bold_mak' >Statutory packaged commodities</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Manufacturer or Packer name</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pc_manufacturer_or_packer_name ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pc_manufacturer_or_packer_name"
                                                                placeholder="Enter manufacturer or packer name"
                                                                value={productData.pc_manufacturer_or_packer_name}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Manufacturer or Packer address</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pc_manufacturer_or_packer_address ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pc_manufacturer_or_packer_address"
                                                                placeholder="Enter manufacturer or packer address"
                                                                value={productData.pc_manufacturer_or_packer_address}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Common or generic name of commodity</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pc_common_or_generic_name_of_commodity ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pc_common_or_generic_name_of_commodity"
                                                                placeholder="Enter Common or generic name of commodity"
                                                                value={productData.pc_common_or_generic_name_of_commodity}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Net quantity or measure of commodity in pkg</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pc_net_quantity_or_measure_of_commodity_in_pkg ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pc_net_quantity_or_measure_of_commodity_in_pkg"
                                                                placeholder="Enter Net quantity or measure of commodity in pkg"
                                                                value={productData.pc_net_quantity_or_measure_of_commodity_in_pkg}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Month year of manufacture packing import</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pc_month_year_of_manufacture_packing_import ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pc_month_year_of_manufacture_packing_import"
                                                                placeholder="Enter Month year of manufacture packing import"
                                                                value={productData.pc_month_year_of_manufacture_packing_import}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            productData.product_type === "1" ? (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className='bold_mak' >Statutory food</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Nutritional info</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pf_nutritional_info ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pf_nutritional_info"
                                                                placeholder="Enter Nutritional info"
                                                                value={productData.pf_nutritional_info}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Additives info</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pf_additives_info ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pf_additives_info"
                                                                placeholder="Enter Additives info"
                                                                value={productData.pf_additives_info}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Brand owner FSSAI license no</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.pf_brand_owner_fassai_license_no ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pf_brand_owner_fassai_license_no"
                                                                placeholder="Enter owner FSSAI license no"
                                                                value={productData.pf_brand_owner_fassai_license_no}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Other FSSAI license no</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pf_other_fassai_license_no"
                                                                placeholder="Enter Other FSSAI license no"
                                                                value={productData.pf_other_fassai_license_no}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Importer FSSAI license no</label>

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="pf_importer_fassai_license_no"
                                                                placeholder="Enter Importer FSSAI license no"
                                                                value={productData.pf_importer_fassai_license_no}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            productData.product_type === "2" ? (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className='bold_mak' >Veggies & fruits</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Net quantity in ( g )</label>
                                                            <span className="text-danger" > * </span>
                                                            {
                                                                productDataError?.vf_net_quantity ? (
                                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                                ) : null
                                                            }
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="vf_net_quantity"
                                                                placeholder="Enter net quantity"
                                                                value={productData.vf_net_quantity}
                                                                onChange={(e) => inputHandler(e)}
                                                                onBlur={(e) => errorHandler(e)}
                                                                readOnly={readonly}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product returnable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.returnable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.returnable}
                                                name="returnable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select returnable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product replaceable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.replaceable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.replaceable}
                                                name="replaceable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select replacable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product cancelable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.canceleable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.canceleable}
                                                name="canceleable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select cancelable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Return window</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.return_window ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.return_window}
                                                name="return_window"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select return window</option>
                                                {returnWindowLength.map((item) => (
                                                    <option value={`P${item}D`}>{`P${item}D`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Seller pickup return</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.seller_pickup_return ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.seller_pickup_return}
                                                name="seller_pickup_return"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select seller return pickup</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Time to ship</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.time_to_ship ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.time_to_ship}
                                                name="time_to_ship"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select return window</option>
                                                {timeToShipLength.map((item) => (
                                                    <option value={`P${item}D`}>{`P${item}D`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Cash on delivery</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.cod ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.cod}
                                                name="cod"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select cash on delivery</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Approve Status</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.approve_status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.approve_status}
                                                name="approve_status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select Approve Status</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Retail Approve Status</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                productDataError?.retail_approve_status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={productData.retail_approve_status}
                                                name="retail_approve_status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={readonly}
                                            >
                                                <option value="">Select Retail Approve Status</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        {pageNamem ? (
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                // onClick={createProductHandler}
                                                onClick={createproductConsole}
                                            >  {loading ? "Loading..." : "Create"}
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                                onClick={updateProductHanlder}
                                            > {uploading ? "Loading..." : "Update"}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModal" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center"></h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-10">
                                        <p> Location Name </p>
                                    </div>
                                    <div className="col-md-2">
                                        <p> Quantity </p>
                                    </div>
                                    {/* {locatioList?.location?.map((item: any, index: any) => ( */}
                                    <>
                                        <div className="col-md-10">
                                            {/* <p>
                          {" "}
                          {index + 1}. {item?.name}{" "}
                        </p> */}
                                        </div>
                                        <div className="col-md-2">
                                            <input
                                                type="number"
                                                //   value={item?.quantity}
                                                className="form-control"
                                            //   onChange={(e) => setLocationValue(e, index)}
                                            />
                                        </div>

                                    </>
                                    {/* ))} */}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-success"
                                //   onClick={importCsvHanlder}
                                >
                                    {loading ? "updating..." : "Update"}
                                </button>

                                <button
                                    type="button"
                                    id="closebtn"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}
export default OndcSellerProductAdd;