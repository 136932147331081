import React from 'react'
import styles from "./OrderTemplate.module.css"
import eye from "../../icons/eye.png"
import mailicon from "../../icons/mailicon.png"
import phoneicon from "../../icons/phoneicon.png"
import some from "../../pages/order-details/index"
import { Link } from "react-router-dom"
export default function OrderTemplate({ orderList }) {
    return (
        <> {
            orderList.map((item, index) => {
                return <div className={`d-flex justify-content-between w-100 ${styles.OrderTemplatemain}`} key={item.id}>
                    <div className={`${styles.OrderTemplateImage}`}>
                        <span>0{index + 1}</span>
                    </div>
                    <div className={`${styles.OrderTemplateDesc}`}>

                        <div>
                            <p className='m-0 mb-1' style={{ textTransform: "capitalize" }}>{item.name} </p>
                        </div>
                        <div>
                            <p className='m-0'>  <img src={mailicon} alt="" /> <span style={{ fontSize: "13px" }}>{item.email}</span></p>
                            <p className='m-0'> <img src={phoneicon} alt="" />  <span>{item.contact_number}</span></p>
                        </div>
                        <div>
                            <p className='m-0'>  <span>Order Id:</span><span> {item.order_id} </span></p>
                        </div>
                        <div>
                            <p className='m-0' style={{ fontSize: "13px" }}>  <span>Created At:</span><span> {item.created_at} </span></p>
                        </div>


                    </div>


                    <div className={`${styles.OrderTemplateedit}`}>
                        {(item.status == "NOT-PAID") && <p className='m-0'>COD</p>}
                        <p className='m-0'>&#8377; {item.order_total}</p>
                        <Link to={`../../orderdetails/${item.order_id}`}> <img src={eye} alt="" onClick={() => {
                            // getTransaction(item.order_id)

                        }} /> </Link>
                    </div>
                </div>
            })
        }</>
    )
}
