import * as React from "react";
import './switch.css';
import { useEffect, useState } from "react";
import { tokenDecode } from "../../utils/helper";
import axios from "axios"
import styled from "styled-components"
const ImageContainer = styled.div
`

  max-height: 64px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 68px;
  object-fit: contain;
}
  `

const Mastercatalog = () => {
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id
  // console.log("current Seller id:-----", current_sellerid)
  const decode = tokenDecode();
  const { data } = decode;
  const [productlist, setproductlist] = useState([])
  const [searchValue, setsearchValue] = useState({
    name: "",
    searchFilter: ""
  })


  useEffect(() => {
    getproductlist()
  }, [])

  async function getproductlist() {
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/showallproductmasters",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      // console.log("data of master product list-->", data)
      if (data) {
        setproductlist(data.data);
        // console.log(data.data)
      }

    } catch (error) { }
  }

  function searchHandler(e) {
    setsearchValue({ ...searchValue, [e.target.name]: e.target.value })
    if (e.target.value == "") {
      getproductlist()
    }

  }
  function selectHandler(e) {
    setsearchValue({ ...searchValue, searchFilter: e.target.value })


  }

  async function searchResult() {
    console.log("searchValue", searchValue)
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/searchproduct",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {

          type: searchValue.searchFilter,
          name: searchValue.name

        },
      });
      // console.log("data of /=master product list-->", data)
      if (data.status == 200) {
        setproductlist(data?.data);
        // console.log(data.data)
      } else {
        setproductlist([])
      }

    } catch (error) { }
  }

  function handleSort(e) {
    // console.log(e.target.value);
    if (e.target.value == 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.selling_price) - parseInt(b.selling_price);
      });
      setproductlist(sortedLowToHigh)
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.selling_price) - parseInt(a.selling_price);
      });
      setproductlist(sortedHightoLow)
    } else {
      getproductlist()
    }
  }


  // console.log(searchValue)

  return (
    <>
      <div className="row mx-auto">
        <div className="col-12">

          <div className="d-flex searchDiv">
            <select name="searchFilter" id="" className="m-0" onChange={selectHandler} value={searchValue.searchFilter} >
              <option value="">Search By</option>
              <option value="Product">Product</option>
              <option value="Brand">Brand</option>
              <option value="Category">Category</option>


            </select>

            <input
              type="search"
              name="name"
              placeholder="Product, Category or Brand"
              className="w-100 mr-2 rounded-pill m-0"
              value={searchValue.name}
              onChange={searchHandler}
              style={{ fontSize: "14px" }}
            />

          </div>

        </div>
        <div className="col-12 d-flex btn-toolbar" role="toolbar">
          <div className="btn-group p-2 w-50" role="group" style={{ height: "43px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ fontSize: "2.5vw" }}
              onClick={searchResult}
            >
              Apply Filter
            </button>
          </div>
          <div className="btn-group p-2 w-50" role="group">
            <select type="button" className="btn btn-secondary" style={{ fontSize: "2.5vw" }} onChange={handleSort}>
              <option value="">Sort</option>
              <option value="0">Low - High</option>
              <option value="1">High - Low</option>
            </select>
          </div>
        </div>
        {
          productlist.map((item, index) => {
            return <div className="d-flex w-100 align-items-center mb-2  py-2" style={{ gap: "3px", position: "relative", background: "white" }}>

              <ImageContainer>
                <img src={item.image} className="w-100" />
              </ImageContainer>
              <div className="prod_desc">
                {item.name} <br />
                Category : {item.category_name}
                {/* Size : 100g */}
                <br />
                Mrp : Rs{item.mrp} <br />

              </div>


              <div className="position-absolute" style={{ top: "3px", right: "5px" }}>
                <label className="switch">
                  <input type="checkbox" checked={item?.in_store} onChange={async (e) => {
                    // console.log("hddshs", item.id);
                    let itemId = item.id;
                    let isCheked =!item?.in_store;

                    // console.log("objecthsjdshdksdjas---", { id: itemId, isCheked });
                    const { data } = await axios({
                      url: "https://shikahrapp.hulsecure.in/public/index.php/api/addmastercatalogproduct",
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                      },
                      data: { id: itemId, isCheked }
                    });
                    const updatedProductlist = [...productlist];
                    updatedProductlist[index] = { ...item, in_store: isCheked };
                    setproductlist(updatedProductlist);

                  }} />
                  <span className="slider round"></span>
                </label>



              </div>
              {/* quick update html */}


            </div>
          })
        }
      </div>
    </>
  );
};

export default Mastercatalog;