import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import InputTemplate from "../../components/Search&Options/InputTemplate";
import "../../components/table.css";
import "../NewmasterPage/Master.css"
import styles from "../../components/Search&Options/Search&Options.module.css"
import ProductTemplate from "../../components/ProductViewTemplate/ProductTemplate";
import searchicon_black from "../../icons/searchicon_black.png"
import style from "../transaction/transaction.module.css"
import Pagination from "../../components/Paginated/Pagination";
const { apiEndpoint } = config;
var $ = require("jquery");
var count = 1;
const ProductList = () => {
  const [productList, setProductList] = useState([]);
  const [sproductList, setSproductList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [sellers, setSellers]: any = useState({
    seller: "",
  });
  const rawData = {
    seller_id: "",
  };
  const rawpData = {
    seller_id: "",
    mrp: "",
  };
  const rawsData = {
    seller_id: "",
    sku: "",
  };
  const [categories, setCategories]: any = useState();
  const [searchData, setSearchData]: any = useState(rawData);
  const [searchpData, setSearchpData]: any = useState(rawpData);
  const [searchsData, setSearchsData]: any = useState(rawsData);

  const [valuename, setValuename] = useState("");
  const [valueprice, setValueprice] = useState("");
  const [valuesku, setValuesku] = useState("");
  const [toggle, setToggle] = useState(false);
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;
  const categoryListing = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerproducts`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      if (data.status === 200) {
        setProductList(data.data);
      }
    } catch (error) { }
  };
  const fetchSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchCatgoryList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getcategorylist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      console.log(data)
      if (data.status === 200) {
        setCategoryList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteProduct = async (id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/deleteproductv`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      if (data.status === 200) {
        setProductList(productList.filter((item: any) => item.id !== id));
        toast.success("Product deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const inputHandler = async (e: any) => {
    var optionvalue = e.target.value;
    setValuename("");
    setValueprice("");
    setValuesku("");
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        setToggle(true);
        /*if(e.target.name==="seller"){etSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                setSellers({[e.target.name]: e.target.value})
            }
            if(e.target.name==="category"){
                setCategories({[e.target.name]: e.target.value})
            }
            console.log(sellers);*/
        console.log(optionvalue);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductbyseller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
          setSproductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setProductList(sproductList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id } = searchData;

        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });

        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });

        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputpHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueprice(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id, mrp } = searchpData;
        setToggle(true);

        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        console.log("line 239" + rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            mrp: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputppHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueprice(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        console.log("line 239" + rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            mrp: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputsHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuesku(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id, sku } = searchsData;
        console.log(searchsData);
        setToggle(true);

        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        console.log("line 195" + rawsData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            sku: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputssHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuesku(optionvalue);
    if (optionvalue) {
      try {
        console.log(searchsData);
        setToggle(true);

        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        console.log("line 195" + rawsData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            sku: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputHandlerforcategory = async (e: any) => {
    try {
      var optionvalue = e.target.value;
      const { data } = await axios({
        url: `${apiEndpoint}/filtercategory`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          category_id: optionvalue,
        },
      });
      console.log("product data", data.response);

      if (data.status === 200) {
        setProductList(data.response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row: any) => {
        return (
          <img
            src={
              row.image
                ? row.image
                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
            }
            alt="ok"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Price",
      Footer: "Price",
      accessor: "mrp",
      sticky: "left",
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row: any) => {
        return <>{row.status ? "Active" : "Draft"}</>;
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row: any) => {
        return (
          <>
            {product.update ? (
              <Link
                to={`../../product/${row.id}`}
                className="btn btn-success btn-circle"
              >
                <i className="fas fa-pen"></i>
              </Link>
            ) : null}
            {product.delete ? (
              <button
                className="btn btn-danger btn-circle"
                onClick={() => deleteProduct(row.id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}
          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    categoryListing();
    fetchSellerList();
    fetchCatgoryList();
  }, []);

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary text-left">
                  Product list
                </h6>
                {seller?.read ? (
                  <div className={style.sellerListOptions}>
                    <b>Filter By Seller: </b>
                    <select
                      name="seller_id"
                      id=""
                      onChange={(e: any) => inputHandler(e)}

                    >
                      <option value="">Select Seller</option>
                      {sellerList.map((item: any) => (
                        <option value={item?.seller_id}>{item?.name}</option>
                      ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}

                {/*<b>Filter By Category: </b><select name="category" id="" onChange={(e:any)=>inputHandlerforcategory(e)} defaultValue="">
                                <option value="">Select Category</option>
                                {
                                    categoryList.map((item:any)=>(
                                        <option value={item?.id}>{item?.category_name}</option>
                                    ))
                                }
                                       
                            </select>*/}
                <div className="search mt-1">
                  <div className="row">
                    <div className="d-flex w-100" style={{ flexWrap: "wrap" }}>
                      <div className={styles.inputMain}>
                        <img src={searchicon_black} alt="" /><input
                          type="search"
                          name="name"
                          placeholder="Search by name"
                          className="mr-2"
                          onChange={(e) => {
                            seller?.read ? inputeHandler(e) : inputeeHandler(e);
                          }}
                          value={valuename ? valuename : ""}
                        /> </div>
                      <div className={styles.inputMain}>
                        <img src={searchicon_black} alt="" /> <input
                          type="search"
                          name="mrp"
                          placeholder="Search by price"
                          className="mr-2"
                          onChange={(e) => {
                            seller?.read ? inputpHandler(e) : inputppHandler(e);
                          }}
                          value={valueprice ? valueprice : ""}
                        /> </div>
                      <div className={styles.inputMain}>
                        <img src={searchicon_black} alt="" /> <input
                          type="search"
                          name="sku"
                          placeholder="Search by sku"
                          className="mr-2"
                          onChange={(e) => {
                            seller?.read ? inputsHandler(e) : inputssHandler(e);
                          }}
                          value={valuesku ? valuesku : ""}
                        /></div>
                    </div>
                    {/* <div className="inputs">
                      <InputTemplate placeholder="Search By Name" />
                      <InputTemplate placeholder="Search By Price" />
                      <InputTemplate placeholder="Search By Sku" />
                    </div> */}
                  </div>

                </div>
              </div>

              <div className="card-body" style={{minHeight: "60vh"}}>
                <div className="table-responsive">
                  {/*} <table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Sku</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                productList.map((item: any, index: any) => (
                                                    <tr key={item?.id}>
                                                        <td> {index + 1} </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <img src={item?.image ? item?.image : "http://cdn.onlinewebfonts.com/svg/img_546302.png"} alt="ok" height={80} width={80} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> {item?.name} </td>
                                                        <td> {item?.mrp} </td>
                                                        <td> {item?.sku}</td>
                                                        <td> {item?.status ? "Active" : "Draft"}</td>

                                                        <td>
                                                            <div className="row">
                                                                {
                                                                    product?.update ? (
                                                                        <div className="col-md-4">
                                                                            <Link to={`/product/${item?.id}`} className="btn btn-success btn-circle">
                                                                                <i className="fas fa-eye"></i>
                                                                            </Link>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {
                                                                    product?.delete ? (
                                                                        <div className="col-md-4">
                                                                            <button className="btn btn-danger btn-circle"
                                                                                onClick={() => deleteProduct(item?.id)}
                                                                            >
                                                                                <i className="fas fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : null
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>*/}
                  <div className="productListing_desktop">
                    <Paginated data={productList} columns={COLUMNS} />
                  </div>
                  <div className="productListing_mobile">

                  {/* <ProductTemplate data={productList} deleteProduct={deleteProduct} handle="product" categoryListing={categoryListing}></ProductTemplate> */}
                  <Pagination itemsPerPage={20}  items={productList} deleteProduct={deleteProduct} handle="product" categoryListing={categoryListing}></Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default ProductList;
