import React from "react";
import ThemeMaker from "../../components/thememaker";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Mastercatalog from "./Mastercatalog"
// import MasterCatalog from "../catalog/"
import Instorecatalog from "./Instorecatalog";
import CreateProduct from "./createProduct";

const index = () => {

  return (
    <>
      <ThemeMaker>
      <div className="row m-0 w-100 mt-1">
        <div className="col-md-12 p-0">
            <div className="card shadow mb-4">
                <div className="card-header py-3" style={{minHeight: "90vh"}}>
                <Tabs>

                  <TabList className="d-flex justify-content-start">
                    <Tab style={{ fontSize:'3.2vw', padding: "6px 4px", fontWeight: "500" }}>Master Catalog</Tab>
                    <Tab style={{ fontSize:'3.2vw', padding: "6px 4px", fontWeight: "500"}}>In-store Products</Tab>
                    <Tab style={{ fontSize:'3.2vw', padding: "6px 4px", fontWeight: "500"}}>Create New Product</Tab>

                  </TabList>

                  <TabPanel>
                    <Mastercatalog/>
                  </TabPanel>
                  <TabPanel>
                    <Instorecatalog/>
                  </TabPanel>
                  <TabPanel>
                    <CreateProduct/>
                  </TabPanel>
                </Tabs>
                </div>
            </div>
        </div>
      </div>
        
        
      </ThemeMaker>
    </>
  );
};


export default index;

