import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import OrderView from "../../components/OrderViewTemplate/OrderView";
import styles from "../../components/OrderViewTemplate/OrderView.module.css";
import phone from "../../icons/phoneicon.png";
import mail from "../../icons/mailicon.png";
//import { toast } from "react-toastify";
const { apiEndpoint } = config;

var $ = require("jquery");
// const badgeMaker: any = {
//     "Created": (order_state: any) => <span className="badge badge-primary">{order_state}</span>,
//     "Shipped": (order_state: any) => <span className="badge badge-warning">{order_state}</span>,
//     "Delivered": (order_state: any) => <span className="badge badge-success">{order_state}</span>,
//     "Canceled": (order_state: any) => <span className="badge badge-danger">{order_state}</span>,
// }
// interface ICustomer {
//     id: any;
// }
const OrderDetails = () => {
  let params = useParams();
  const { id: order_id } = params;
  const [alldetails, setalldetails] = useState([]);
  console.log("all details-------------", alldetails);
  const decoded = tokenDecode();
  const [isLoading, setIsLoading] = useState(false);
  const [updateOrder_, setUpdateOrder_] = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [orderDetail, setorderDetail] = useState([]);
  const [_orderDetail, _setorderDetail] = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });
  const [orderState, setOrderState] = useState({
    order_id,
    order_state: "",
    fulfillment_state: "",
    tracking_url: "",
  });

  const [addButton, setaddButton] = useState(true);
  // const shipping_address = JSON.parse(_orderDetail?.shipping_address);

  const [orderStatus, setorderStatus] = useState([
    "Created",
    "Accepted",
    "In-progress",
    "Completed",
    "Cancelled",
    "Returned",
  ]);

  const getorderDetails = async (order_id) => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorder`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id,
        },
      });
      console.log("order detail ->------   ", data);
      console.log("order state ->------   ", data.data[0].order_state);
      if (data.status === 200) {
        setIsLoading(false);
        setorderDetail(data.products);
        setalldetails(data);
        let totalP = 0;
        data.products.forEach((item) => {
          totalP += parseFloat(item?.price);
        });

        if (data.data[0].order_state !== "") {
          const index = orderStatus.indexOf(data.data[0].order_state);
          if (index !== -1) {
            setorderStatus(orderStatus.slice(index));
          }
        }

        setOrderState({
          ...orderState,
          order_state: data.data[0]?.order_state,
          tracking_url: data.products[0]?.tracking_url,
          fulfillment_state: data.products[0]?.fulfillment_state,
        });
        setUpdateOrder_({
          ...updateOrder_,
          order_id: data.data[0]?.order_id,
          tracking_url: data.data[0]?.tracking_url,
          order_state: data.data[0]?.order_state,
        });
        _setorderDetail({
          ..._orderDetail,
          ...data.data[0],
          total_price: data.data[0].order_total,
          billing_address: JSON.parse(data.data[0]?.billing),
          shipping_address: JSON.parse(data.data[0]?.fulfillments),
        });
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const updateOrder = async (product_id) => {
    try {
      console.log(orderDetail);
      const getItem = orderDetail.find(
        (item) => item?.product_id === product_id
      );
      console.log("getItem", getItem);

      const { data } = await axios({
        url: `${apiEndpoint}/updateitemstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: getItem,
      });
      console.log(data, "datadata");

      if (data.message === "flag1") {
        toast.success("Item status updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("yess", error);

      // console.log("update error", error);
      // toast.error("404 error !", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      // });

      // window.location.reload();
    }
  };
  const updateOrderChangeHandler = (e, index) => {
    // setUpdateOrder_({ ...updateOrder_, [e.target.name]: e.target.value })
    console.log(e.target.value, "index---", e.target.name, index);
    const newUpdate = orderDetail;
    newUpdate[index][e.target.name] = e.target.value;
    console.log(newUpdate, "newUpdate");

    setorderDetail([...newUpdate]);
  };
  const updateThisOrder = async () => {
    try {
      console.log("orderState---->", orderState);
      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: orderState,
      });
      console.log(data, "datadata");

      if (data.message === "flag1") {
        toast.success("Order status updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong 500 !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    getorderDetails(order_id);
    // console.log(_orderDetail.order_state, "<-----------_orderDetail.order_state ")
  }, []);
  // if (_orderDetail.order_state !== "") {
  //     const index = orderStatus.indexOf(_orderDetail.order_state);
  //     if (index !== -1) {
  //       setorderStatus(orderStatus.slice(index));
  //     }
  //   }
  console.log("orderDetails---->", _orderDetail);
  console.log("orderStatus---->", orderStatus);

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12 " style={{ fontSize: "14px" }}>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Order Detail
                </h6>
              </div>
              <div className="d-none">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Order id: {_orderDetail?.order_id}
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-5">
                      <p> Product name </p>
                    </div>
                    <div className="col-md-1">
                      <p> Price x quantity </p>
                    </div>
                    <div className="col-md-1">
                      <p> Price </p>
                    </div>
                    {/* <div className="col-md-5 d-flex justify-content-between">
                                        <p> Fulfillment Status  </p>
                                        <p> Tracking Url  </p>
                                        <p> Action  </p>
                                    </div> */}
                  </div>
                  {orderDetail.map((_iitm, index) => {
                    return (
                      <div className="row mt-1">
                        <div className="col-md-5">
                          <p>
                            {" "}
                            <span>{index + 1}. </span>
                            {_iitm?.product_name}
                          </p>
                        </div>
                        <div className="col-md-1">
                          <p>
                            {" "}
                            {_iitm?.price} x {_iitm?.quantity}
                          </p>
                        </div>
                        <div className="col-md-1">
                          <p> {_iitm?.price * _iitm?.quantity} </p>
                        </div>
                        {/* <div className="col-md-5 d-flex">

                                                    <select value={_iitm?.fulfillment_state} className="form-control" name="fulfillment_state"
                                                        onChange={(e) => updateOrderChangeHandler(e, index)}
                                                    >
                                                        <option value="Serviceable">Serviceable</option>
                                                        <option value="Order-picked-up">Order-picked-up</option>
                                                        <option value="Out-for-delivery">Out-for-delivery</option>
                                                        <option value="Order-delivered">Order-delivered</option>
                                                        <option value="RTO-Initiated">RTO-Initiated</option>
                                                        <option value="RTO-Delivered">RTO-Delivered</option>
                                                        <option value="Cancelled">Cancelled</option>

                                                    </select>
                                                    <input type="text" value={_iitm?.tracking_url} name="tracking_url" className="form-control" id=""
                                                        onChange={(e) => updateOrderChangeHandler(e, index)}
                                                    />
                                                    <button
                                                        className="btn btn-success ml-2"
                                                        onClick={() => updateOrder(_iitm?.product_id)}
                                                    >Save</button>
                                                </div> */}
                      </div>
                    );
                  })}
                  <div className="row alert alert-info">
                    <div className="col-md-6">
                      <p>Total</p>
                    </div>
                    <div className="col-md-3">
                      <p> </p>
                    </div>
                    <div className="col-md-3">
                      <p> {_orderDetail?.total_price} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 card ml-5 mb-3">
                      <p className="font-weight-bold"> Customer </p>
                      <p>Name : {_orderDetail?.name}</p>
                    </div>
                    <div className="col-md-5 card ml-5 mb-3">
                      <p className="font-weight-bold"> Contact Information </p>
                      <p>Email : {_orderDetail?.email}</p>
                      <p> Phone : {_orderDetail?.contact_number}</p>
                    </div>
                    <div className="col-md-5 card ml-5 mb-3">
                      <p className="font-weight-bold"> Billing Address </p>
                      <p>
                        Door : {_orderDetail?.billing_address?.address?.door}
                      </p>
                      <p>
                        Name : {_orderDetail?.billing_address?.address?.name}
                      </p>
                      <p>
                        Building :{" "}
                        {_orderDetail?.billing_address?.address?.building}
                      </p>
                      <p>
                        Street :{" "}
                        {_orderDetail?.billing_address?.address?.street}
                      </p>
                      <p>
                        Locality :{" "}
                        {_orderDetail?.billing_address?.address?.locality}
                      </p>
                      <p>
                        Ward : {_orderDetail?.billing_address?.address?.ward}
                      </p>
                      <p>
                        City : {_orderDetail?.billing_address?.address?.city}
                      </p>
                      <p>
                        State : {_orderDetail?.billing_address?.address?.state}
                      </p>
                      <p>
                        Country :{" "}
                        {_orderDetail?.billing_address?.address?.country}
                      </p>
                      <p>
                        Area code :{" "}
                        {_orderDetail?.billing_address?.address?.area_code}
                      </p>
                      <p>Phone : {_orderDetail?.billing_address?.phone}</p>
                      <p>Email : {_orderDetail?.billing_address?.email}</p>
                    </div>
                    <div className="col-md-5 card ml-5 mb-3">
                      <p className="font-weight-bold"> Shipping Address </p>
                      <p>
                        Door :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.door
                        }
                      </p>
                      <p>
                        Name :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.name
                        }
                      </p>
                      <p>
                        Building :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.building
                        }
                      </p>
                      <p>
                        Street :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.street
                        }
                      </p>
                      <p>
                        Locality :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.locality
                        }
                      </p>
                      <p>
                        Ward :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.ward
                        }
                      </p>
                      <p>
                        City :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.city
                        }
                      </p>
                      <p>
                        State :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.state
                        }
                      </p>
                      <p>
                        Country :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.country
                        }
                      </p>
                      <p>
                        Area code :{" "}
                        {
                          _orderDetail?.shipping_address[0]?.end?.location
                            ?.address?.area_code
                        }
                      </p>
                      <p>
                        Phone :{" "}
                        {_orderDetail?.shipping_address[0]?.end?.contact?.phone}
                      </p>
                      <p>
                        Email :{" "}
                        {_orderDetail?.shipping_address[0]?.end?.contact?.email}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor=""> Order status </label>
                    <select
                      value={orderState?.order_state}
                      className="form-control"
                      name="order_state"
                      onChange={(e) =>
                        setOrderState({
                          ...orderState,
                          [e.target.name]: e.target.value,
                        })
                      }
                    >
                      <option value="Created">Created</option>
                      <option value="Accepted">Accepted</option>
                      <option value="In-progress">In-progress</option>
                      <option value="Completed">Completed</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Returned">Returned</option>
                    </select>
                  </div>
                  <div className="col-md-6 mt-2">
                    <label htmlFor=""> Fulfillment status </label>
                    <select
                      value={orderState?.fulfillment_state}
                      className="form-control"
                      name="fulfillment_state"
                      onChange={(e) =>
                        setOrderState({
                          ...orderState,
                          [e.target.name]: e.target.value,
                        })
                      }
                    >
                      <option value="Serviceable">Serviceable</option>
                      <option value="Order-picked-up">Order-picked-up</option>
                      <option value="Out-for-delivery">Out-for-delivery</option>
                      <option value="Order-delivered">Order-delivered</option>
                      <option value="RTO-Initiated">RTO-Initiated</option>
                      <option value="RTO-Delivered">RTO-Delivered</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Returned">Returned</option>
                    </select>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor=""> Tracking Url </label>
                    <input
                      type="text"
                      value={orderState?.tracking_url}
                      name="tracking_url"
                      className="form-control"
                      id=""
                      onChange={(e) =>
                        setOrderState({
                          ...orderState,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-3 mt-2">
                    <button
                      className="btn btn-success"
                      onClick={updateThisOrder}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>

              {/* code for mobile view */}
              {orderDetail.map((item, index) => {
                let total_price = item.price * item.quantity;
                return (
                  <div className={styles.OrderTemplatemain}>
                    {/* <div className={`${styles.OrderTemplateImage}`}>
                                        <span>{index}</span>
                                    </div> */}
                    <div className={`${styles.OrderTemplateDesc}`}>
                      <p className="m-0 mb-1">
                        Order Id: <span>{item.order_id} </span>
                      </p>
                      <p className="m-0">
                        Product Name:{" "}
                        <span className="text-capitalize">
                          {" "}
                          {item.product_name}{" "}
                        </span>
                      </p>

                      <p className="m-0 mb-1">
                        Price x Qunatity:{" "}
                        <span>
                          {" "}
                          {item.price} X {item.quantity}{" "}
                        </span>
                      </p>
                      {/* <p className='m-0 mb-1'>Order State: <span> {item.order_state} </span></p> */}

                      <hr />

                      <p className="d-flex justify-content-between">
                        <span>TOTAL</span>
                        <span>{total_price}</span>
                      </p>
                    </div>
                  </div>
                );
              })}

              <div className={styles.OrderTemplatemain}>
                {/* <div className={`${styles.OrderTemplateImage}`}>
                                    <span>01</span>
                                </div> */}
                <div className={`${styles.OrderTemplateDesc}`}>
                  <p className="m-0 text-capitalize">
                    {" "}
                    <span> {_orderDetail.name}</span>
                  </p>
                  <p className="m-0 mb-1">
                    <img src={phone} alt="" />
                    <span> {_orderDetail.contact_number} </span>
                  </p>
                  <p className="m-0 mb-1">
                    <img src={mail} alt="" /> <span>{_orderDetail.email} </span>
                  </p>
                </div>
              </div>

              <div className={styles.addressMain}>
                <div>
                  <div>
                    <button
                      onClick={() => {
                        setaddButton(true);
                      }}
                      className={
                        addButton ? styles.blueButton : styles.whiteButton
                      }
                    >
                      Billing Address
                    </button>

                    <button
                      onClick={() => {
                        setaddButton(false);
                      }}
                      className={
                        addButton ? styles.whiteButton : styles.blueButton
                      }
                    >
                      Shipping Address{" "}
                    </button>
                  </div>
                  {addButton ? (
                    <>
                      <div>
                        <p>
                          Name:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.name}
                          </span>
                        </p>
                        <p>
                          Mob:{" "}
                          <span>{_orderDetail?.billing_address?.phone}</span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Door:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.door}
                          </span>
                        </p>
                        <p>
                          Building:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.building}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Street:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.street}
                          </span>
                        </p>
                        <p>
                          Locality:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.locality}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          City:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.city}
                          </span>
                        </p>
                        <p>
                          State:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.state}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Area code:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.area_code}
                          </span>
                        </p>
                        <p>
                          Ward:{" "}
                          <span>
                            {_orderDetail?.billing_address?.address?.ward}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Country:{" "}
                          <span>{_orderDetail?.billing_address?.country}</span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Email:{" "}
                          <span>{_orderDetail?.billing_address?.email}</span>
                        </p>
                      </div>{" "}
                    </>
                  ) : (
                    <>
                      <div>
                        <p>
                          Name:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.customer
                                ?.person?.name
                            }
                          </span>
                        </p>
                        <p>
                          Mob:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.contact
                                ?.phone
                            }
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Door:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.door
                            }
                          </span>
                        </p>
                        <p>
                          Building:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.building
                            }
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Street:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.street
                            }
                          </span>
                        </p>
                        <p>
                          {/* Locality: <span>{_orderDetail?.shipping_address[0]?.end?.location?.locality}</span> */}
                        </p>
                      </div>
                      <div>
                        <p>
                          City:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.city
                            }
                          </span>
                        </p>
                        <p>
                          State:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.state
                            }
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Area code:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.area_code
                            }
                          </span>
                        </p>
                        <p>
                          Ward:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.ward
                            }
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Country:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.location
                                ?.country
                            }
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Email:{" "}
                          <span>
                            {
                              _orderDetail?.shipping_address[0]?.end?.contact
                                ?.email
                            }
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex p-2">
                <div className="w-50">
                  <label htmlFor="" className={styles.labels}>
                    {" "}
                    Order status{" "}
                  </label>
                  <select
                    value={orderState?.order_state}
                    className="form-control"
                    name="order_state"
                    onChange={(e) =>
                      setOrderState({
                        ...orderState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    style={{ width: "90%" }}
                  >
                    {orderStatus.map((item) => {
                      return (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      );
                    })}
                    {/* <option value="Created">Created</option>
                                        <option value="Accepted">Accepted</option>
                                        <option value="In-progress">In-progress</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Returned">Returned</option> */}
                  </select>
                </div>

                <div className="w-50">
                  <label htmlFor="" className={styles.labels}>
                    {" "}
                    Fulfillment status{" "}
                  </label>
                  <select
                    value={orderState?.fulfillment_state}
                    className="form-control"
                    name="fulfillment_state"
                    onChange={(e) =>
                      setOrderState({
                        ...orderState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    style={{ width: "90%" }}
                  >
                    <option value="Serviceable">Serviceable</option>
                    <option value="Order-picked-up">Order-picked-up</option>
                    <option value="Out-for-delivery">Out-for-delivery</option>
                    <option value="Order-delivered">Order-delivered</option>
                    <option value="RTO-Initiated">RTO-Initiated</option>
                    <option value="RTO-Delivered">RTO-Delivered</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Returned">Returned</option>
                  </select>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <label htmlFor="" className={styles.labels}>
                  {" "}
                  Tracking Url{" "}
                </label>
                <input
                  type="text"
                  value={orderState?.tracking_url}
                  name="tracking_url"
                  className="form-control"
                  id=""
                  onChange={(e) =>
                    setOrderState({
                      ...orderState,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-md-3 mt-2 mb-3">
                <button className="btn btn-primary" onClick={updateThisOrder}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default OrderDetails;
