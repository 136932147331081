import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { config } from "../../utils/config";
import { BasicTable } from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import UserTemplate from "../../components/UserTemplate/UserTemplate";
import '../../components/table.css';
import styles from "../transaction/transaction.module.css"
import searchicon_black from "../../icons/searchicon_black.png"
import style from "../../components/Search&Options/Search&Options.module.css"
import Sellerpagination from "../../components/Paginated/Sellerpagination";
const { apiEndpoint } = config;
var $ = require('jquery');

var count = 1;
const UserList = () => {
    const [userlist, setUserList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sellerList, setSellerList] = useState([]);
    const rawData = {
        seller_id: ''
    }
    const [searchData, setSearchData]: any = useState(rawData);
    const decode = tokenDecode();
    const { data } = decode;
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const fetchUserList = async () => {
        try {
            setLoading(true);
            const { data } = await axios({
                url: `${apiEndpoint}/getuserlist`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id,
                }
            });
            console.log("user listing", data);
            setLoading(false);
            if (data.status === 200) {
                setUserList(data.data);
            }
            setTimeout(() => {
                $('#dataTable').DataTable();
            }, 500)
        } catch (error) {

        }
    }
    const userDelete = async (id: any) => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/userdelete`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: { id }
            });
            console.log("data----", data);
            if (data.status === 200) {
                setUserList(userlist.filter((item: any) => item.id !== id));
                toast.success("User deleted !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const getSellerList = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            if (data.status === 200) {
                console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        try {

            var optionvalue = e.target.value;
            setSearchData({ ...searchData, [e.target.name]: e.target.value });
            console.log(optionvalue);
            const { data } = await axios({
                url: `${apiEndpoint}/getuserfilterbyseller`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: optionvalue
                }

            });

            if (data.status === 200) {
                console.log(data);
                setUserList(data.data);
            }


        } catch (error) {
            console.log("error", error);
        }
    };
    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Name",
            Footer: "Name",
            accessor: "name",
            sticky: "left"
        },
        {
            Header: "Email",
            Footer: "Email",
            accessor: "email",
            sticky: "left"
        },
        {
            Header: "Contact",
            Footer: "Contact",
            accessor: "contact_no",
            sticky: "left"
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row: any) => {
                return <>{row.status ? <span className="badge badge-success">Active</span> :
                    <span className="badge badge-danger">Deactive</span>}</>
            },
            sticky: "left"
        },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                return (
                    <>
                        {user.update ? <Link to={`../../updateuser/${row.id}`} className="btn btn-success btn-circle"> <i className="fas fa-eye"></i></Link> : null}{user.delete ? <button className="btn btn-danger btn-circle" onClick={() => userDelete(row.id)}><i className="fas fa-trash"></i></button> : null}
                    </>
                )
            },
            sticky: "left"
        },

    ];
    count++
    useEffect(() => {
        // $('#dataTable').DataTable();
        fetchUserList();
        getSellerList();
    }, [])
    const [searchValue, setsearchValue] = useState("")
    const masterSearchHandler = async (e: any) => {
        let searchedData = e.target.value;
        setsearchValue(searchedData)
       
            let dataToSend = {
                title: searchedData
            }
            console.log(dataToSend)
            try {
                const { data } = await axios({
                    url: "https://shikahrapp.hulsecure.in/public/index.php/api/searchUserFilter",
                    method: "post",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                    },
                    data: dataToSend
                })
                console.log("Response from Server-------------", data)
                if (data.status == 200) {
                    setUserList(data.message)
                } else {
                    console.log("Search API is not running!!");
                }

            } catch (err) {
                console.log(err)
            }
        
    }
    return (
        <>
            <ThemeMaker>

                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    User list
                                </h6>
                                {/* {seller?.read ?
                                    <div className={styles.sellerListOptions}>
                                        <b>Filter By Seller: </b> <div className={styles.selectionControl}>
                                            <select name="seller_id" id="" onChange={(e: any) => inputHandler(e)} >
                                                <option value="">Select Seller</option>
                                                {
                                                    sellerList.map((item: any) => (
                                                        <option value={item?.seller_id} key={item?.seller_id}>{item?.name}</option>
                                                    ))
                                                }

                                            </select></div></div>
                                    : ""} */}

                                <div className="search mt-1">
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <div className={`${style.inputMain} w-75 mt-2`} style={{ margin: "10px auto" }}>
                                            <img src={searchicon_black} alt="" style={{ width: "6%" }} />
                                            <input
                                                type="search"
                                                name="name"
                                                placeholder="Search User"
                                                className="mr-2 w-100"
                                                // onChange={(e) => {
                                                //     seller?.read ? inputeHandler(e) : inputeeHandler(e);
                                                // }}
                                                // value={valuename ? valuename : ""}
                                                value={searchValue}
                                                onChange={masterSearchHandler}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body p-0 p-1" style={{ minHeight: "70vh" }}>
                            <div className="table-responsive">
                                {/* <table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Contact</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                userlist.map((item: any, index: any) => (
                                                    <tr>
                                                        <td> {index + 1} </td>
                                                        <td>{item?.name} </td>
                                                        <td>{item?.email}</td>
                                                        <td>{item?.contact_no}</td>
                                                        <td>
                                                            {
                                                                item?.status ?
                                                                    <span className="badge badge-success">Active</span> :
                                                                    <span className="badge badge-danger">Deactive</span>
                                                            }
                                                        </td>
                                                        <td>

                                                            <div className="row">
                                                                {
                                                                    user?.update ? (
                                                                        <div className="col-md-4">
                                                                            <Link to={`/updateuser/${item?.id}`} className="btn btn-success btn-circle">
                                                                                <i className="fas fa-eye"></i>
                                                                            </Link>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {
                                                                    user?.delete ? (
                                                                        <div className="col-md-4">
                                                                            <button className="btn btn-danger btn-circle"
                                                                                onClick={() => userDelete(item?.id)}
                                                                            >
                                                                                <i className="fas fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : null
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }


                                        </tbody>
                                        </table>*/}
                                {/* <Paginated data={userlist} columns={COLUMNS} />   */}

                                <div className="productListing_desktop">
                                    <Paginated data={userlist} columns={COLUMNS} />
                                </div>
                                <div className="productListing_mobile">
                                    {/* <UserTemplate userlist={userlist} userDelete={userDelete}></UserTemplate> */}
                                    <Sellerpagination itemsPerPage={20} items={userlist} sellerDelete={userDelete} paginationFor="user"></Sellerpagination>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </ThemeMaker>
        </>
    )
}

export default UserList;