import { Link } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";
import { useState } from "react";
import logoimg from "../../../../src/icons/logoimg.png";
import styles from "./Side_bar.module.css";
import { useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import dashboardicon from "../../../icons/dashboardicon.png";
import Navbar from "../navbar";
const Sidebar = () => {

  const [arrows, setArrows] = useState({});
  const decoded = tokenDecode();
  const { data } = decoded;
  const {
    permission: {
      category,
      order,
      product,
      role,
      seller,
      tax,
      user,
      location,
      mastercatalog,
    },
  } = data;
  // console.log("hhhh", decoded);
  // console.log("permissions>>>>>>", data)
  if (decoded) {
    // if (isAdmin === "true") {
    //     return children;
    // }
  } else {
    console.log("no");
  }

  const [numberOfNAP, setnumberOfNAP] = useState([]);


  const [sidebarShow, setsidebarShow] = useState(checkstatus())

  function checkstatus() {
    const token = localStorage.getItem("us_st_d");
    const tokenDecode = jwt_decode(token)
    // console.log("tokendecode from siebar---->", tokenDecode)

    return tokenDecode.data.user.approve_status
  }
  // console.log("seller approve status from sidebar---->", sidebarShow)
  useEffect(() => {

    async function getNAP() {
      try {
        const { data } = await axios({
          url: "https://shikahrapp.hulsecure.in/public/index.php/api/listNonApproveProduct",
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: decoded?.data?.user?.seller_id,
          },
        });

        if (data.status === 200) {
          setnumberOfNAP(data.data);
          // console.log(data)
        }
      } catch (error) { }
    }
    getNAP();
  }, []);

  // console.log(numberOfNAP)

  // console.log("form sidebar>>>>", decoded.data.user.seller_id)
  let current_sellerid = decoded.data.user.seller_id;

  const handleClick = (id) => {
    setArrows((prevArrows) => {
      const newArrows = Object.fromEntries(
        Object.entries(prevArrows).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prevArrows[id];
      return newArrows;
    });
  };

  return (
    <>
      <div className={styles.sidebar_navbar}>
        <Navbar></Navbar>
      </div>
      {sidebarShow ==1 ? <ul
        className={`navbar-nav sidebar-dark accordion ${styles.side_bar}`}
        id="accordionSidebar"
      >
        <Link to="/dashboard">
          <a
            href="replace"
            className="sidebar-brand d-flex align-items-center justify-content-center"
          >
            <div className="sidebar-brand-icon rotate-n-15"></div>
            <div className="sidebar-brand-text ">
              {" "}
              <img
                src={logoimg}
                style={{ height: "70px", padding: "10px" }}
                alt="logo here"
                className={styles.sideBarLogo}
              />
            </div>
          </a>
        </Link>
        <hr className={`sidebar-divider my-0 ${styles.sideBarLogo}`} />

        <li className="nav-item active">
          <Link to="/dashboard">
            <a
              href="replace"
              className="nav-link d-flex align-items-center justify-content-start"
              style={{ gap: "15px" }}
            >
              {/* <i className="fas fa-users-cog" ></i> */}
              <img src={dashboardicon} alt="" />
              <span>Dashboard</span>
            </a>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Management</div>
        {/* MasterCatalog  field:- */}
        <>
          <li className="nav-item">
            <Link to="#">
              <a
                className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                href="replace"
                data-toggle="collapse"
                data-target="#mastercatalog"
                aria-expanded="true"
                aria-controls="collapseTwo"
                id="master-catalog"
                onClick={() => handleClick("master-catalog")}
              >
                <div className={styles.listItems}>
                  <i className="fas fa-fw fa-tablet"></i>
                  <span>Catalog</span>
                </div>

                <i
                  className={
                    arrows["master-catalog"]
                      ? `fas fa-angle-down `
                      : `fas fa-angle-right `
                  }
                ></i>
              </a>
            </Link>
            <div
              id="mastercatalog"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div
                className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}
              >
                {current_sellerid < 2 ? (
                  <>
                    {" "}
                    <Link to="/mastercatalog">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Add Catalog Product
                      </a>
                    </Link>
                    <Link to="/masterproductlist">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Master Product List
                      </a>
                    </Link>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <Link to="/catalog">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Master Product List
                      </a>
                    </Link>
                  </>
                )
                }
              </div>
            </div>
          </li>

          {/* <li className="nav-item">
            <Link to="#" >
              <a className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass" href="replace" data-toggle="collapse" data-target="#mastercatalog"
                aria-expanded="true" aria-controls="collapseTwo" id="master-catalog" onClick={() => handleClick("master-catalog")}>
                <div className={styles.listItems}>
                  <i className="fas fa-fw fa-tablet"></i>
                  <span>Master Catalog</span>
                </div>

                <i className={arrows["master-catalog"] ? `fas fa-angle-down ` : `fas fa-angle-right `}></i>
              </a>
            </Link>
            <div id="mastercatalog" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
              <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}>

                {(current_sellerid < 2) ? <> <Link to="/mastercatalog" >
                  <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Add catalog Product</a>
                </Link>
                  <Link to="/masterproductlist" >
                    <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Master Product List</a>

                  </Link> </> : <> <Link to="/masterproductlist" >
                    <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Master Product List</a>
                  </Link></>}

              </div>
            </div>
          </li> */}
        </>
        {/* MasterCatalog  field end:- */}

        {/* Non approved Products tab start */}

        {current_sellerid < 2 && (
          <li>
            <Link to="#">
              <a
                className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                href="replace"
                data-toggle="collapse"
                data-target="#non-approved-products"
                aria-expanded="true"
                aria-controls="collapseTwo"
                id="master-catalog"
                onClick={() => handleClick("non-approved-products")}
              >
                <div className={styles.listItems}>
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Pending Product Requests</span>

                  {numberOfNAP.length > 0 && (
                    <span className="">{numberOfNAP.length}</span>
                  )}
                </div>

                <i
                  className={
                    arrows["non-approved-products"]
                      ? `fas fa-angle-down `
                      : `fas fa-angle-right `
                  }
                ></i>
              </a>
            </Link>
            <div
              id="non-approved-products"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div
                className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}
              >
                <Link to="/nonapprovedproducts">
                  <a
                    href="replace"
                    className={`collapse-item  ${styles.collapseitemText}`}
                  >
                    Non Approved Products
                  </a>
                </Link>
              </div>
            </div>
          </li>
        )}

        <li className="nav-item ">
          {seller?.read || seller?.write || tax?.write ? (
            <>
              <Link
                to="#"
                className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                data-toggle="collapse"
                data-target="#seller11"
                aria-expanded="true"
                aria-controls="collapseTwo"
                onClick={() => handleClick("sellers")}
              >
                <div className={styles.listItems}>
                  <i className="fas fa-fw fa-user"></i>
                  <span>Sellers</span>
                </div>

                <i
                  className={
                    arrows["sellers"]
                      ? `fas fa-angle-down `
                      : `fas fa-angle-right `
                  }
                ></i>
              </Link>
              <div
                id="seller11"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div
                  className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}
                >
                  {seller?.write ? (
                    <Link to="/addseller">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Add seller
                      </a>
                    </Link>
                  ) : null}
                  {seller?.read ? (
                    <Link to="/sellerlist">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Seller List
                      </a>
                    </Link>
                  ) : null}
                  {tax.write ? (
                    <Link to="/tax">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Tax
                      </a>
                    </Link>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
          {current_sellerid == 1 && (
            <>
              {" "}
              {user?.read || user?.write || role?.write ? (
                <>
                  <Link
                    to="#"
                    className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    id="users"
                    onClick={() => handleClick("users")}
                  >
                    <div className={styles.listItems}>
                      <i className="fas fa-fw fa-users"></i>
                      <span>Users</span>
                    </div>
                    <i
                      className={
                        arrows["users"]
                          ? `fas fa-angle-down `
                          : `fas fa-angle-right `
                      }
                    ></i>
                  </Link>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                  >
                    <div
                      className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                    >
                      {role?.write ? (
                        <Link to="/roles">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Roles
                          </a>
                        </Link>
                      ) : null}
                      {user?.write ? (
                        <Link to="/adduser">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Add user
                          </a>
                        </Link>
                      ) : null}
                      {user?.read ? (
                        <Link to="/userlist">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            User List
                          </a>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}{" "}
            </>
          )}
        </li>
        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Interface</div>
        {category?.write || product?.read || product?.write ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed d-flex justify-content-between myaddedclass"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  id="products"
                  onClick={() => handleClick("products")}
                >
                  <div className={styles.listItems}>
                    <i className="fas fa-fw fa-cog"></i>
                   { current_sellerid ==1 ? <span>Category & Brand </span> : <span>Non-Approved Products</span>} 
                  </div>
                  <i
                    className={
                      arrows["products"]
                        ? `fas fa-angle-down`
                        : `fas fa-angle-right`
                    }
                  ></i>
                </a>
              </Link>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div
                  className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                >
                  {category?.write ? (
                    <Link to="/newcategory">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Category
                      </a>
                    </Link>
                  ) : null}

                  {current_sellerid < 2 && <Link to="/brand">
                    <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                      Brand
                    </a>
                  </Link>}

                  {/* {product?.write ? (
                    <a
                      href="/product"
                      className={`collapse-item  ${styles.collapseitemText}`}
                    >
                      Add product
                    </a>
                  ) : null} */}
                  {/* {product?.read ? (
                    <Link to="/productlist">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Product List
                      </a>
                    </Link>
                  ) : null} */}
                  {/* {product?.write ? (
                    <Link to="/catalog">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                       
                      >
                        Product Catalog
                      </a>
                    </Link>
                  ) : null} */}

                  {/* ondc seller product tab start */}

                  {/* {current_sellerid > 1 && <> {product?.read ? (
                    <Link to="/ondcsellerproduct">
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                     ONDC Seller Products
                      </a>
                    </Link>
                  ) : null}</>} */}

                  {current_sellerid > 1 && (
                    <>
                      {" "}
                      {product?.read ? (
                        <Link to="/sellernonapprovedproducts">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Non-Approved Products
                          </a>
                        </Link>
                      ) : null}
                    </>
                  )}

                  {/* ondc seller product tab end */}
                </div>
              </div>
            </li>
          </>
        ) : null}
        {order?.read ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed d-flex justify-content-between myaddedclass"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseTwo_1"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  id="orders"
                  onClick={() => handleClick("orders")}
                >
                  <div className={styles.listItems}>
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Orders</span>
                  </div>

                  <i
                    className={
                      arrows["orders"]
                        ? `fas fa-angle-down`
                        : `fas fa-angle-right`
                    }
                  ></i>
                </a>
              </Link>
              <div
                id="collapseTwo_1"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div
                  className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}
                >
                  {order?.read ? (
                    <Link to="/orderlist">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Order List
                      </a>
                    </Link>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : null}
        {order?.read ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed d-flex justify-content-between myaddedclass"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseTwo1_1"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  id="transaction"
                  onClick={() => handleClick("transaction")}
                >
                  <div className={styles.listItems}>
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Transaction</span>
                  </div>
                  <i
                    className={
                      arrows["transaction"]
                        ? `fas fa-angle-down`
                        : `fas fa-angle-right`
                    }
                  ></i>
                </a>
              </Link>
              <div
                id="collapseTwo1_1"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div
                  className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                >
                  {order?.read ? (
                    <Link to="/transaction">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Transaction
                      </a>
                    </Link>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : null}
        {/* {location?.read ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#location"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Location</span>
                </a>
              </Link>
              <div
                id="location"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {location?.read ? (
                    <Link to="/location">
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                        Add location
                      </a>
                    </Link>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : null} */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* MasterCatalog another field:- */}

        {/* <>

          <li className="nav-item">
            <Link to="#" >
              <a className="nav-link collapsed" href="replace" data-toggle="collapse" data-target="#mastercatalog"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-cog"></i>
                <span>Master Catalog</span>
              </a>
            </Link>
            <div id="mastercatalog" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">

                {(current_sellerid < 2) ? <> <Link to="/mastercatalog" >
                  <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Add catalog Product</a>
                </Link>
                  <Link to="/masterproductlist" >
                    <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Master Product List</a>
                  </Link></> : <> <Link to="/masterproductlist" >
                    <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Master Product List</a>
                  </Link></>}

              </div>
            </div>
          </li>

        </> */}
      </ul> : <h5 className="text-center">Approval Status is pending</h5>}
    </>
  );
};

export default Sidebar;