/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../../components/thememaker/navbar";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { show, hide } from "react-functional-modal"
import styled from "styled-components"

const s3Config = {
  bucketName: "bucket-name",
  dirName: "directory-name" ,
  region: "ap-south-1",
  accessKeyId: "ABCD12EFGH3IJ4KLMNO5",
  secretAccessKey: "a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0",
  s3Url: "https:/your-aws-s3-bucket-url/",
};
const aws = require("aws-sdk");
const ImageContainer = styled.div
`
 border: 1px solid black;
    border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

    
    img{
      width: 100%!important;
    height: 80%;
    object-fit: contain;
  }

  button{
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
    `


const createProduct = () => {
  const decode = tokenDecode();
  let current_sellerid = decode.data.user.seller_id;
  const [read, setRead] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [showMenu, setshowMenu] = useState(false)
  const [brandlist, setbrandlist] = useState([]);
  let rawSubCatData = {
    parent_id: "",
    name: "",
    status: true,
    id: "",
  };
  const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const [Subcategorydata, setSubcategorydata] = useState(rawSubCatData);
  const [taxData, setTaxData] = useState([]);
  const [readOnly, setreadOnly] = useState(false);
  const rawData = {
    seller_id: decode?.data?.user?.seller_id,
    name: "",
    net_qty: "",
    pc_manufacturer_or_packer_name: "",
    quantity: "",
    short_description: "",
    description: "",
    categories_id: Subcategorydata.parent_id,
    mrp: 0,
    selling_price: 0,
    tax_included_price: 0,
    tax: 0,
    tags: "",
    image: "",
    sku: "",
    hsn: "",
    position: "",
    status: false,
    food_type_veg: "",
    returnable: "",
    cancelable: "",
    return_window: "",
    seller_pickup_return: "",
    time_to_ship: "",
    cod: "",
    product_type: "",
    preservatives: "",
    preservatives_details: "",
    flavours_and_spices: "",
    ready_to_cook: "",
    ready_to_eat: "",
    rice_grain_type: "",
    recommended_age: "",
    baby_weight: "",
    absorption_duration: "",
    scented_or_flavour: "",
    herbal_or_ayurvedic: "",
    theme_or_occasion_type: "",
    hair_type: "",
    mineral_source: "",
    caffeine_content: "",
    capacity: "",
    pf_brand_owner_FSSAI_license_no: "",
    tax_included: "",
    country_of_origin: "",
    uom: "",
    customer_care_contact: "",
    replaceable: "",
    brand: "",

  };
  const rawDataError = {
    name: false,
    short_description: false,
    description: false,
    categories_id: false,
    net_qty: false,
    pc_manufacturer_or_packer_name: false,
    quantity: false,
    mrp: false,
    selling_price: false,
    tax_included_price: false,
    tax: false,
    customer_care_contact: false,
    tags: false,
    image: false,
    sku: false,
    hsn: false,
    position: false,
    status: false,
    food_type_veg: false,
    returnable: false,
    cancelable: false,
    return_window: false,
    seller_pickup_return: false,
    time_to_ship: false,
    cod: false,
    product_type: false,
    preservatives: false,
    preservatives_details: false,
    flavours_and_spices: false,
    ready_to_cook: false,
    ready_to_eat: false,
    rice_grain_type: false,
    recommended_age: false,
    baby_weight: false,
    absorption_duration: false,
    scented_or_flavour: false,
    herbal_or_ayurvedic: false,
    theme_or_occasion_type: false,
    hair_type: false,
    mineral_source: false,
    caffeine_content: false,
    capacity: false,
    pf_brand_owner_FSSAI_license_no: false,
    tax_included: false,
    country_of_origin: false,
    uom: false,
    replaceable: false,
    brand: false,


  };

  const [product, setProduct] = useState(rawData);
  const [productErr, setProductErr] = useState(rawDataError);
  let params = useParams();
  const { id } = params;
  const history = useHistory()
  


  const open = () => {
    show(
      <ImageContainer>
        <img src={product.image} alt="" />
        <button onClick={() => {
          hide("key#1")
        }}>x</button>
      </ImageContainer>,
      { key: "key#1" }
    )
  }

  const handleSubcategoryChange = (id) => {
    setSubcategorydata({ ...Subcategorydata, parent_id: id });
  };

  const [Nadata, setNadata] = useState([]);
  const [subcattoggle, setSubcattoggle] = useState(false);

  useEffect(() => {
    taxListHanler();
    categoryListing();




    brandlisting()
    if (id) {
      // setpageName(false);
      getProduct();

      // if (current_sellerid > 1) {
      //   setreadonly(true)
      // }
      // setProduct(JSON.parse(user))
    } else {
      // setpageName(true);
      getadditionaldetails()
    }
  }, []);

  async function brandlisting() {
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/getBrandList",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      })

      if (data.status === 200) {
        setbrandlist(data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function getadditionaldetails() {
    const { data } = await axios({
      url: `https://shikahrapp.hulsecure.in/public/index.php/api/sellerShop`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
      },

    });

    if (data.status === 200) {
      let adddata = data.response;
      // console.log("adddata", adddata)
      setProduct({
        ...product, cod: adddata.cod, time_to_ship: adddata.time_to_ship, replaceable: adddata.replaceable, returnable: adddata.returnable, cancelable: adddata.cancelable, return_window: adddata.return_window,
        seller_pickup_return: adddata.seller_pickup_return
      })
    }
  }


  const getProduct = async () => {
    // console.log("comming to getproduct function")
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/getproductseller",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { product_id: id },
      });
      // console.log("getProduct >---------> ", data);
      if (data.status === 200) {
        // console.log(data.data);
        setProduct(data.data);
        setreadOnly(true)
        const parsedData = data.response.map((item) => ({
          id: item.id,
          parent_id: item.parent_id,
          cat_name: item.cat_name,
          status: item.status,
          subcategory: item.subcategory || [],
        }));
        // console.log("parsed data-->", parsedData);
        setNadata(parsedData);

      }
    } catch (error) { }
  };

  const MakeCategory = ({ cat_name, subcategory, id }) => {
    const subCategoryChecker = subcategory && subcategory.length >= 1;
    const [isChecked, setIsChecked] = useState(
      product.categories_id && product.categories_id.includes(id)
    );
    const handleCategoryClick = () => {
      onCategoryClick(id, isChecked);
    };
    // console.log("product--->", product);
    function onCategoryClick(id, checked) {
      if (checked) {
        setProduct({
          ...product,
          categories_id: [...product.categories_id, id],
        });
      } else {
        setProduct({
          ...product,
          categories_id: product.categories_id.filter(
            (category_id) => category_id !== id
          ),
        });
      }
    }

    const handleCheckboxChange = (event) => {
      const newCheckedState = event.target.checked;
      setIsChecked(newCheckedState);
      onCategoryClick(id, newCheckedState);
    };

    useEffect(() => {
      setIsChecked(product.categories_id && product.categories_id.includes(id));
    }, [product.categories_id, id]);
    // console.log("fsdfhsdkffkdsfhsdfaf------->",Subcategorydata )
    return (
      <div>
        <ul style={{ marginLeft: "20px" }}>
          <li key={id} onClick={handleCategoryClick}>
            <input
              type="checkbox"
              value={id}
              name="sub-category"
              checked={isChecked}
              onChange={handleCheckboxChange}
              disabled={readOnly}
            />{" "}
            -{cat_name}{" "}
          </li>

          {subCategoryChecker &&
            subcategory.map((item) => <MakeCategory {...item} />)}
        </ul>
      </div>
    );
  };

  const categoryListing = async () => {
    try {
      const { data } = await axios({
        url: `https://shikahrapp.hulsecure.in/public/index.php/api/getcategories`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("category listing", data);
      if (data.status === 200) {
        // setCategoryList(data.data);
        if (data.status === 200) {
          // Ndata = data.response;
          const parsedData = data.response.map(
            (item) => ({
              id: item.id,
              parent_id: item.parent_id,
              cat_name: item.cat_name,
              status: item.status,
              subcategory: item.subcategory || [],
            })
          );
          // console.log("parsed data-->", parsedData);
          setNadata(parsedData);
        }
      }
    } catch (error) { }
  };


  const errorHandler = (e) => {
    if (e.target.value) {
      setProductErr({ ...productErr, [e.target.name]: false });
    } else {
      setProductErr({ ...productErr, [e.target.name]: true });
    }
  };

  const taxListHanler = async () => {
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/gettaxlist",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("tax listing", data);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) { }
  };

  const priceHandler = (e) => {
    // console.log("e.target.name", e.target.name);
    if (e.target.name === "selling_price") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(e.target.value);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? e.target.value
          : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax") {
      const percentAmmount =
        (parseFloat(e.target.value) / 100) * parseFloat(product.selling_price);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? product?.tax_included_price
          : parseFloat(product.selling_price) +
          parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax_included") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(product.selling_price);
      setProduct({
        ...product,
        [e.target.name]: !product?.tax_included,
        tax_included_price: !product?.tax_included
          ? product.selling_price
          : percentAmmount + parseFloat(product.selling_price),
      });
    }
  };

  const productImageHandler = async (e) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
        secretAccessKey: "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",
      });
      const params = {
        Bucket: "ondc-marketplace",
        Key: `images/${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let result = await s3.upload(params).promise();
      const { Location } = result;
      // console.log(Location);

      setProduct({ ...product, image: Location });
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleInput = async (e) => {
    e.preventDefault();
    // console.log("line 325", product);
    const errorEmptyAlert = () => {
      toast.error("Please fill the required fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const {
      name, selling_price, sku, image, brand, product_type, net_qty, mrp
    } = product

    if (name && selling_price && sku && image && brand !== "" && product_type !== "" && net_qty && mrp) {
      try {
        const options = {
          method: "POST",
          url: `https://shikahrapp.hulsecure.in/public/index.php/api/addproductseller`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: product,
        };
        const data = await axios(options);
        if (data.status === 200) {
          setProduct(product);
          toast.success("New product created successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        console.log(error);
        toast.error("404 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      errorEmptyAlert()
    }

  };

  const additionalDetails = (
    <div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Manufacturer Name:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="pc_manufacturer_or_packer_name"
            value={product.pc_manufacturer_or_packer_name}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Manufacturer Name"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Manufacturer Address:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="pc_manufacturer_or_packer_address"
            value={product.pc_manufacturer_or_packer_address}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Manufacturer Address"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Manufacturing month & year:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="pc_month_year_of_manufacture_packing_import"
            value={product.pc_month_year_of_manufacture_packing_import}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Manufacturing month & year"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          HSN Number:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="hsn"
            value={product.hsn}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter HSN Number"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Tags:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="tags"
            value={product.tags}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Search Tags"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Short description:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="short_description"
            value={product.short_description}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Short product description"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Long description:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="description"
            value={product.description}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Long product description"
            readOnly={readOnly}
          />
        </div>
      </div>
      {/* <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Sku Code:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="sku"
            value={product.sku}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Sku Code"
          />
        </div>
      </div> */}
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Country of Origin:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="country_of_origin"
            value={product.country_of_origin}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Country of Origin"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Customer Care:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="customer_care_contact"
            value={product.customer_care_contact}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Customer Care"
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Time to ship:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="time_to_ship"
            value={product.time_to_ship}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value="">Time to Ship</option>
            {timeToShipLength.map((item) => (
              <option value={`P${item}D`}>{`${item}Days`}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product Returnable:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="returnable"
            value={product.returnable}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Product Returnable</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product return Window:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="return_window"
            value={product.return_window}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >

            <option value="">Select return window</option>
            {returnWindowLength.map((item) => (
              <option value={`P${item}D`}>{`${item}Days`}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product Cancellable:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="cancelable"
            value={product.cancelable}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Product Cancellable</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Return Pickup Available:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="seller_pickup_return"
            value={product.seller_pickup_return}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Return Pickup Available</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product Replaceable:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="replaceable"
            value={product.replaceable}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Product Replaceable</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          COD:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select name="cod"
            value={product.cod}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }>
            <option value={""}>Cash on delivery</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
    </div>
  );

  function Showmenu() {
    setshowMenu(true);
    history.push("/sidebar")

  }

  // console.log("product", product)

  async function updateHandler() {
    const { selling_price, returnable, cancelable, time_to_ship, return_window, cod, replaceable, seller_pickup_return, } = product
    try {
      const data = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/updateselprod",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          ...product, product_id: id
        }

      })

      console.log(data)
    } catch (err) {
      console.log(err)
    }
  }

  const linkName = read
    ? `Edit Additional Details >`
    : `Edit Additional Details >`;

  return (
    <>
      {id && <Navbar Showmenu={Showmenu} />}
      <div className="">
        <div className="text-center mb-3">
          <strong>Add Product details</strong>
          <div>
            <div className="col-12 text-left border border-2">

              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="col-md-3 col-sm-12 col-form-label"
                >
                  Product Name:
                  <span className="text-danger" > * </span>
                  {
                    productErr?.name ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }
                </label>

                <div className="col-md-8 col-sm-12 ">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    style={{ borderBottom: "1px solid #262626" }}
                    id=""
                    name="name"
                    value={product.name}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        [e.target.name]: e.target.value,
                      })
                    }
                    onBlur={(e) => errorHandler(e)}
                    placeholder="Enter Product Name"
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="col-md-3 col-sm-12 col-form-label"
                >
                  Category:
                  <span className="text-danger" > * </span>
                  {
                    productErr?.product_type ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }
                </label>
                <div className="col-md-8 col-sm-12 ">
                  <select
                    name="product_type"
                    value={product.product_type}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        [e.target.name]: parseInt(e.target.value),
                      })
                    }
                    onBlur={(e) => errorHandler(e)}
                    id=""
                    style={{ borderBottom: "1px solid #262626" }}
                    className="form-control-plaintext"
                    disabled={readOnly}
                  >
                    <option value="">Select Category</option>
                    <option value={1}>Masala and Seasoning</option>
                    <option value={2}>Oil &amp; Ghee</option>
                    <option value={3}>Gourmet &amp; World Foods</option>
                    <option value={4}>Foodgrains</option>
                    <option value={5}>Eggs, Meat &amp; Fish</option>
                    <option value={6}>Cleaning &amp; Household</option>
                    <option value={7}>Beverages</option>
                    <option value={8}>Beauty &amp; Hygiene</option>
                    <option value={9}>Bakery, Cakes &amp; Dairy </option>
                    <option value={10}>Kitchen Accessories</option>
                    <option value={11}>Baby Care</option>
                    <option value={12}>Snacks &amp; Branded Foods</option>
                    <option value={13}>Pet Care</option>
                    <option value={14}>Stationery</option>
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="col-md-3 col-sm-12 col-form-label"
                >
                  Sub-Category:

                  {/* <span className="text-danger" > * </span>
                    {
                      productErr?.sub-category ? (
                        <span className="text-danger tiny_size" > This field is required ! </span>
                      ) : null
                    } */}
                </label>
                <div className="col-md-8 col-sm-12 ">
                  {/* code for multi selections:-- */}
                  <div
                    className=" form-control-plaintext selectcategory"
                    style={{ borderBottom: "1px solid #262626" }}
                    onClick={() => setSubcattoggle(!subcattoggle)}
                  >
                    --Select Sub-Category--
                    <span style={{ float: "right" }}>▼</span>
                    {/* <input type="text" className="form-control" name="subcat" placeholder="Search" value={search} onChange={handleSearchChange} /> */}
                  </div>
                  {subcattoggle ? (
                    <div className="multiselectdivision">
                      {Nadata.map((item, index) => {
                        return (
                          <>
                            <MakeCategory
                              {...item}
                              onSubcategoryChange={handleSubcategoryChange}
                              key={index}
                            />
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* code for multi selections:-- */}
                </div>
              </div>
              {Number(product?.categories_id) === 25 ? (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Food type</label>
                    <span className="text-danger"> * </span>

                    <select
                      className="form-control"
                      value={product.food_type_veg}
                      name="food_type_veg"
                      id=""
                      disabled={readOnly}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          [e.target.name]: e.target.value,
                        })
                      }
                    // onBlur={(e) => errorHandler(e)}

                    >
                      <option value="">Select food type</option>
                      <option value="true">Veg</option>
                      <option value="false">Non veg</option>
                    </select>
                  </div>
                </div>
              ) : null}

              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="col-md-3 col-sm-12  col-form-label"
                >
                  Net Quantity (Grammage):
                  <span className="text-danger" > * </span>
                  {
                    productErr?.net_qty ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }
                </label>
                <div className="col-md-8 col-sm-12 ">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    style={{ borderBottom: "1px solid #262626" }}
                    id=""
                    name="net_qty"
                    value={product.net_qty}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        [e.target.name]: e.target.value,
                      })
                    }
                    placeholder="Enter Weight"
                    onBlur={(e) => errorHandler(e)}
                    readOnly={readOnly}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="col-md-3 col-sm-12  col-form-label"
                >
                  SKU:

                  <span className="text-danger" > * </span>
                  {
                    productErr?.sku ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }
                </label>
                <div className="col-md-8 col-sm-12 ">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    style={{ borderBottom: "1px solid #262626" }}
                    id=""
                    name="sku"
                    value={product.sku}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        [e.target.name]: e.target.value,
                      })
                    }
                    placeholder="Enter Sku"
                    onBlur={(e) => errorHandler(e)}
                    readOnly={readOnly}
                  />
                </div>
              </div>


              <div className="mb-3 row">

                <label className="col-md-3 col-sm-12  col-form-label">Brand
                  <span className="text-danger" > * </span>
                  {
                    productErr?.brand ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }</label>
                  <div className="col-md-8 col-sm-12 ">
                        <select
                      className="form-control-plaintext"
                      value={product.brand}
                      name="brand"
                      id=""
                      style={{ borderBottom: "1px solid #262626" }}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          [e.target.name]: e.target.value,
                        })
                      }
                      onBlur={(e) => errorHandler(e)}
                      disabled={readOnly}

                    >
                      <option value="">Select Brand</option>
                      {brandlist.map((item) => (
                        <option value={item.id}>{item.brand_name}</option>
                      ))}
                    </select>
                  </div>
                
              </div>



              <div className="mb-3 row">
                <label className="col-md-3 col-sm-12  col-form-label">
                  Selling price
                  <span className="text-danger" > * </span>
                  {
                    productErr?.selling_price ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }
                  <br />
                  <small>Tax included</small>
                  <input
                    type="checkbox"
                    name="tax_included"
                    style={{ marginLeft: "20px" }}
                    onChange={(e) => priceHandler(e)}
                    checked={product?.tax_included}
                    disabled={readOnly}

                  />
                </label>
                <div className="col-md-8 col-sm-12 ">
                  <input
                    type="number"
                    className="form-control-plaintext"
                    style={{ borderBottom: "1px solid #262626" }}
                    name="selling_price"
                    placeholder="Selling Price"
                    value={product.selling_price}
                    onChange={(e) => priceHandler(e)}
                    onBlur={(e) => errorHandler(e)}

                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-md-3 col-sm-12  col-form-label">
                  Tax included price
                </label>
                <div className="col-md-8 col-sm-12 ">
                  <input
                    type="number"
                    className="form-control-plaintext"
                    style={{ borderBottom: "1px solid #262626" }}
                    disabled
                    name="tax_included_price"
                    placeholder=""
                    value={product.tax_included_price}
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-md-3 col-sm-12  col-form-label">
                  Tax ( % )
                </label>
                <div className="col-md-8 col-sm-12 ">
                  <select
                    className="form-control-plaintext"
                    disabled={readOnly}
                    style={{ borderBottom: "1px solid #262626" }}
                    name="tax"
                    placeholder="Tax"
                    value={product.tax}
                    onChange={(e) => priceHandler(e)}
                    onBlur={(e) => errorHandler(e)}

                  >
                    <option value="">Select tax</option>
                    {taxData.map((item) => (
                      <option value={item?.tax_rate}>{item?.tax_rate}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-md-3 col-sm-12  col-form-label">
                  Max price
                  <span className="text-danger" > * </span>
                  {
                    productErr?.mrp ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }
                </label>
                <div className="col-md-8 col-sm-12 ">
                  <input
                    type="number"
                    className="form-control-plaintext"
                    style={{ borderBottom: "1px solid #262626" }}
                    name="mrp"
                    placeholder="Max price"
                    value={product.mrp ? product.mrp : product.price}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        [e.target.name]: e.target.value,
                      })
                    }
                    onBlur={(e) => errorHandler(e)}
                    readOnly={readOnly}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="col-md-3 col-sm-12  col-form-label"
                >
                  Image:
                  <span className="text-danger" > * </span>
                  {
                    productErr?.image ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  }
                </label>
                <div className="col-md-8 col-sm-12 ">
                  {product.image && (
                    <a
                      // href={product.image}
                      onClick={open}
                      target="_blank"
                      style={{
                        fontSize: "10px",
                        marginLeft: "3px",
                        textDecoration: "underline",
                      }}
                    >
                      View Document
                    </a>
                  )}

                  {/* <button onClick={open}>Show Image</button> */}


                  <input
                    type="file"
                    className="form-control-plaintext"
                    style={{ borderBottom: "1px solid #262626" }}
                    id=""
                    accept="image/*"
                    name="image"
                    // value={product.image}
                    onChange={(e) => productImageHandler(e)}
                    onBlur={(e) => errorHandler(e)}
                    disabled={readOnly}
                  />
                  {/* <button className="btn btn-success">Upload</button> */}
                </div>
              </div>

              <div className="m-3">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setRead(!read);
                  }}
                >
                  {linkName}
                </a>
                {read && additionalDetails}
              </div>

              <div className="m-2">
                {!id ? <button className="btn btn-primary" onClick={handleInput}>
                  Proceed
                </button> : <button className="btn btn-success" onClick={updateHandler}>
                  Update
                </button>}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default createProduct;