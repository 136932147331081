import React from 'react'

export default function Seeorderdetails() {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const HULID = urlParams.get('HULID');
  const ACCESSTOKEN = urlParams.get('ACCESSTOKEN');
  const ODERID = urlParams.get('ODERID');
  console.log(HULID)
  console.log(ACCESSTOKEN)
  console.log(ODERID)
  return (
    <div>
      See Order details <br />
      HULID--  {HULID} <br />
      Access Token - {ACCESSTOKEN} <br />
      Order ID--  {ODERID}
    </div>
  )
}
