/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import "./switch.css";
// import "./styles.css";
const { apiEndpoint } = config;

const orderDetail = () => {
  const [showPop, setShowPop] = useState(false);

  const toggle = (e: any) => {
    e.preventDefault();
    setShowPop(true);
    // useEffect(() => {
    //   // document.body.style.overflowX = "scroll";
    //   document.body.style.height='fit-content';
    //   return () => {
    //     // document.body.style.overflowX = "auto";
    //     document.body.style.height='0';
    //   };
    // }, []);
  };

  let params = useParams();
  const { id: order_id }: any = params;
  const [alldetails, setalldetails] = useState([]);
  console.log("all details-------------", alldetails);
  const decoded = tokenDecode();
  const [isLoading, setIsLoading] = useState(false);
  const [updateOrder_, setUpdateOrder_] = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });
  const [orderState, setOrderState] = useState({
    order_id,
    order_state: "",
    fulfillment_state: "",
    tracking_url: "",
  });

  const [isChecked, setisChecked]:any = useState('');
  const [cancelvalue, setCancelvalue]:any = useState('');
  let rawdata;
  const [orderCancle, setOrderCancle]: any = useState({
    order_id,
    product_id: [],
  });

  const [addButton, setaddButton] = useState(true);
  // const shipping_address = JSON.parse(_orderDetail?.shipping_address);

  const [orderStatus, setorderStatus] = useState([
    "Created",
    "Accepted",
    "In-progress",
    "Completed",
    "Cancelled",
    // "Returned",
  ]);

  const getorderDetails = async (order_id: any) => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorder`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id,
        },
      });
      console.log("order detail ->------   ", data);
      console.log("order state ->------   ", data.data[0].order_state);
      if (data.status === 200) {
        setIsLoading(false);
        setorderDetail(data.products);
        setOrderCancle(data.products);
        setalldetails(data);
        let totalP = 0;
        data.products.forEach((item: any) => {
          totalP += parseFloat(item?.price);
        });
        if (data.data[0].order_state !== "") {
          const index = orderStatus.indexOf(data.data[0].order_state);
          if (index !== -1) {
            setorderStatus(orderStatus.slice(index));
          }
        }

        setOrderState({
          ...orderState,
          order_state: data.data[0]?.order_state,
          tracking_url: data.products[0]?.tracking_url,
          fulfillment_state: data.products[0]?.fulfillment_state,
        });

        setUpdateOrder_({
          ...updateOrder_,
          order_id: data.data[0]?.order_id,
          tracking_url: data.data[0]?.tracking_url,
          order_state: data.data[0]?.order_state,
        });
        _setorderDetail({
          ..._orderDetail,
          ...data.data[0],
          total_price: data.data[0].order_total,
          billing_address: JSON.parse(data.data[0]?.billing),
          shipping_address: JSON.parse(data.data[0]?.fulfillments),
        });

       

         rawdata = data.products.map((item: any, i: any) => ({
          item_id: item.product_id,
          order_status: orderState.order_state,
          qty: cancelvalue,
        }));


        console.log("line 145", rawdata);
        orderCancle.product_id = Object.assign(rawdata);
        setOrderCancle(orderCancle);
        console.log("line 154 ordercancle---->>>", orderCancle);

        
        
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const updateOrder = async (product_id: any) => {
    try {
      console.log(orderDetail);
      const getItem = orderDetail.find(
        (item: any) => item?.product_id === product_id
      );
      console.log("getItem", getItem);

      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: getItem,
      });
      console.log(data, "datadata");

      if (data.message === "flag1") {
        toast.success("Item status updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("yess", error);

      // console.log("update error", error);
      // toast.error("404 error !", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      // });

      // window.location.reload();
    }
  };
  const updateOrderChangeHandler = (e: any, index: any) => {
    // setUpdateOrder_({ ...updateOrder_, [e.target.name]: e.target.value })
    console.log(e.target.value, "index---", e.target.name, index);
    const newUpdate: any = orderDetail;
    newUpdate[index][e.target.name] = e.target.value;
    console.log(newUpdate, "newUpdate");

    setorderDetail([...newUpdate]);
  };
  const updateThisOrder = async (e: any) => {
    try {
      console.log("orderState---->", orderState);
      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: orderState,
      });
      console.log(data, "datadata");

      if (data.status === 200) {
        toast.success("Order cancel updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong 500 !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  const cancleUpdate = (cancelvalue:any) => {
    // e.preventDefault();
    console.log("orderCancle", orderCancle);
    console.log("quantity", cancelvalue);
    console.log("product id", isChecked);
    // istatus = selectedStatus;
    setCancelvalue(cancelvalue);
    console.log('item 288', orderCancle);
    //line 288 issue
   
    const newrawdata = orderDetail.map((item: any, i: any) => ({
      item_id: item.product_id,
      order_status: orderState.order_state,
      qty: cancelvalue,
    }));
    
    console.log("line 294", newrawdata);
    orderCancle.product_id = Object.assign(newrawdata);
    console.log('line 297', orderCancle);
  
    
   
  };
  const cancelPartial = async (e: any) => {
    console.log('line 307', orderCancle);
    // const id = toast.loading("Please wait...");
    try {
      console.log("orderState---->", orderState);
      const response = await axios({
        url: `${apiEndpoint}/sellerordercancel`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: orderCancle,
      })

      .then(function (response) {
        console.log("response 173", response);
        toast.success("Order cancel updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowPop(false);
      })
      .catch(function (error) {
        console.log("error 176", error);
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(function () {
        console.log("response   // always executed 179");
      });
    } catch (error) {
      console.error(" error 784", error);
      toast.error("Something went wrong !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  
  useEffect(() => {
    getorderDetails(order_id);
    // console.log(_orderDetail.order_state, "<-----------_orderDetail.order_state ")
  }, []);


  // if (_orderDetail.order_state !== "") {
  //     const index = orderStatus.indexOf(_orderDetail.order_state);
  //     if (index !== -1) {
  //       setorderStatus(orderStatus.slice(index));
  //     }
  //   }
  console.log("orderDetails---->", _orderDetail);
  console.log("orderStatus---->", orderStatus);
  const [cancelpopup, setcancelpopup] = useState(false);
  const [selectedStatus, setselectedStatus] = useState();
  const cancelbtn = () => {
    setcancelpopup(true);
    console.log("cancelPopup");
  };

  const [cancelreson, setcancelreson] = useState("");
  const changeReturnStatus = (selectedStatus: any) => {
    console.log("selectedStatus", selectedStatus);
    // istatus = selectedStatus;
    setcancelreson(selectedStatus);
  };

  const ordercancel = async () => {
    console.log("cancel", cancelreson);
    console.log("orderCancel hit");

    const statusData = {
      order_id: "2023-04-26-565129",
      reason_code: cancelreson,
    };
    const id = toast.loading("Please wait...");
    try {
      const response = await axios({
        method: "post",
        url: "https://shikhar-staging.herokuapp.com/v1/unsolicated_cancel",
        headers: { Authorization: `Bearer ${localStorage.getItem("us_st_d")}` },
        data: statusData,
      })
        .then(function (response) {
          console.log("response 173", response);
          toast.update(id, {
            render: "Status Updating",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          setcancelpopup(false);
        })
        .catch(function (error) {
          console.log("error 176", error);
          toast.update(id, {
            render: "something worng happned",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        })
        .finally(function () {
          console.log("response   // always executed 179");
        });
    } catch (error) {
      console.error(" error 784", error);
    }
  };
  return (
    <>
      <ThemeMaker>
        <div className="card container">
          <div className=" shadow m-2 p-2 fw-bold">
            Order ID : {order_id.slice(11, 17)}
          </div>

          {orderDetail &&
            orderDetail.map((item: any) => {
              return (
                <>
                  <div className=" shadow m-2 p-2">
                    <strong className="text-info">Items Details</strong>
                    <div className="col-12 d-flex">
                      <div className="col-4">
                        <img src={item.image} alt="image" className="w-75" />
                      </div>
                      <div className="col-8">
                        Product: {item?.product_name} <br />
                        Category : {item?.category_name} <br />
                        Size : {item?.weight}
                        <br />
                        Qty : {item?.quantity}
                        <br />
                        Price : {item?.price}
                      </div>
                    </div>
                    <hr />
                  </div>
                </>
              );
            })}
         
          <div className="row d-flex">
            <div className="col-md-6 col-sm-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label"
              >
                Order Status
              </label>
              <br />
              <select
                className="form-select col-8"
                aria-label="size 3 select example"
                value={orderState?.order_state}
                name="order_state"
                    onChange={(e) =>
                      setOrderState({
                        ...orderState,
                        [e.target.name]: e.target.value,
                      })}
              >
                {orderStatus.map((item) => {
                      return (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      );
                    })}
                {/* <option value="Created">Created</option>
                  <option value="Accepted">Accepted</option>
                  <option value="In-progress">In-progress</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option> */}
                {/* <option value="Returned">Returned</option> */}
              </select>
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label"
              >
                Fullfillment
              </label>
              <br />
              <select
                className="form-select col-8"
                aria-label="size 3 select example"
                value={orderState?.fulfillment_state}
                name="fulfillment_state"
                onChange={(e) =>
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                <option value="Pending">Pending</option>
                <option value="Packed">Packed</option>
                <option value="Order-picked-up">Order-picked-up</option>
                <option value="Out-for-delivery">Out-for-delivery</option>
                <option value="Order-delivered">Order-delivered</option>
                <option value="RTO-Initiated">RTO-Initiated</option>
                <option value="RTO-Delivered">RTO-Delivered</option>
                <option value="RTO-Disposed">RTO-Disposed</option>
                <option value="Cancelled">Cancelled</option>
                {/* <option value="Returned">Returned</option> */}
              </select>
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label"
              >
                Tracking URL
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={orderState?.tracking_url}
                name="tracking_url"
                onChange={(e) =>
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>

            <div className="">
              {showPop && showPop ? (
                <>
                  <div
                    onClick={() => setShowPop(false)}
                    className={`cancelpopupoverlay`}
                  ></div>
                  <div className={`popup `}>
                    {orderDetail &&
                      orderDetail.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="row p-4 mb-2" key={index}>
                              <div className="col-12 mb-2">
                                Product Name: {item.product_name}
                              </div>
                              <div className="col-md-6 col-sm-12">
                                Current Quantity: {item.quantity}
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <label className="col-form-label">
                                  Cancle Quantity:
                                </label>
                                <input
                                  type="number"
                                  style={{
                                    border: "none",
                                    borderBottom: "1px solid #ccc",
                                  }}
                                  name="qty"
                                  min={1}
                                  max={item.quantity}
                                  value={cancelvalue}
                                  onChange={(e) =>
                                    cancleUpdate(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-12 d-flex align-self-baseline">
                                Update &nbsp;
                                <input
                                  type="checkbox"
                                  name="item_id"
                                  value={isChecked}
                                  onChange={(e) => {
                                    setisChecked({...isChecked ,[e.target.name]:isChecked});
                                    console.log('line 593',isChecked);
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}

                    <div className="d-flex justify-content-end position-relative p-2">
                      <button
                        className="btn btn-primary align-end"
                        type="submit"
                        onClick={(e) => cancelPartial(e)}
                      >
                        Cancelation
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="row m-2 w-100 d-flex justify-content-between">
              <div className=" col-md-6 col-sm-12">
                <button
                  className="btn btn-primary"
                  onClick={(e) => updateThisOrder(e)}
                >
                  Update
                </button>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="col-12 w-100 m-0 p-0 d-flex btnAlign">
                  {/* <button className="btn btn-primary" onClick={updateThisOrder}>
                  Update
                </button> */}

                  <div className="m-2 ">
                    <button
                      onClick={cancelbtn}
                      className="btn btn-outline-danger"
                    >
                      Order Cancel
                    </button>
                  </div>
                  {cancelpopup ? (
                    <>
                      <div
                        onClick={() => setcancelpopup(false)}
                        className={`cancelpopupoverlay`}
                      ></div>
                      <div className={`cancelorderpopup p-3 `}>
                        <div className="row">
                          <div className="col-12 ">
                            <select
                              className="btn btn-outline-dark w-100 text-left"
                              value={cancelreson}
                              onChange={(e) =>
                                changeReturnStatus(e.target.value)
                              }
                            >
                              <option value="002">
                                One or more items in the Order not available
                              </option>
                              <option value="005">
                                Merchant rejected the order
                              </option>
                              <option value="008">
                                Order not ready for pickup
                              </option>
                              <option value="011">
                                Buyer not found or cannot be contacted
                              </option>
                              <option value="013">
                                Buyer refused to accept delivery
                              </option>
                              <option value="014">Address not found</option>
                              <option value="015">
                                Buyer not available at location
                              </option>
                              <option value="016">
                                Accident / rain / strike / vehicle issues
                              </option>
                              <option value="017">
                                Order delivery delayed or not possible
                              </option>
                              <option value="018">
                                Delivery pin code not serviceable
                              </option>
                              <option value="019">
                                Pickup pin code not serviceable
                              </option>
                            </select>
                            <p className="text-center mt-3 d-none">{`You selected : ${cancelreson}`}</p>
                          </div>
                          <div className="col-12 m-2 p-2 d-flex justify-content-between">
                            <button
                              className="btn btn-outline-dark col-5 col-md-5"
                              onClick={() => setcancelpopup(false)}
                            >
                              Close
                            </button>
                            <button
                              className="btn btn-outline-danger col-5 col-md-5"
                              onClick={() => ordercancel()}
                            >
                              Cancel Order
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="m-2 ">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        toggle(e);
                      }}
                    >
                      Partial Cancelation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" shadow m-2 p-2">
            <strong className="text-info">Order Details</strong>
            <p className="d-flex justify-content-between">
              Order Number{" "}
              <span style={{ textAlign: "end" }}>{_orderDetail?.order_id}</span>
            </p>
            <p className="d-flex justify-content-between">
              Bill Number{" "}
              <span style={{ textAlign: "end" }}>
                GST{_orderDetail?.order_id.slice(11, 17)}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Bill Date{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.created_at}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Customer Name{" "}
              <span style={{ textAlign: "end" }}>{_orderDetail?.name}</span>
            </p>
            {/* <hr /> */}
            <p className="d-flex justify-content-between">
              Total Amount{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.order_total}
              </span>
            </p>
            {/* <p className="d-flex justify-content-between">
                            Shipping Amount{" "}
                            <span style={{ textAlign: "end" }}>200.00</span>
                          </p>
                          <p className="d-flex justify-content-between">
                            Total Product Qty{" "}
                            <span style={{ textAlign: "end" }}>5</span>
                          </p>
                          <p className="d-flex justify-content-between">
                            Total Qty <span style={{ textAlign: "end" }}>5</span>
                          </p> */}
            <hr />
            <p className="d-flex justify-content-between text-info">
              <strong>Grand Total</strong>{" "}
              <span
                style={{ textAlign: "end" }}
              >{`${_orderDetail?.order_total} `}</span>
            </p>
          </div>
          <div className=" shadow m-2 p-2">
            <strong className="text-info">Payment Method</strong>
            <p className="d-flex justify-content-between">
              Order Number{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.order_id.slice(11, 17)}
              </span>
            </p>
            {/* <p className="d-flex justify-content-between">
                            Mode{" "}
                            <span style={{ textAlign: "end" }}>Cash On Delivery</span>
                          </p> */}
            <p className="d-flex justify-content-between">
              Bill Date{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.created_at}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Total Amount{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.order_total}
              </span>
            </p>
          </div>
          {/* <div className=" shadow m-2 p-2">
                          <strong className="text-info">GST Details</strong>
                          <p className="d-flex justify-content-between">
                            GST Percentage{" "}
                            <span style={{ textAlign: "end" }}>2.5</span>
                          </p>
                          <p className="d-flex justify-content-between">
                            GST Amount <span style={{ textAlign: "end" }}>50</span>
                          </p>
                        </div> */}
        </div>
      </ThemeMaker>
    </>
  );
};

export default orderDetail;