import React from 'react'
import { Link } from 'react-router-dom'
import styles from "./TransactionTemplate.module.css"
import eye from "../../icons/eye.png"
export default function TransactionTemplate({ transactionList, getTransaction, calculation }) {
    return (
        <>
            {
                transactionList.map((item, index) => {
                    return <div className={`d-flex justify-content-between w-100 ${styles.templateTemplatemain}`} key={item.id}>
                        <div className={`${styles.templateTemplateImage}`}>
                            <span>0{index + 1}</span>
                        </div>
                        <div className={`${styles.templateTemplateDesc}`}>

                            <div>
                                <p className='w-100'>Order Id</p>  <p className='m-0 mb-1'>{item.order_id} </p>
                            </div>
                            <div>
                                <p className='m-0'>  <span>Amount:</span> <span>{item.seller_amount}</span></p>
                            </div>
                            <div>
                                <p className='m-0'>  <span>Created At:</span><span> {item.created_at} </span></p>
                            </div>


                        </div>
                        {/* <div className={`${styles.templateTemplateButtons}`}>

                            <div onClick={() => {
                                // sellerDelete(item.id)
                            }}>
                                <img src="" alt="" />
                            </div>

                            <Link to={`../../updateseller/${item.id}`}

                                className="btn-sm m-1 btn btn-success btn-circle"
                            >
                                <div>
                                    <img src="" alt="" />
                                </div>

                            </Link>
                        </div> */}

                        <div className={`${styles.templateTemplateedit}`}>
                            <img src={eye} alt="" onClick={() => {
                                getTransaction(item.order_id)

                            }} data-target="#exampleModal1" data-toggle="modal" />
                        </div>
                    </div>
                })
            }

            <div className="modal fade bd-example-modal-lg" id="exampleModal1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Order id: {calculation?.order_id} </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>  Buyer Commission : {calculation?.buyer_commission} </p>
                                </div>
                                <div className="col-md-12">
                                    <p>  ONDC Commission : {calculation?.ondc_commission}</p>
                                </div>
                                <div className="col-md-12">
                                    <p>  Seller App Commission : {calculation?.sellerapp_commission}  </p>
                                </div>
                                <div className="col-md-12">
                                    <p>  Seller Amount :{(calculation?.seller_amount)}  </p>
                                </div>
                                <div className="col-md-12">
                                    <p>  Total Amount :{calculation?.total_amount}  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
