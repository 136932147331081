/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import orderImg from "../../icons/order.png"
import { useEffect, useState } from "react";
import moment from "moment";
import searchicon_black from "../../icons/searchicon_black.png";
import style from "../../components/Search&Options/Search&Options.module.css";

import "./switch.css";

const completeOrder = () => {
  const decoded: any = tokenDecode();
  const [orderList, setOrderList] = useState([]);
  const [sellerList, setSellerList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { apiEndpoint } = config;
  const [toggle, setToggle] = useState(false);
  const rawData = {
    provider_id: "",
  };
  const [searchData, setSearchData]: any = useState(rawData);
  const [sorderList, setSorderList] = useState([]);

  const [valuename, setValuename] = useState("");
  const [valueid, setValueid] = useState("");
  const [valueemail, setValueemail] = useState("");
  const [valuecontact, setValuecontact] = useState("");
  const [valuestatus, setValuestatus] = useState("");
  const [valueamount, setValueamount] = useState("");
  const [valuedate, setValuedate] = useState("");
  const { data } = decoded;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;

  const fetchSellerList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/ordercompletelist`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decoded?.data?.user?.seller_id,
        },
      });
      console.log("order data -> ", data.data);
      setOrderList(data.data);
      // const order = data.data;
      console.log("line 34", orderList);
      if (data.status === 200) {
        setOrderList(data.data);
        setIsLoading(false);
        // setOrderList(data.data);

        /*setTimeout(() => {
                $('#dataTable').DataTable();
            }, 500);
            var someTableDT = $("#dataTable").on("draw.dt", function () {
                $("#dataTable").find(".dataTables_empty").parents('tbody').empty();
            });
*/
      }
    } catch (error) {
      console.log("error", error);

      setIsLoading(false);
    }
  };

  const getSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        console.log(data.data);
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const inputHandler = async (e: any) => {
    setValuename("");
    setValueid("");
    setValueemail("");
    setValuecontact("");
    setValuestatus("");
    setValueamount("");
    setValuedate("");
    var optionvalue = e.target.value;
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbyseller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data);
          setOrderList(data.data);
          setSorderList(data.data);
          setToggle(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOrderList(sorderList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { provider_id } = searchData;
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            name: optionvalue,
          },
        });

        console.log({
          data: {
            provider_id: { searchData },
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputoHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            order_id: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log("line 332", data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputooHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            order_id: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    console.log(optionvalue);
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputcHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            contact_number: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputccHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            contact_number: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputdHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            created_at: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputddHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            created_at: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  useEffect(() => {
    fetchSellerList();
    getSellerList();
  }, []);

  return (
    <>
    <div className=" ">
        <div className="col-12 m-2 d-flex">
          <div className="input-group mb-3">
            <span className="input-group-text">Filter By Date</span>
            <input
              type="date"
              className="form-control"
              placeholder="Search by date"
              name="date"
              onChange={(e) => {
                seller?.read ? inputdHandler(e) : inputddHandler(e);
              }}
              value={valuedate ? valuedate : ""}
            />
          </div>
        </div>
        <div className={`search mt-1 `}>
          <div className={`row w-100 d-flex m-0`} style={{ flexWrap: "wrap" }}>
            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="order_id"
                placeholder="Search by order id"
                onChange={(e) => {
                  seller?.read ? inputoHandler(e) : inputooHandler(e);
                }}
                value={valueid ? valueid : ""}
              />
            </div>

            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="name"
                placeholder="Search by name"
                onChange={(e) => {
                  seller?.read ? inputeHandler(e) : inputeeHandler(e);
                }}
                value={valuename ? valuename : ""}
              />
            </div>

            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="email"
                placeholder="Search by email"
                onChange={(e) => {
                  seller?.read ? inputemHandler(e) : inputeemHandler(e);
                }}
                value={valueemail ? valueemail : ""}
              />
            </div>

            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="contact_number"
                placeholder="Search by Contact"
                onChange={(e) => {
                  seller?.read ? inputcHandler(e) : inputccHandler(e);
                }}
                value={valuecontact ? valuecontact : ""}
              />
            </div>


          </div>
        </div>
        
        <div className="">
        {orderList &&
            orderList.map((item: any) => {
              return (
                <>
                  <div className="row m-2 p-2 shadow">
                    <div className="col-2">
                      <img src={orderImg} alt="" className="" />
                    </div>
                    <div className="col-8">
                      <p>
                        <strong>
                          Order ID: {item.order_id}
                          <br />
                          {item.name}
                        </strong>
                        <br />
                        <small>
                        {item?.address?.end?.location?.address?.building}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className=" m-2 shadow">
                    <div className="row m-2 p-2 d-flex justify-content-evenly">
                      <div className="col-6">
                        Item : {item.item_count}
                        <br />
                        Status : {item.status}
                      </div>
                      <div
                        className="col-6"
                        style={{
                          textAlign: "end",
                          fontSize: "3vw",
                          fontWeight: "bold",
                        }}
                      >
                        {/* <a className="text-dark" href="./orderCompleteDetail">
                          &#x3E;
                        </a> */}
                      </div>
                    </div>
                    <div
                      style={{ borderBottom: "2px dotted grey", width: "100%" }}
                    ></div>
                    <div className="row m-2 d-flex justify-content-evenly">
                      <div className="col-6 pb-3">{moment(`${item.order_id.slice(0, 10)}`).format("MMMM Do YYYY")}</div>
                      <div className="col-6 pb-3" style={{ textAlign: "end" }}>
                        Order Amount: {item.order_total}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* <a className="text-primary" style={{ cursor: "pointer" }}>
                      Accept Partial Order &#x3E;
                    </a> */}
                  </div>
                </>
              );
            })}
        </div>
      </div>
      {/* <div className=" mx-auto">
        <div className="col-12 m-2 d-flex">
          <div className="input-group mb-3">
            <span className="input-group-text">Filter By Date</span>
            <input type="date" className="form-control" placeholder="" />
          </div>
        </div>
        <div className="row d-flex">
          <input
            type="search"
            name="orderId"
            placeholder="Search by Order id"
            className="col-5 rounded-pill"
            value=""
          />
          <input
            type="search"
            name="name"
            placeholder="Search by Name"
            className="col-5 rounded-pill"
            value=""
          />
          <input
            type="search"
            name="email"
            placeholder="Search by Email"
            className="col-5 rounded-pill"
            value=""
          />
          <input
            type="search"
            name="contact"
            placeholder="Search by Contact"
            className="col-5 rounded-pill"
            value=""
          />
        </div>
        <div className="">
          <div className="row m-2 shadow">
            <div className="col-4">
              <i className="fa-solid fa-square-arrow-down"></i>
            </div>
            <div className="col-8">
              <p>
                <strong>
                  Order ID: 512658
                  <br />
                  Ramesh
                </strong>
                <br />
                <small>CV Raman Nagar</small>
              </p>
            </div>
          </div>
          <div className=" m-2 shadow">
            <div className="row m-2 d-flex justify-content-evenly">
              <div className="col-6">
                Item : 4<br />
                Status : Accepted
              </div>
              <div className="col-6" style={{ textAlign: "end", fontSize:'3vw',  fontWeight:'bold' }}>
                <a className="text-dark" href="./orderCompleteDetail">&#x3E;</a>
              </div>
            </div>
            <div
              style={{ borderBottom: "2px dotted grey", width: "100%" }}
            ></div>
            <div className="row m-2 d-flex justify-content-evenly">
              <div className="col-6">Sun, Apr,09,2023</div>
              <div className="col-6" style={{ textAlign: "end" }}>
                Order Amount: 2000
              </div>
            </div>
          </div>
          <div className="col-12">
          <a
                className="text-primary"
                style={{ cursor: "pointer" }}
               
              >
                Accept Partial Order &#x3E;
              </a>
          </div> */}
          {/* <div className="m-2 shadow">
            <div className="col-12 d-flex btn-toolbar" role="toolbar">
              <div className="btn-group p-2 w-50" role="group">
                <button
                  type="button"
                  className="btn btn-success"
                  style={{ fontSize: "2.5vw" }}
                >
                  Accept Order
                </button>
              </div>
              <div className="btn-group p-2 w-50" role="group">
                <button
                  type="button"
                  className="btn btn-danger"
                  style={{ fontSize: "2.5vw" }}
                >
                  Reject Order
                </button>
              </div>
              <a
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setRead(!read);
                }}
              >
                Accept Partial Order &#x3E;
              </a>
            </div>
          </div> */}
        {/* </div>
      </div> */}
    </>
  );
};

export default completeOrder;