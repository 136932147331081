import React from 'react'
import { useEffect, useRef } from 'react';
import axios from "axios"
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import { config } from "../../utils/config";
const { apiEndpoint } = config;

export default function Sellerauthcheck() {
  const headers = JSON.parse(JSON.stringify(window.location));
  // console.log("headers-->", headers);

  const history = useHistory()
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    async function getId() {
      // console.log("getid function---------")
      const queryString = window.location.search;
      // const queryString = https://stageadmin.xpressbaazaar.com/sellerauthcheck?HULID=" + sHulId + "&ACCESSTOKEN=" + sToken;
      const urlParams = new URLSearchParams(queryString);
      const HULID = urlParams.get('HULID');
      const Passedtoken = urlParams.get('ACCESSTOKEN');
      // console.log("HULID----->", HULID)
      // console.log("Passedtoken----->", Passedtoken)
      var decoded = jwt_decode(Passedtoken);
      // console.log("decoded", decoded)
     

      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/checksellerauth",
        method: "POST",
        data: {
          hulid: HULID,
          accesstoken: Passedtoken
        }
      })
      console.log("data--->", data)

      if (data) {
          // console.log("data--->",data.data.token );
          localStorage.setItem("us_st_d", data.data.token)
          var decode = jwt_decode(data.data.token);
        // console.log("--->", decode)
          let decodedUserdata = decode.data.seller_data
        // let sellerParsedData =  JSON.parse(data.data);
        // let sellerDetails =  sellerDetails.details;
        // console.log("sllerdetails", sellerDetails.details)
        // console.log("decoded", decodedUserdata)
          if (decodedUserdata.gst_no == null && decodedUserdata.pan_no == null ) {
              // console.log("inside if block");
              history.push(`/selleregister?hulid=${HULID}`)
          } else if (decodedUserdata.approve_status == 1) {

              history.push(`/dashboard`)
          }
          else if (decodedUserdata.approve_status == 0) {
              // console.log("from else----", decodedUserdata.approve_status);
              givedta()
              async function givedta() {
                  const { data } = await axios({
                      url: `${apiEndpoint}/getbankdetails`,
                      method: "POST",
                      headers: {
                          Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                      }
                  });
                  // console.log("data", data)
                  if (data.status === 200) {

                      if (data.data[0].opening_time == null || data.data[0].bank_account == null || data.data[0].pan_name == null) {
                          if (data.data[0].opening_time == null) {
                              history.push(`/sellerdetail?tab=shopdetails`)
                          } else if (data.data[0].pan_name == null) {
                              history.push(`/sellerdetail?tab=personaldetails`)
                          } else if (data.data[0].bank_account == null) {
                              history.push(`/sellerdetail?tab=bankdetails`)
                          }

                      } else {
                          history.push(`/pending`)
                      }

                  }

              }

          }
      }

    }
    if (!renderAfterCalled.current) {
      // your API call func
      getId()
    }

    renderAfterCalled.current = true;
  }, []);
  return (
    <div className='d-flex justify-content-center w-100 mt-2 text-dark'>
      Loading...
    </div>

  )
}
