import React from 'react'
import { useState, useEffect } from 'react'
import eye from "../../icons/eye.png"
import styles from "../TaxlistTemplate/TaxlistTemplate.module.css"
import deleteicon from "../../icons/delete.png"
import styled from 'styled-components'



const Title = styled.h2`    color: black;
font-size: 20px;
text-align: center;
font-weight: 700;`
export default function NewCategorylist({ item, index, editRedirect, deleteTax }) {
    // console.log("item --->", item)
    const [listdata, setlistdata] = useState(item)
    const [parentCatName, setparentCatName] = useState("")

    useEffect(() => {
        setlistdata(item);
    }, [item]);

    function showSubcategories(data) {
        // console.log("item on click", data);
        setlistdata(data.subcategory);
        setparentCatName(data.cat_name)
    }

    let Parentid = listdata.every(item => item.parent_id == 0)

    return (
        <>

            {listdata.length > 0 ? (parentCatName != "") && !Parentid && <Title>Sub Categories of {parentCatName}</Title> : (parentCatName !== "" && listdata.length < 1) ? <Title>No Items to Show</Title> : <Title>Loading...</Title>}
            {
                listdata.length > 0 && listdata.map(item => {
                    return <div className={`d-flex justify-content-between w-100 ${styles.taxlistTemplatemain}`} key={item.id}>
                        {/* <div className={`${styles.taxlistTemplateImage}`}>
                            <span>0{index + 1}</span>
                        </div> */}
                        <div className={`${styles.taxlistTemplateDesc}`}>
                            <div>
                                <p className='m-0'>  <span>Category:</span> <span style={{ color: "black", fontWeight: "700" }}>{item.cat_name
                                }</span></p>
                            </div>
                            <div>
                                <p className='mb-0'> <span>Status:</span>  {item.status ? "Active" : "Deactive"}</p>
                            </div>

                            <div className='mt-2'>

                                {(item.subcategory.length > 0) && <button className={styles.blue} onClick={() => {
                                    showSubcategories(item)
                                }}>Check SubCategories</button>}
                            </div>

                        </div>

                        <div className={`${styles.taxlistTemplateedit}`}>

                          {!Parentid &&  <div onClick={() => deleteTax(item.id, item.parent_id)}>
                                <img src={deleteicon} alt="" />
                            </div>}
                            <img src={eye} alt="" onClick={() => {
                                editRedirect(item.id, parentCatName)

                            }} data-target="#exampleModal1" data-toggle="modal" />
                        </div>
                    </div>
                })}
        </>
    )
}
