import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import Pagination from "../../components/Paginated/Pagination";
// import "./Master.css"
import InputTemplate from "../../components/Search&Options/InputTemplate";
import ProductTemplate from "../../components/ProductViewTemplate/ProductTemplate";
const { apiEndpoint } = config;
var $ = require("jquery");
var count = 1;
const ProductList = () => {

  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id
  // console.log("sdlsadsa", decoded)
  const [loading, setLoading] = useState(false);


  const [productList, setProductList] = useState([]);
  const [sproductList, setSproductList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [sellers, setSellers] = useState({
    seller: "",
  });
  const rawData = {
    seller_id: "",
  };
  const rawpData = {
    seller_id: "",
    min_price: "",
  };
  const rawsData = {
    seller_id: "",
    sku: "",
  };
  const [categories, setCategories] = useState();
  const [searchData, setSearchData] = useState(rawData);
  const [searchpData, setSearchpData] = useState(rawpData);
  const [searchsData, setSearchsData] = useState(rawsData);

  const [valuename, setValuename] = useState("");
  const [valueprice, setValueprice] = useState("");
  const [valuesku, setValuesku] = useState("");
  const [toggle, setToggle] = useState(false);
  const [brandlist, setbrandlist] = useState([])
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: { category, order, product, role, seller, tax, user, mastercatalog },
  } = data;
  const categoryListing = async () => {
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/listNonApproveProductSeller",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });

      if (data.status === 200) {
        setProductList(data.data);
        // console.log(data.data)
      }

    } catch (error) { }
  };
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("")
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("")
  const [openInput, setopenInput] = useState(null)




  const deleteProduct = async (id) => {

    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/deleteproductcatalog",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      if (data.status === 200) {
        setProductList(productList.filter((item) => item.id !== id));
        toast.success("Product deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };



  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row, count) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row) => {
        return (
          <img
            src={
              row.image
                ? row.image
                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
            }
            alt="ok"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Price",
      Footer: "Price",
      accessor: "mrp",
      sticky: "left",
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row) => {
        return <>{row.status ? "Active" : "Draft"}</>;
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row) => {
        return (
          <>
            {product.update ? (
              <Link
                to={`mastercatalog/${row.id}`}
                className="btn-sm m-1 btn btn-success btn-circle"
              >
                <i className="fas fa-pen"></i>
              </Link>
            ) : null}
            {product.delete ? (
              <button
                className="btn-sm m-1 btn btn-danger btn-circle"
                onClick={() => deleteProduct(row.id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}




          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    categoryListing();
   
  }, []);

  



  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
             
              <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Pending Requests
              </h6>
                {/* {seller?.read ? (
                                    <>
                                        <b>Filter By Seller: </b>
                                        <select
                                            name="seller_id"
                                            id=""
                                            onChange={(e) => inputHandler(e)}
                                        >
                                            <option value="">Select Seller</option>
                                            {sellerList.map((item) => (
                                                <option value={item?.seller_id}>{item?.name}</option>
                                            ))}
                                        </select>
                                    </>
                                ) : (
                                    ""
                                )} */}


                {/*<b>Filter By Category: </b><select name="category" id="" onChange={(e:any)=>inputHandlerforcategory(e)} defaultValue="">
                                <option value="">Select Category</option>
                                {
                                    categoryList.map((item:any)=>(
                                        <option value={item?.id}>{item?.category_name}</option>
                                    ))
                                }
                                       
                            </select>*/}
                <div className="search mt-1">
                  <div className="row">
                    {/* <div className="inputs_desktop">
                                            <input
                                                type="search"
                                                name="name"
                                                placeholder="Search by name"
                                                className="mr-2"
                                                onChange={(e) => {
                                                    seller?.read ? inputeHandler(e) : inputeeHandler(e);
                                                }}
                                                value={valuename ? valuename : ""}
                                            />
                                            <input
                                                type="search"
                                                name="min_price"
                                                placeholder="Search by price"
                                                className="mr-2"
                                                onChange={(e) => {
                                                    seller?.read ? inputpHandler(e) : inputppHandler(e);
                                                }}
                                                value={valueprice ? valueprice : ""}
                                            />
                                            <input
                                                type="search"
                                                name="sku"
                                                placeholder="Search by sku"
                                                className="mr-2"
                                                onChange={(e) => {
                                                    seller?.read ? inputsHandler(e) : inputssHandler(e);
                                                }}
                                                value={valuesku ? valuesku : ""}
                                            />
                                        </div> */}
                    {/* <div className="inputs">
                      <InputTemplate placeholder="Search By Name" />
                      <InputTemplate placeholder="Search By Price" />
                      <InputTemplate placeholder="Search By Sku" />
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="card-body cardBodyHeight  p-2">
                <div className="table-responsive">
                  <div className="productListing_desktop">
                    <Paginated data={productList} columns={COLUMNS} />
                  </div>
                  <div className="productListing_mobile">
                    {/* <ProductTemplate data={productList} deleteProduct={deleteProduct} handle="nonapprovedproduct"></ProductTemplate> */}

                    <Pagination itemsPerPage={20}  items={productList} deleteProduct={deleteProduct} handle="nonapprovedproduct"></Pagination>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default ProductList;
