import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Footer from "./footer";
import { useState } from "react"
import styles from "../thememaker/sidebar/Side_bar.module.css"
import style from "./thememaker.module.css"
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
interface Props {
    children?: ReactNode;
}





const ThemeMaker = ({ children }: Props) => {

    const [showMenu, setshowMenu] = useState(false)
    const history = useHistory()
    function Showmenu() {
        setshowMenu(true);
       history.push("/sidebar")   
        
    }
    return (
        <>

            {/* for small screen sizes start */}

            <div className={style.smallScreenSidebar}>

                <div id="wrapper">
                    <div id="content-wrapper" className={`d-flex flex-column `}>
                        <div id="content">
                            <Navbar Showmenu={Showmenu} />
                            <div className={`container-fluid ${styles.theme_}`} >
                                {showMenu ? <Sidebar /> : <div className="d-flex w-100"> <div className={styles.theme_sidemenuDesktop}> </div>  {children}</div>}
                            </div>
                            {/* <Footer /> */}
                        </div>
                    </div>
                </div>

            </div>


            {/* for small screen sizes end */}
            {/* for big screen sizes start */}
            <div className={style.bigScreenSidebar}>
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar />
                            {children}
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>

            {/* for big screen sizes end */}




        </>
    )
}

export default ThemeMaker;