import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/404";
import Login from "../pages/login";
import AddUser from "../pages/adduser";
import UserList from "../pages/userlist";
import Profile from "../pages/profile";
import ProductList from "../pages/productlist";
import AddProduct from "../pages/addproduct";
import LoginPageIsLogedIn from "../components/loginProtected";
import ProtectedRoute from "../components/protectedRoute";
import ForgetPassword from "../pages/forget-password";
import ResetPassword from "../pages/reset-password";
import AddSeller from "../pages/addseller";
import SellerList from "../pages/sellerlist";
import Roles from "../pages/roles";
import OrderList from "../pages/orderlist";
import Category from "../pages/category";
import UpdateSeller from "../pages/updateseller";
import Tax from "../pages/tax";
import UpdateUser from "../pages/updateuser";
import Location from "../pages/location";
import Logs from "../pages/logs";
import Transaction from "../pages/transaction";
import OrderDetails from "../pages/order-details";
import MasterProductAdd from "../pages/NewmasterPage/MasterProductAdd";
import MasterProductList from "../pages/NewmasterPage/MasterProductList";
import OndcSellerProduct from "../pages/NewmasterPage/OndcSellerProduct";
import OndcSellerProductAdd from "../pages/NewmasterPage/OndcSellerProductAdd";
import NonapprovedProducts from "../pages/Non Approved Product List/index";
import NonapprovedProduct from "../pages/NonApprovedProduct/index";
import Notification from "../pages/Notifications/index.js";
import Sellerdetails from "../pages/SellerDetails";
import SellerNonApprovedlist from "../pages/Non Approved Product List/SellerNonApprovedlist";
import Onboarding from "../pages/Onboarding";
import Registerseller from "../pages/SellerRegisteration";
import Personaldetails from "../pages/SellerRegisteration/Personaldetails";
import Bankdetails from "../pages/SellerRegisteration/Bankdetails";
import Checkseller from "../pages/SellerRegisteration/Checkseller";
import Pendingpage from "../pages/SellerRegisteration/Pendingpage";
import Brand from "../pages/Brand";
import Addcategory from "../pages/New Category/Addcategory";
import Sellerauthcheck from "../pages/SellerRegisteration/Sellerauthcheck";
import Seeorderdetails from "../pages/PartialOrder/Seeorderdetails";
import Acceptedorderdetails from "../pages/PartialOrder/Acceptedorderdetails";
import Orderpopusp from "../pages/PartialOrder/Orderpopusp";
import Instorecatalog from "../pages/catalog/Instorecatalog";
import CreateProduct from "../pages/catalog/createProduct";
// import Something from "../pages/addproduct/Something";

import Sidebar from "../components/thememaker/sidebar";
import Catalog from "../pages/catalog";
import Order from "../pages/order";
import OrderCompleteDetail from "../pages/order/orderCompleteDetail";

const routes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/addseller",
    component: <AddSeller />,
  },
  {
    path: "/sellerlist",
    component: <SellerList />,
  },
  {
    path: "/tax",
    component: <Tax />,
  },
  {
    path: "/updateseller/:id",
    component: <UpdateSeller />,
  },
  {
    path: "/roles",
    component: <Roles />,
  },
  {
    path: "/adduser",
    component: <AddUser />,
  },
  {
    path: "/updateuser/:id",
    component: <UpdateUser />,
  },
  {
    path: "/userlist",
    component: <UserList />,
  },
  {
    path: "/category",
    component: <Category />,
  },
  {
    path: "/product/:id?",
    component: <AddProduct />,
  },
  {
    path: "/productlist",
    component: <ProductList />,
  },
  {
    path: "/orderlist",
    component: <OrderList />,
  },
  {
    path: "/orderdetails/:id?",
    component: <OrderDetails />,
  },
  {
    path: "/transaction",
    component: <Transaction />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/location",
    component: <Location />,
  },
  {
    path: "/forget-password",
    component: <ForgetPassword />,
  },
  {
    path: "/reset-password/:id",
    component: <ResetPassword />,
  },
  {
    path: "/logs",
    component: <Logs />,
  },

  {
    path: "/mastercatalog/:id?",
    component: <MasterProductAdd />,
  },
  {
    path: "/masterproductlist",
    component: <MasterProductList />,
  },
  {
    path: "/ondcsellerproduct",
    component: <OndcSellerProduct />,
  },
  {
    path: "/ondcsellerproductadd/:id?",
    component: <OndcSellerProductAdd />,
  },
  {
    path: "/nonapprovedproducts",
    component: <NonapprovedProducts />,
  },
  {
    path: "/nonapprovedproduct/:id?",
    component: <NonapprovedProduct />,
  },
  {
    path: "/notifications",
    component: <Notification />,
  },
  {
    path: "/sellernonapprovedproducts",
    component: <SellerNonApprovedlist />,
  },

  // {
  //   path: "/test",
  //   component: <Something />
  // },

  {
    path: "/sidebar",
    component: <Sidebar />,
  },
  {
    path: "/sellerdetail",
    component: <Sellerdetails />,
  },
  {
    path: "/ondcsellerapp",
    component: <Onboarding />,
  },
  {
    path: "/selleregister",
    component: <Registerseller />,
  },
  {
    path: "/personaldetails/:id?",
    component: <Personaldetails />,
  },
  {
    path: "/bankdetails/:id?",
    component: <Bankdetails />,
  },
  {
    path: "/checkseller",
    component: <Checkseller />,
  },

  {
    path: "/pending",
    component: <Pendingpage />,
  },
  {
    path: "/catalog",
    component: <Catalog />,
  },

  {
    path: "/brand",
    component: <Brand />,
  },
  {
    path: "/newcategory",
    component: <Addcategory />,
  },
  {
    path: "/sellerauthcheck",
    component: <Sellerauthcheck />,
  },
  {
    path: "/seeorderdetails",
    component: <Seeorderdetails />,
  },
  {
    path: "/order",
    component: <Order />,
  },
  {
    path: "/orderCompleteDetail/:id?",
    component: <OrderCompleteDetail />,
  },
  {
    path: "/orderpopup",
    component: <Orderpopusp />,
  },
  {
    path: "/updatestoreproduct/:id?",
    component: <CreateProduct />,
  },

  {
    path: "*",
    component: <PageNotFound />,
  },
];
console.log("LoginPageIsLogedIn----", LoginPageIsLogedIn);
const AllRoutes = () => {
  return (
    <>
      <Router>
        <Switch>
          {routes.map((item, index) => (
            <Route exact path={item?.path} key={index}>
              {item.path === "/" ? (
                <LoginPageIsLogedIn>{item.component}</LoginPageIsLogedIn>
              ) : item.path === "/ondcsellerapp" ? (
                <Onboarding />
              ) : item.path === "/checkseller" ? (
                <Checkseller />
              ) : item.path === "/sellerauthcheck" ? (
                <Sellerauthcheck />
              ) : item.path === "/acceptedorderdetail" ? (
                <Acceptedorderdetails />
              ) : (
                <>
                  <ProtectedRoute>{item.component}</ProtectedRoute>
                </>
              )}
            </Route>
          ))}
        </Switch>
      </Router>
    </>
  );
};

export default AllRoutes;
