import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

const ForgetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        email: "",
    });
    const [errorMessage, setErrorMessage] = useState({
        email: true,
    });

    const forgetPassMannage = async () => {
        const { data } = await axios({
            url: "/user/forget",
            method: "POST",
            data: user,
        });
        setLoading(false);
        if (data.message === "flag2") {
            setUser({ email: "" })
            toast.success("Please check your email to reset password !", {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (data.message === "flag1") {
            toast.error("Email is not registered !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (data.message === "flag0") {
            toast.error("404 error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const forgetPassHandler = (e: any) => {
        e.preventDefault();
        setLoading(true);
        if (errorMessage.email) {
            setLoading(false);
            toast.error("Email is required !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            forgetPassMannage();
        }
    }
    const onChangeHandler = (e: any) => {
        setUser({ ...user, [e.target.name]: e.target.value });
        setErrorMessage({ ...errorMessage, [e.target.name]: e.target.value ? false : true })
    }
    return (
        <>

            <div className="container-fluid bg-primary" style={{ height: "100vh" }} >

                <div className="row justify-content-center">

                    <div className="col-md-6 mt-4 mb-4">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-center mb-3">
                                                <img src="/img/mainlogo.png" style={{ height: "65px" }} alt="logo here" />
                                            </div>
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                                <p className="mb-4">We get it, stuff happens. Just enter your email address below
                                                    and we'll send you a link to reset your password!</p>
                                            </div>
                                            <form className="user mb-4" onSubmit={(e) => forgetPassHandler(e)} >
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user"

                                                        placeholder="Enter Email Address..."
                                                        name="email"
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                                {
                                                    loading ? <button className="btn btn-primary btn-user btn-block mt-4 mb-4">
                                                        Loading...
                                                    </button> : <button className="btn btn-primary btn-user btn-block mt-4 mb-4">
                                                        Reset password
                                                    </button>
                                                }


                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <Link className="small" to="/">Already have an account? Login!</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <ToastContainer />
        </>
    )
}

export default ForgetPassword;