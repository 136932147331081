import React from 'react'
import styles from "./SellerTemplate.module.css"
import deleteicon from "../../icons/delete.png"
import edit from "../../icons/edit.png"
import { Link } from "react-router-dom"
import mailicon from "../../icons/mailicon.png"
import phoneicon from "../../icons/phoneicon.png"


export default function SellerTemplate({ data, sellerDelete }) {
    // console.log("this is from product template>>>>", data)
    return (
        <>
            {
                data.map((item) => {
                    return <div className={`d-flex justify-content-between w-100 ${styles.sellerTemplatemain}`} key={item.id}>
                        <div className={`${styles.sellerTemplateImage}`}>
                            <img src={item.images} alt="" />
                        </div>
                        <div className={`${styles.sellerTemplateDesc}`}>
                            <p className="m-0">{item.company_name}</p>
                            <div className={`${styles.sellerTemplateDesc_mail}`}>
                                <img src={mailicon} alt="" /> <p className='m-0 mb-1'>{item.email} </p>
                            </div>
                            <div>
                                <img src={phoneicon} alt="" />  <p className='m-0'>{item.contact_no}</p>
                            </div>

                            {(item.approve_status == 1) ? <button className={styles.success}>Approved</button> : (item.approve_status == 2) ? <button className={styles.danger}>Blocked</button> : <button>New Request</button> }
                        </div>
                        <div className={`${styles.sellerTemplateButtons}`}>

                            <div onClick={() => {
                                sellerDelete(item.id)
                            }}>
                                <img src={deleteicon} alt="" />
                            </div>

                            <Link to={`../../updateseller/${item.id}`}

                                className="btn-sm m-1 btn btn-success btn-circle"
                            >
                                <div>
                                    <img src={edit} alt="" />
                                </div>

                            </Link>
                        </div>
                    </div>
                })
            }
        </>
    )
}
