import React, { useEffect } from "react";
import { useState } from "react";
import "./switch.css";
import { tokenDecode } from "../../utils/helper";
import styles from "../../components/ProductViewTemplate/ProductTemplate.module.css";
import { data } from "jquery";
import { toast } from "react-toastify";
import edit from "../../icons/edit_ui.png";
import axios from "axios";
import { Link } from "react-router-dom";
import editfull from "../../icons/edit.png";
import styled from "styled-components";

const ImageContainer = styled.div
`
  max-height: 64px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 68px;
  object-fit: contain;
}
  `

const Instorecatalog = (props) => {
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  const [searchValue, setsearchValue] = useState("");
  // console.log("currentsellerid--->", current_sellerid)
  const [openInput, setopenInput] = useState(null);
  const [productlist, setproductlist] = useState([]);
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("");
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("");
  const [isChecked, setIsChecked] = useState("");

  useEffect(() => {
    getproductlist();
  }, []);

  async function getproductlist() {
    try {
      const { data } = await axios({
        url: `https://shikahrapp.hulsecure.in/public/index.php/api/listsellerproducts`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: current_sellerid,
        },
      });

      if (data.status === 200) {
        setproductlist(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // console.log(productlist)
  function maxPriceHandler(id) {
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct = productlist.filter((item) => item.id === id);
    setquickUpdatedMaxPrice2(...maxPriceProduct);
    let mp;
    // console.log("maxPriceProduct---", maxPriceProduct)
    if (maxPriceProduct[0]?.selling_price) {
      mp = maxPriceProduct[0].selling_price;
      // console.log("mp--->", mp)
      setquickUpdatedMaxPrice(mp);
    } else if (maxPriceProduct[0]?.mrp) {
      mp = maxPriceProduct[0].mrp;
      setquickUpdatedMaxPrice(mp);
    }
  }

  async function QuickUpdateHandler(id, mrp) {
    console.log("quickUpdatedMaxPrice", quickUpdatedMaxPrice);
    if (parseInt(quickUpdatedMaxPrice) <= parseInt(mrp[0])) {
      let updatedDataToSend;
      let dataForSeller2 = {
        product_id: id,
        price: quickUpdatedMaxPrice,
        status: true,
      };
      if (current_sellerid === 1) {
        updatedDataToSend = {
          ...quickUpdatedMaxPrice2,
          selling_price: quickUpdatedMaxPrice,
        };
      } else {
        updatedDataToSend = dataForSeller2;
      }
      // console.log(updatedDataToSend)
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/quickeditprice",
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: updatedDataToSend,
      });

      if (data.status === 200) {
        setopenInput(null);
        toast.success("Price Updated successfully !", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          getproductlist();
        }, 1000);
      }

      // if (current_sellerid===1) {
      //   if (data.status===200) {
      //     categoryListing();
      //   }
      // }
    } else {
      alert("Please Enter an amount less then MRP");
      console.log("quickupdate value--->", quickUpdatedMaxPrice);
      console.log("mrp value--->", mrp[0]);
      console.log("quickupdate value--->", typeof quickUpdatedMaxPrice);
      console.log("mrp value--->", typeof mrp[0]);
    }
  }

  function handleSort(e) {
    // console.log(e.target.value);
    if (e.target.value === 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.selling_price) - parseInt(b.selling_price);
      });
      setproductlist(sortedLowToHigh);
    } else if (e.target.value === 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.selling_price) - parseInt(a.selling_price);
      });
      setproductlist(sortedHightoLow);
    } else {
      getproductlist();
    }
  }

  function searchHandler(e) {
    setsearchValue(e.target.value);
    if (e.target.value === "") {
      getproductlist();
    }
  }

  async function searchResult() {
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/searchproductpro",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          title: searchValue,
          seller_id: current_sellerid,
        },
      });
      // console.log("data of master product list-->", data)
      if (data) {
        setproductlist(data.data);
        // console.log(data.data)
      }
    } catch (error) {}
  }

  return (
    <>
      <div className="row mx-auto">
        <div className="col-12">
          <input
            type="search"
            name="name"
            placeholder="Search by Product, Category or Brand"
            className="w-100 mr-2 rounded-pill"
            value={searchValue}
            onChange={searchHandler}
            style={{ fontSize: "14px" }}
          />
        </div>
        <div className="col-12 btn-toolbar" role="toolbar">
          <div
            className="btn-group p-2 w-50"
            role="group"
            style={{ height: "57px" }}
          >
            <button
              type="button"
              className="btn btn-secondary"
              style={{ fontSize: "2.5vw" }}
              onClick={searchResult}
            >
              Apply Filter
            </button>
          </div>
          <div className="btn-group p-2 w-50" role="group">
            <select
              type="button"
              className="btn btn-secondary"
              style={{ fontSize: "2.5vw" }}
              onChange={handleSort}
            >
              <option value="">Sort</option>
              <option value="0">Low - High</option>
              <option value="1">High - Low</option>
            </select>
          </div>
        </div>
        {productlist?.map((item, index) => {
          return (
            <div
              className="d-flex w-100  mb-2 py-2"
              style={{
                gap: "3px",
                position: "relative",
                background: "white",
                padding: "4px",
              }}
              key={item?.id}
            >
              <ImageContainer>
                <img src={item?.image} alt="" className="w-100" />
              </ImageContainer>
              <div className="prod_desc">
                {item?.name} <br />
                Category : {item?.cat_name[0]}
                <br />
                Mrp : {item?.mrp} <br />
                Selling Price : {item?.selling_price}
              </div>
              <div
                className=" d-flex flex-column align-items-center"
                style={{ top: "3px", right: "5px", gap: "3px" }}
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={item?.status[0]}
                    onChange={async (e) => {
                      let newIsChecked = !item?.status[0];
                      let itemId = item?.id;

                      const { data } = await axios({
                        url: "https://shikahrapp.hulsecure.in/public/index.php/api/changeproductstatus",
                        method: "POST",
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "us_st_d"
                          )}`,
                          "Content-type": "application/json",
                        },
                        data: { product_id: itemId, isChecked: newIsChecked },
                      });
                      const updatedProductlist = [...productlist];
                      updatedProductlist[index] = {
                        ...item,
                        status: [newIsChecked],
                      };
                      setproductlist(updatedProductlist);
                    }}
                  />
                  <span className="slider round"></span>
                </label>

                <button
                  className="btn btn-secondary btn-circle "
                  type="button"
                  style={{ width: "30px", height: "30px" }}
                >
                  <img
                    src={edit}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      maxPriceHandler(item.id);
                    }}
                  />
                </button>

                {openInput === item.id && (
                  <div className="position-relative">
                    <div class="pop-up">
                      <span
                        className={styles.popupClose}
                        onClick={() => setopenInput(null)}
                      >
                        x
                      </span>
                      <div class="pop-up-content">
                        <div class="form-group d-flex " style={{ gap: "3px" }}>
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "12px",
                              color: "black",
                            }}
                            className="p-1 m-0"
                          >
                            Selling Price
                          </p>
                          <input
                            type="text"
                            class="form-control form-control-sm "
                            value={quickUpdatedMaxPrice}
                            className="w-50 px-2"
                            onChange={(e) => {
                              setquickUpdatedMaxPrice(e.target.value);
                            }}
                          />
                        </div>
                        <button
                          style={{
                            marginLeft: "2px",
                            backgroundColor: "blue",
                            padding: "2px 5px",
                            borderRadius: "3px",
                            border: "none",
                            color: "white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            QuickUpdateHandler(item.id, item.mrp);
                          }}
                        >
                          {" "}
                          Update{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div style={{ display: "none" }}>
                  <Link to={`/updatestoreproduct/${item.id}`}>
                    <div>
                      <img src={editfull} alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Instorecatalog;
