import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown"
import Bankdetails from "../SellerRegisteration/Bankdetails";
import Personaldetails from "../SellerRegisteration/Personaldetails";
import thanks from "../../icons/thanks.gif"
import styles from "./Sellerdetails.module.css"
import style from "../SellerRegisteration/seller_register.module.css"
const { apiEndpoint } = config;

const s3Config = {
    bucketName: 'bucket-name',
    dirName: 'directory-name',      /* Optional */
    region: 'ap-south-1',
    accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
    secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
    s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
const aws = require('aws-sdk');

const Sellerdetails = () => {
    const [showThank, setshowThank] = useState(false);


    let params = useParams();
    const { id } = params;
    let rawToken = localStorage.getItem("raw_token")
    const [pageNamem, setpageName] = useState(true);
    const [loading, setLoading] = useState(false);
    const [uploading, setUpLoading] = useState(false);
    const decode = tokenDecode();
    // console.log("decode-->", decode)
    const [roleData, setRoleData] = useState([]);
    const [daysName, setdaysName] = useState("");


    function tabredirect() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tab = urlParams.get('tab');
        return tab
    }
    const [renderComponent, setrenderComponent] = useState(tabredirect())

    const rawData = {
        opening_time: '10:00',
        closing_days: "",
        opening_days: "",
        closing_time: '19:00',
        incorporation_date: "",
        images: "",
        commission: 1,
        ondc_commission: "",
        fssai_licence: "",
        seller_commission: 1,
        returnable: "false",
        replaceable: "false",
        customer_care_contact: "",
        cancelable: "",
        return_window: "",
        seller_pickup_return: "false",
        time_to_ship: "",
        cod: "false",
        delivery_fee: "",
        packing_fee: "",
        sell_on_ondc: "",
        // status: "true",
        // approved_status: "0"

    };
    const errorRawData = {
        opening_time: false,
        closing_days: false,
        opening_days: false,
        closing_time: false,
        incorporation_date: false,
        images: false,
        commission: false,
        ondc_commission: false,
        fssai_licence: false,
        seller_commission: false,
        returnable: false,
        replaceable: false,
        customer_care_contact: false,
        cancelable: false,
        return_window: false,
        seller_pickup_return: false,
        time_to_ship: false,
        cod: false,
        // status: false,
        sell_on_ondc: false

    };
    const [userData, setUserData] = useState(rawData);
    const [userDataError, setUserDataError] = useState(errorRawData);
    const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    //code for the days selection:-
    console.log(userData)
    const [Oweekdays, setOweekdays] = useState([
        { day: "Monday", id: "1" }, { day: "Tuesday", id: "2" }, { day: "Wednesday", id: "3" }, { day: "Thursday", id: "4" }, { day: "Friday", id: "5" }, { day: "Saturday", id: "6" }, { day: "Sunday", id: "7" },
    ])
    const [Cweekdays, setCweekdays] = useState([
        { cday: "Monday", id: "1" }, { cday: "Tuesday", id: "2" }, { cday: "Wednesday", id: "3" }, { cday: "Thursday", id: "4" }, { cday: "Friday", id: "5" }, { cday: "Saturday", id: "6" }, { cday: "Sunday", id: "7" },
    ])
    const [selectedCDays, setSelectedCDays] = useState();
    const [selectedODays, setSelectedODays] = useState(Oweekdays);



    //code for the days selection end:-

    const inputHandler = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const errorHandler = (e) => {
        if (e.target.value) {
            setUserDataError({ ...userDataError, [e.target.name]: false });
        } else {
            setUserDataError({ ...userDataError, [e.target.name]: true });
        }
    }
    const getRole = async () => {
        try {
            const { data } = await axios({
                url: `https://shikahrapp.hulsecure.in/public/index.php/api/sellerShop`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                },

            });

            if (data.status === 200) {
                setUserData(data.response);
                setpageName(false);
                // Split opening_days and closing_days by comma and trim any whitespace
                const openingDays = data.response.opening_days.split(",").map((day) => day.trim());
                const closingDays = data.response.closing_days.split(",").map((day) => day.trim());

                const filteredCDays = Cweekdays.filter(
                    (day) => closingDays.includes(day.id)
                );
                const filteredODays = Oweekdays.filter(
                    (day) => openingDays.includes(day.id)
                );

                setSelectedODays(filteredODays.map(item => item));
                setSelectedCDays(filteredCDays.map(item => item));
            }
        } catch (error) { }
    };
    const submitSellerDetailsHandler = async () => {
        setLoading(true);

        try {
            const {
                opening_days,
                opening_time,
                closing_days,
                closing_time,
                incorporation_date,
                images,
                commission,
                ondc_commission,
                fssai_licence,
                seller_commission,
                returnable,
                replaceable,
                customer_care_contact,
                cancelable,
                return_window,
                seller_pickup_return,
                time_to_ship,
                cod,
                // status,
                sell_on_ondc
            } = userData;

            if (opening_days && opening_time && closing_days && closing_time && returnable !== "" && replaceable !== "" && customer_care_contact && cancelable !== "" && return_window !== "" && seller_pickup_return !== "" && time_to_ship !== "" && cod !== "") {
                const { data } = await axios({
                    url: `https://shikahrapp.hulsecure.in/public/index.php/api/addshopdetails`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                    },
                    data: userData,
                });

                if (data.status == 200) {
                    toast.success("Details submitted Successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setUserData(data.response);
                    setTimeout(() => {
                        pageChangeController()

                    }, 3000)

                }
            } else {
                toast.error("Please fill all the fields !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const onSelectHandler = (selectedList, selectedItem) => {
        let daySelected = selectedList.map(item => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map(item => item));
    }
    const onRemoveHandler = (selectedList, selectedItem) => {
        let daySelected = selectedList.map(item => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map(item => item));
    }



    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            // console.log(Location);

            setUserData({ ...userData, [e.target.name]: Location })

        } catch (exception) {
            // console.log(exception);
        }
    };

    function renderController(e) {
        setrenderComponent(e.target.value)
        // givedta()
    }


    function pageChangeController() {
        if (renderComponent == "shopdetails") {
            setrenderComponent("personaldetails")
        }
        if (renderComponent == "personaldetails") {
            setrenderComponent("bankdetails")
        }
    }

    useEffect(() => {
        getRole();
        if (localStorage.getItem("showthank")) {
            setshowThank(true)
        }


    }, []);

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">

                            <div className="card-header p-0">


                                {showThank && <div className={style.thanks_container} style={{ background: "white" }}>

                                    {/* <div className="w-100 d-flex justify-content-center" >
                                        <img src={thanks} alt="" className="w-50" />
                                    </div> */}

                                    <div className={style.thanks_statement}>
                                        <p>Thank you for registering with Seller Portal</p>
                                        <p className='m-1' style={{ fontSize: "13px", background: "white", marginBottom: "12px" }}>Your request is under process, your outletwill be live once request is approved.</p>
                                        <p className='m-1' style={{ fontSize: "13px", background: "white", marginBottom: "12px" }}>In mean time please confirm shop details.</p>
                                    </div>



                                </div>}
                                <h6 className="m-0 font-weight-bold text-primary">
                                    {/* {pageNamem ? "Add Shop Details" : "Update Details"} */}
                                    <div className={styles.tabs}>


                                        <button onClick={renderController} value="shopdetails" className={renderComponent == "shopdetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Shop Details
                                        </button>
                                        <button onClick={renderController} value="personaldetails" className={renderComponent == "personaldetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Personal   Details</button>
                                        <button onClick={renderController} value="bankdetails" className={renderComponent == "bankdetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Bank Details</button>

                                    </div>
                                </h6>
                            </div>
                            {renderComponent == "shopdetails" ? <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Opening Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.opening_time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                name="opening_time"
                                                placeholder="Enter Opening Time"
                                                value={userData.opening_time}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Closing Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.closing_time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                name="closing_time"
                                                className="form-control"
                                                placeholder="Enter Closing Time"
                                                value={userData.closing_time}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Incorporation Date
                                                {/* <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.incorporation_date ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                                            </label>
                                            <input
                                                type="date"
                                                name="incorporation_date"
                                                className="form-control"
                                                placeholder="Enter Date"
                                                value={userData.incorporation_date}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Image
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.images ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            {userData.images && <a href={userData.images} target="_blank" style={{ fontSize: "10px", marginLeft: "3px", textDecoration: "underline" }}>View Document</a>}
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => productImageHandler(e)}
                                                accept="image/*"
                                                name="images"
                                                multiple
                                                onBlur={(e) => errorHandler(e)}
                                                id="image"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Maximum  Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.ondc_commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="ondc_commission"
                                                className="form-control"
                                                value={userData.ondc_commission}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Max Commission"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label>Seller Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.seller_commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="seller_commission"
                                                className="form-control"
                                                value="1"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter ONDC Commission"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label>Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="commission"
                                                className="form-control"
                                                placeholder="Enter Commission"
                                                value="1"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fssai License Number
                                                {/* <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.fssai_licence ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                                            </label>
                                            <input
                                                type="text"
                                                maxLength="14"
                                                name="fssai_licence"
                                                className="form-control"
                                                value={userData.fssai_licence}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter License Number"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product returnable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.returnable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.returnable}
                                                name="returnable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select returnable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Sell On ONDC</label>
                                            {/* <span className="text-danger" > * </span>
                                            {
                                                userDataError?.sell_on_ondc ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            <select
                                                className="form-control"
                                                value={userData.sell_on_ondc}
                                                name="sell_on_ondc"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Sell on ONDC</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Replaceable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.replaceable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.replaceable}
                                                name="replaceable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Replaceable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Cancelable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.cancelable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.cancelable}
                                                name="cancelable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Cancelable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Care Contact
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.customer_care_contact ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="customer_care_contact"
                                                className="form-control"
                                                value={userData.customer_care_contact}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Customer Care Number"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Cash On delivery</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.cod ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.cod}
                                                name="cod"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select cash on delivery</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Delivery Charges

                                            </label>
                                            <input
                                                type="text"
                                                name="delivery_fee"
                                                className="form-control"
                                                value={userData.delivery_fee}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Delivery Charges"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Packing Charges

                                            </label>
                                            <input
                                                type="text"
                                                name="packing_fee"
                                                className="form-control"
                                                value={userData.packing_fee}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Packing Charges"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Seller pickup return</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.seller_pickup_return ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.seller_pickup_return}
                                                name="seller_pickup_return"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select seller return pickup</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Return Window</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.return_window ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.return_window}
                                                name="return_window"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Return Window</option>
                                                {timeToShipLength.map((item) => (
                                                    <option value={`P${item}D`}>{`${item}Days`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Time to ship</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.time_to_ship ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.time_to_ship}
                                                name="time_to_ship"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Time to Ship</option>
                                                {timeToShipLength.map((item) => (
                                                    <option value={`P${item}D`}>{`${item}Days`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.status}
                                                name="status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Status</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>

                                            </select>
                                        </div>
                                    </div> */}
                                    {/* {  decode.data.seller_data.id ==1 &&  <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Approved Status</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.approved_status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.approved_status}
                                                name="approved_status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="0">New Request</option>
                                                <option value="1">Active</option>
                                                <option value="2">Block</option>

                                            </select>
                                        </div>
                                    </div>}  */}


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                <label>Opening Days
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        userDataError?.opening_days !== "valid" && userDataError?.opening_days !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </label>
                                            </label>
                                            {/* <input
                                                type="text"
                                                name="opening_days"
                                                className="form-control"
                                                placeholder="Enter Open Days"
                                                value={userData.opening_days}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            /> */}

                                            <Multiselect options={Oweekdays} selectedValues={selectedODays} displayValue="day" onSelect={onSelectHandler} onRemove={onRemoveHandler}></Multiselect>


                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Closing Days
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.closing_days !== "valid" && userDataError?.closing_days !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>

                                            <Multiselect options={Cweekdays} selectedValues={selectedCDays} displayValue="cday" onSelect={(selectedList, selectedItem) => {
                                                let daySelected = selectedList.map(item => item.id)
                                                let z = daySelected.toString()
                                                setUserData({ ...userData, closing_days: z });
                                                setSelectedCDays(selectedList.map(item => item));
                                            }}></Multiselect>
                                        </div>
                                    </div>


                                    <div className="col-md-12">
                                        {pageNamem ? <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "saving..." : "Save"}
                                        </button> : <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "saving..." : "Update"}
                                        </button>}
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>
                            </div> : renderComponent == "personaldetails" ? <Personaldetails pageChangeController={pageChangeController} /> : renderComponent == "bankdetails" && <Bankdetails />}
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    );
};

export default Sellerdetails;
