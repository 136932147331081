import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";
import shikharapplogo from "../../../icons/logoimg.png"
import styles from "./navbar.module.css"
import bars from "../../../icons/MenuBar.png"
import shop from "../../../icons/shop.png"
const Navbar = (props) => {
    const history = useHistory();
    const logouthandler = () => {
        localStorage.removeItem("us_st_d");

    }
    const token = localStorage.getItem("us_st_d");
    const decoded = tokenDecode();
    const { data } = decoded;
    const { user } = data;
    // console.log("hhhh", user);
    return (
        <>
            <nav className={`navbar navbar-expand navbar-light  topbar  static-top shadow ${styles.mobile_navbar}`}>

                {/* <Link to="/">  */}
                <button id="sidebarToggleTop" className={`btn btn-link rounded-circle mr-3 ${styles.burgerButton}`} onClick={() => props.Showmenu()}>
                    <img src={bars} alt="" style={{ height: "70%" }} />
                </button>
                {/* </Link> */}

                {/* <Link to="/"> <div className={styles.logo}>  <img src={shikharapplogo} alt="" /> SHIKHAR SELLER APP </div></Link> */}
                {/* <div className={styles.logo}>  <img src={shikharapplogo} alt="" /> SHIKHAR SELLER APP </div> */}

                <ul className="navbar-nav  ">

                    {/* below is thesearch button of navbar start, uncomment when required */}

                    {/* <li className="nav-item dropdown no-arrow d-sm-none">
                        <a href="replace" className="nav-link dropdown-toggle" id="searchDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-search fa-fw"></i>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                            aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small"
                                        placeholder="Search for..." aria-label="Search"
                                        aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li> */}


                    {/* below is thesearch button of navbar end, uncomment when required */}

                    {/* <li className="nav-item dropdown no-arrow mx-1">
                        <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-bell fa-fw"></i>
                            <span className="badge badge-danger badge-counter">3+</span>
                        </a>
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-file-alt text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fas fa-donate text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fas fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                        </div>
                    </li>

                    <li className="nav-item dropdown no-arrow mx-1">
                        <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-envelope fa-fw"></i>
                            <span className="badge badge-danger badge-counter">7</span>
                        </a>
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="messagesDropdown">
                            <h6 className="dropdown-header">
                                Message Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div className="font-weight-bold">
                                    <div className="text-truncate">Hi there! I am wondering if you can help me with a
                                        problem I've been having.</div>
                                    <div className="small text-gray-500">Emily Fowler · 58m</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                        alt="..." />
                                    <div className="status-indicator"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">I have the photos that you ordered last month, how
                                        would you like them sent to you?</div>
                                    <div className="small text-gray-500">Jae Chun · 1d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                        alt="..." />
                                    <div className="status-indicator bg-warning"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Last month's report looks great, I am very happy with
                                        the progress so far, keep up the good work!</div>
                                    <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                                        told me that people say this to all dogs, even if they aren't good...</div>
                                    <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                        </div>
                    </li> */}

                    <div className="topbar-divider d-none d-sm-block"></div>
                    {user.seller_id !== 1 ? <Link to="/sellerdetail?tab=shopdetails"  >
                        <a href="replace" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                            {/* <i className="fas fa-envelope"></i> */}
                            <img src={shop} alt="" />
                            {/* <i className="fas fa-shop"></i> */}
                            {/* <span> Mails </span> */}
                        </a>
                    </Link> : <li className={`nav-item dropdown no-arrow ${styles.plus}`} >

                        <Link to="#" className="nav-link dropdown-toggle" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user?.name}</span>
                            <img className={`img-profile rounded-circle ${styles.dropdownimg}`}
                                src={`/img/undraw_profile.svg`} alt="im here" />

                            <div className={styles.searchOpener}>
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </div>

                        </Link>

                        <div className={`dropdown-menu  shadow animated--grow-in p-0 w-100`}
                            aria-labelledby="userDropdown">
                            <div className={styles.doropdownnavmenu}>
                                <Link to="/profile" >
                                    <a href="replace" className="dropdown-item" >
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        <span> Profile  </span>
                                    </a>
                                </Link>

                                <div className="dropdown-divider"></div>
                                <Link to="#" onClick={logouthandler} >
                                    <a href="replace" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        <span> Logout </span>
                                    </a>
                                </Link>

                                {/* code as per new figma start */}

                                <div className="dropdown-divider"></div>
                                {/* <Link to="#" onClick={logouthandler} > */}
                                <Link to="/notifications" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                    <i className="far fa-bell"></i>
                                    <span> Notifications </span>
                                </Link>
                                {/* </Link> */}
                                <div className="dropdown-divider"></div>
                                <Link to="/sellerdetail?tab=shopdetails"  >
                                    <a href="replace" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                        {/* <i className="fas fa-envelope"></i> */}
                                        <img src={shop} alt="" />
                                        {/* <i className="fas fa-shop"></i> */}
                                        <span> Mails </span>
                                    </a>
                                </Link>


                                {/* code as per new figma end */}
                            </div>
                        </div>



                    </li>}

                </ul>

            </nav>
        </>
    )
}

export default Navbar;
