/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import "./switch.css";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import orderImg from "../../icons/order.png";
import moment from "moment";
import { useEffect, useState } from "react";
// import orderDetail from "./orderCompleteDetail";
import searchicon_black from "../../icons/searchicon_black.png";
import style from "../../components/Search&Options/Search&Options.module.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import InputTemplate from "../../components/Search&Options/InputTemplate";

const customerOrder = () => {
  const decoded: any = tokenDecode();
  const [orderList, setOrderList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [billings, setBillings]:any = useState([]);
  const [items, setItems]: any = useState([]);
  const [payment, setPayment]: any = useState([]);
  const [quote, setQuote]: any = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { apiEndpoint } = config;
  const [toggle, setToggle] = useState(false);
  const rawData = {
    provider_id: "",
  };
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });
  const [updateOrder_, setUpdateOrder_]: any = useState({
    order_id: "",
    tracking_url: "",
    order_state: ""
  })
  const [searchData, setSearchData]: any = useState(rawData);
  const [sorderList, setSorderList] = useState([]);

  const [valuename, setValuename] = useState("");
  const [valueid, setValueid] = useState("");
  const [valueemail, setValueemail] = useState("");
  const [valuecontact, setValuecontact] = useState("");
  const [valuestatus, setValuestatus] = useState("");
  const [valueamount, setValueamount] = useState("");
  const [valuedate, setValuedate] = useState("");
  const { data } = decoded;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;

  const fetchSellerList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorderlistnew`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decoded?.data?.user?.seller_id,
        },
      });

      console.log("order data -> ", data.data);
      // const order = data.data;
      setOrderList(data.data);
      console.log("line 34", orderList);
      if (data.status === 200) {
        setOrderList(data.data);
        // for (let i = 0; i < data.data.length; i++) {
          // _setorderDetail({
          //   ..._orderDetail,
          //   ...data.data[0],
          //   billing_address: JSON.parse(data.data[0]?.billing),
          //   quote: JSON.parse(data.data[0]?.quote),
          //   items: JSON.parse(data.data[0]?.items),
          //   // shipping_address: JSON.parse(data.data[i]?.fulfillments),
          // });
          // console.log("orderDetails---->", { ..._orderDetail });
        // }
        // for (let i = 0; i < orderList.length; i++){
        // setBilling({...billing, bill:data[i]?.billing})
        // console.log('line 68',JSON.stringify(billing) );
        // }
        // for (let i = 0; i < orderList.length; i++){
        //     setBilling({...billing, bill:data[i]?.billing})
        //     setItems({...items, count:data[i]?.items})
        //     setPayment({...payment, amount:data[i]?.payment})
        //     setQuote({...quote, price:data[i]?.quote})
        // }

        // console.log('line 69',data.data[0]?.items );
        // console.log('line 72',data.data[0]?.payment );
        // console.log('line 74',data.data[0]?.quote );
        //   let address:any = '';
        //   data.billing.foreach((item:any) => {
        //     address = JSON.parse(item?.address)
        // });
        // console.log('line 79', address);

        // JSON.stringify(setItems(data.data.items))
        // JSON.stringify(setQuote(data.data.quote))
        // JSON.stringify(setBilling(data.data.billing.address))
        // JSON.stringify(setPayment(data.data.payment))
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);

      setIsLoading(false);
    }
  };
  const getorderDetails = async (order_id: any) => {
    setIsLoading(true);
    try {
        const { data } = await axios({
            url: `${apiEndpoint}/getsellerorder`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
            },
            data: {
                order_id
            }
        });
        console.log("order detail ->------   ", data);
        if (data.status === 200) {
            setIsLoading(false);
            setorderDetail(data.products);
            let totalP = 0
            data.products.forEach((item: any) => {
                totalP += parseFloat(item?.price)
            })
            setUpdateOrder_({
                ...updateOrder_,
                order_id: data.data[0]?.order_id,
                tracking_url: data.data[0]?.tracking_url,
                order_state: data.data[0]?.order_state
            })
            _setorderDetail({ ..._orderDetail, ...data.data[0], total_price: totalP, billing_address: JSON.parse(data.data[0]?.billing), shipping_address: JSON.parse(data.data[0]?.fulfillments) });
        }
    } catch (error) {
        console.log("error", error);
        setIsLoading(false);
    }
}
const updateOrder = async (order_id: any) => {
    try {

        const { data } = await axios({
            url: `${apiEndpoint}/updateorderstatus`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
            },
            data: updateOrder_
        });
        console.log(data, "datadata");

        if (data.message === "flag1") {
            toast.success("Order updated !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchSellerList();
        } else {
            toast.error("Something went wrong !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    } catch (error) {
        console.log("update error", error);
        toast.error("404 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        // window.location.reload();

    }
}
  const getSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        console.log(data.data);
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const inputHandler = async (e: any) => {
    setValuename("");
    setValueid("");
    setValueemail("");
    setValuecontact("");
    setValuestatus("");
    setValueamount("");
    setValuedate("");
    var optionvalue = e.target.value;
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbyseller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data);
          setOrderList(data.data);
          setSorderList(data.data);
          setToggle(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOrderList(sorderList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { provider_id } = searchData;
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            name: optionvalue,
          },
        });

        console.log({
          data: {
            provider_id: { searchData },
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputoHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            order_id: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log("line 332", data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputooHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            order_id: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    console.log(optionvalue);
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputcHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            contact_number: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputccHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            contact_number: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputdHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            created_at: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputddHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            created_at: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  useEffect(() => {
    fetchSellerList();
    getSellerList();
  }, []);
  return (
    <>
      <div className=" ">
        <div className="col-12 m-2 d-flex">
          <div className="input-group mb-3">
            <span className="input-group-text">Filter By Date</span>
            <input
              type="date"
              className="form-control"
              placeholder="Search by date"
              name="date"
              onChange={(e) => {
                seller?.read ? inputdHandler(e) : inputddHandler(e);
              }}
              value={valuedate ? valuedate : ""}
            />
          </div>
        </div>
        <div className={`search mt-1 `}>
          <div className={`row w-100 d-flex m-0`} style={{ flexWrap: "wrap" }}>
            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="order_id"
                placeholder="Search by order id"
                onChange={(e) => {
                  seller?.read ? inputoHandler(e) : inputooHandler(e);
                }}
                value={valueid ? valueid : ""}
              />
            </div>

            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="name"
                placeholder="Search by name"
                onChange={(e) => {
                  seller?.read ? inputeHandler(e) : inputeeHandler(e);
                }}
                value={valuename ? valuename : ""}
              />
            </div>

            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="email"
                placeholder="Search by email"
                onChange={(e) => {
                  seller?.read ? inputemHandler(e) : inputeemHandler(e);
                }}
                value={valueemail ? valueemail : ""}
              />
            </div>

            <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="contact_number"
                placeholder="Search by Contact"
                onChange={(e) => {
                  seller?.read ? inputcHandler(e) : inputccHandler(e);
                }}
                value={valuecontact ? valuecontact : ""}
              />
            </div>
          </div>
        </div>

        <div className="">
          {orderList &&
            orderList.map((item: any) => {
              return (
                <>
                  <div className="row m-2 p-2 shadow">
                    <div className="col-2">
                      <img src={orderImg} alt="" className="orderimg"/>
                    </div>
                    <div className="col-8">
                      <p>
                        <strong>
                          Order ID: {item.order_id}
                          <br />
                          {item.name}
                        </strong>
                        <br />
                        <small>
                        {item?.address?.end?.location?.address?.building}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className=" m-2 shadow">
                    <div className="row m-2 p-2 d-flex justify-content-evenly">
                      <div className="col-6">
                        Item : {item.item_count}
                        <br />
                        Status : {item.status}
                      </div>
                      <div
                        className="col-6"
                        style={{
                          textAlign: "end",
                          fontSize: "3vw",
                          fontWeight: "bold",
                        }}
                      >
                        <Link className="text-dark" to={`./orderCompleteDetail/${item.order_id}`}>
                          &#x3E;
                        </Link>
                      </div>
                    </div>
                    <div
                      style={{ borderBottom: "2px dotted grey", width: "100%" }}
                    ></div>
                    <div className="row m-2 d-flex justify-content-evenly">
                      <div className="col-6 pb-3">{moment(`${item.created_at}`).format("MMMM Do YYYY")}</div>
                      <div className="col-6 pb-3" style={{ textAlign: "end" }}>
                        Order Amount: {item.order_total}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* <a className="text-primary" style={{ cursor: "pointer" }}>
                      Accept Partial Order &#x3E;
                    </a> */}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default customerOrder;