import { useEffect } from "react";
import { useHistory, useLocation, } from "react-router-dom";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";

const ProtectedRoute = ({ children }: any) => {
    const history = useHistory();
    const location = useLocation();
    const search = useLocation().search;
    const tk_access = new URLSearchParams(search).get('tk_access');

    // const token: any = localStorage.getItem("us_st_d");
    // const TokenChecker = async () => {
    //     try {
    //         const { data } = await axios({
    //             url: "/login/refresh",
    //             method: "POST",
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         });
    //         localStorage.setItem("isLogedIn", data.refreshToken);
    //     } catch (error) {
    //         localStorage.removeItem("isLogedIn");
    //     }
    // }
    useEffect(() => {
        //   TokenChecker();
    }, []);
    const keyV = location.pathname.split("/")[1];
    try {
        const decoded: any = tokenDecode(tk_access);
        const { data } = decoded;
        const { permission: { category, order, product, role, seller, tax, user, location } } = data;
        // console.log("hhhh", decoded);

        if (decoded) {
            // if (isAdmin === "true") {
            //     return children;
            // }
            switch (keyV) {
                case "":
                    return children;
                case "dashboard":
                    return children;
                case "addseller":
                    return seller.write ? children : history.push("/")
                case "sellerlist":
                    return seller.read ? children : history.push("/");
                case "tax":
                    return tax.write ? children : history.push("/");
                case "updateseller":
                    return seller.update ? children : history.push("/");
                case "roles":
                    return role.write ? children : history.push("/")
                case "adduser":
                    return user.write ? children : history.push("/");
                case "updateuser":
                    return user.update ? children : history.push("/");
                case "userlist":
                    return user.read ? children : history.push("/");
                case "category":
                    return category.write ? children : history.push("/");
                case "product":
                    return product.write ? children : history.push("/");
                case "productlist":
                    return product.read ? children : history.push("/");
                case "orderlist":
                    return order.read ? children : history.push("/");
                case "orderdetails":
                    return order.read ? children : history.push("/");
                case "transaction":
                    return order.read ? children : history.push("/");
                case "location":
                    return location.read ? children : history.push("/");

                case "mastercatalog":
                    return children;
                case "masterproductlist":
                    return children;
                case "ondcsellerproduct":
                    return children;
                case "ondcsellerproductadd":
                    return children;
                case "nonapprovedproducts":
                    return children;

                case "nonapprovedproducts":
                    return children;
                case "nonapprovedproduct":

                    return children;
                case "notifications":
                    return children;
                case "sellernonapprovedproducts":
                    return children;
                case "test":
                    return children;

                case "profile":
                    return children;
                case "sellerdetail":
                    return children;
                case "logs":
                    return children;
                case "sidebar":
                    return children;
                case "ondcsellerapp":
                    return children;
                case "selleregister":
                    return children;
                case "personaldetails":
                    return children;
                case "bankdetails":
                    return children;
                case "checkseller":
                    return children;
                case "pending":
                    return children;

                case "catalog":
                    return children;
                case "order":
                    return order.read ? children : history.push("/");
                case "orderCompleteDetail":
                    return order.read ? children : history.push("/");
                case "brand":
                    return children;
                case "sellerauthcheck":
                    return children;
                case "newcategory":
                    return children;
                case "seeorderdetails":
                    return children;
                case "acceptedorderdetail":
                    return children;
                case "orderpopup":
                    return children;
                case "updatestoreproduct":
                    return children;
                default:
                    return history.push("/");
            }

        } else {
            console.log("no");
            switch (keyV) {
                case "forget-password":
                    return children;
                case "reset-password":
                    return children;
                default:
                    return history.push("/");
            }
        }
    } catch (error) {
        console.log("this erro -->", error);

        switch (keyV) {
            case "forget-password":
                return children;
            case "reset-password":
                return children;
            case "test":
                return children;
            default:
                return history.push("/");
        }
    }
}


export default ProtectedRoute;
