import { useState, useEffect, useRef, useCallback } from "react";
//import "datatables.net-dt/js/dataTables.dataTables"
//import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Link } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import { AnyAaaaRecord } from "dns";
import { BasicTable } from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import Sellerpagination from "../../components/Paginated/Sellerpagination";
import styles from "./sellerList.module.css"
import '../../components/table.css';
import SellerTemplate from "../../components/SellerViewTemplate/SellerTemplate";
const { apiEndpoint } = config;

const A = () => <span className="badge badge-primary">New Request</span>
const B = () => <span className="badge badge-success">Approved</span>
const C = () => <span className="badge badge-danger">Blocked</span>
const valueToApp: any = {
    "0": <A />,
    "1": <B />,
    "2": <C />
}
var count = 1;

var $ = require('jquery');
const SellerList = () => {
    const decoded: any = tokenDecode();
    const { data } = decoded;
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const [sellerList, setSellerList] = useState([]);
    const [allseller, setAllseller] = useState(true)
    const [sellerPending, setSellerPending] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const fetchSellerList = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            console.log("all data", data);
            if (data.status === 200) {
                setIsLoading(false);
                setSellerList(data.data);
                /*setTimeout(() => {
                    $('#dataTable').DataTable({
                        "pageLength": 50,
                        "bDestroy": true
                    });
                }, 500);*/

            }
        } catch (error) {
            console.log("error", error);

            setIsLoading(false);
        }
    }
    const inputHandler = async (e: any) => {

        try {
            var optionvalue = e.target.value;
            console.log(optionvalue);
            if (optionvalue === "pendingseller") {
                const { data } = await axios({
                    url: `${apiEndpoint}/pendingsellerlist`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    }
                });
                console.log("pending data", data.data);
                setIsLoading(false);
                if (data.status === 200) {
                    setSellerList(data.data);


                }
            }
            else if (optionvalue === "approveseller") {
                const { data } = await axios({
                    url: `${apiEndpoint}/approvesellerlist`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    }
                });
                console.log("pending data", data.data);
                setIsLoading(false);
                if (data.status === 200) {
                    setSellerList(data.data);


                }
            }
            else {
                const { data } = await axios({
                    url: `${apiEndpoint}/getsellerlist`,
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    }
                });
                console.log("allseller data", data.data);
                setIsLoading(false);
                if (data.status === 200) {
                    setSellerList(data.data);


                }
            }

        } catch (error) {
            console.log("error", error);

            setIsLoading(false);
        }
    };
    const sellerDelete = async (id: any) => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/sellerdelete`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: id
                }
            });
            //   console.log("sellerList", sellerList);

            const updateSellerList = sellerList.filter((item: any) => item.id !== id);
            //setSellerList(updateSellerList);
            //  console.log("updateSellerList", updateSellerList);
            //fetchSellerList()
            if (data.status === 200) {
                toast.success("Seller Removed Successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            window.location.reload();



            //console.log("delet data data", data);
            // if (data.status === 200) {
            //     toast.success("Seller removed successfully !", {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });

            // } else {
            //     toast.error("403 error !", {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });
            // }
        } catch (error) {
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Company Name",
            Footer: "Company Name",
            accessor: "company_name",
            sticky: "left"
        },
        {
            Header: "Email",
            Footer: "Email",
            accessor: "email",
            sticky: "left"
        },
        {
            Header: "Contact",
            Footer: "Contact",
            accessor: "contact_no",
            sticky: "left"
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row: any) => {
                return <>{valueToApp[row.approve_status]}
                </>
            },
            sticky: "left"
        },
        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                return (
                    <>
                        {seller.update ? <Link to={`../../updateseller/${row.id}`} className="btn btn-success btn-circle"> <i className="fas fa-eye"></i></Link> : null}{seller.delete ? <button className="btn btn-danger btn-circle" onClick={() => sellerDelete(row.id)}><i className="fas fa-trash"></i></button> : null}
                    </>
                )
            },
            sticky: "left"
        },

    ];
    count++

    useEffect(() => {

        fetchSellerList();
    }, [])

    return (
        <>
            <ThemeMaker>

                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-dark text-left">
                                    Seller list
                                </h6>
                                <div className={styles.sellerListOptions}> <b>Filter By: </b><select name="status" id="" onChange={(e: any) => inputHandler(e)}>
                                    <option value="allseller" selected >All</option>
                                    <option value="pendingseller">Pending Seller</option>
                                    <option value="approveseller">Approved Seller</option>
                                </select> </div>
                            </div>

                            <div className={`card-body p-0 ${styles.cardbody}`}>
                                <div className="table-responsive">
                                    {/*} <table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Company name</th>
                                                <th>Email</th>
                                                <th>Contact</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sellerList.map((item: any, index: any) => {
                                                        const A = () => <span className="badge badge-primary">New Request</span>
                                                        const B = () => <span className="badge badge-success">Approved</span>
                                                        const C = () => <span className="badge badge-danger">Blocked</span>

                                                        const valueToApp: any = {
                                                            "0": <A/>,
                                                            "1": <B/>,
                                                            "2": <C/>
                                                        }
                                                        return (
                                                            <tr key={item?.id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item?.company_name}</td>
                                                                    <td>{item?.email}</td>
                                                                    <td>{item?.contact_no}</td>
                                                                    <td>{valueToApp[`${item?.approve_status}`]}</td>
                                                                    <td>
                                                                        <div className="row">
                                                                            {
                                                                                seller?.update ? (
                                                                                    <div className="col-md-4">
                                                                                        <Link to={`/updateseller/${item?.id}`} className="btn btn-success btn-circle">
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </Link>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                seller?.delete ? (
                                                                                    <div className="col-md-4">
                                                                                        <button className="btn btn-danger btn-circle"
                                                                                            onClick={() => sellerDelete(item?.id)}
                                                                                        >
                                                                                            <i className="fas fa-trash"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                    </td>
                                                            </tr>
                                                        
                                                        )
                                                
                                                })
                                            }
                                        </tbody>
                                        </table>*/}
                                    {/* <Paginated data={sellerList} columns={COLUMNS} /> */}


                                    <div className={styles.sellerListing_desktop}>
                                        <Paginated data={sellerList} columns={COLUMNS} />
                                    </div>
                                    <div className={styles.sellerListing_mobile}>
                                        {/* <SellerTemplate data={sellerList} sellerDelete={sellerDelete}></SellerTemplate> */}
                                        <Sellerpagination itemsPerPage={20} items={sellerList} sellerDelete={sellerDelete} paginationFor="seller"></Sellerpagination>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default SellerList;